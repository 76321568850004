import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export const styles = StyleSheet.create({

    textContainer: {
        marginVertical: (8).scaleHeight(),
        marginHorizontal: (16).scaleWidth(),
        height: (38).scaleHeight(),
        backgroundColor: COLORS.appTextInputBackgroundColor,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputStyle: {
        fontSize: (14).scaleWidth(),
        fontFamily: LATO.Medium,
        color:'#515C63',
        flex: 1,
        height: (38).scaleHeight(),
       
    }
})