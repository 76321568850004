import { View, ImageBackground, Modal, TouchableOpacity, Text, Platform } from 'react-native';
import LinearGradient from "react-native-linear-gradient";
import { useEffect, useRef, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { COLORS } from '../../../constants';
import { useSelector } from 'react-redux';
import InputText from '../inputText';
import {styles} from './index.style'



function CreateLineupPopup(props) {

    const updatedLineupName = useSelector((state) => state.lineupReducers.updatedLineupName)
    const [lineupName, setLineupName] = useState('')
    const textInputRef = useRef()
    
    useEffect(() => {
        setLineupName(updatedLineupName)
    }, [])

    useEffect(() => {
      setTimeout(keyboarOnFocus, 3000);
      if (textInputRef.current) {
        textInputRef.current.focus();
       
      }

    }, []);
//-----------------Method to focus on Keyboard-----------//
    function keyboarOnFocus() {
        if (textInputRef.current) {
            textInputRef.current.focus();
        }
    }
      
//-----------Save Line Up Method ------------------//
    const onSaveLineUp = () => {
        if (lineupName.trim().length > 0) {
            props.onClickSaveLineUp(lineupName.trim())
        }
    }


    return (

        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                    <View style={styles.gifContainer}>
                        <View style={styles.innerView}>
                            <Feather onPress={props.onDismissPopup} name="x-circle" size={24} color="black" />
                        </View>   
                        <InputText
                            reference={textInputRef}
                            placeHolder={'Enter New Lineup Name'}
                            autocapitalize={'none'}
                            changeValue={text => setLineupName(text)}
                            value={lineupName}
                            inputStyle={styles.inputStyle}
                            textContainer={styles.textContainer}
                            textAlign="center"
                            multiline={Platform.OS=='android' && true}
                            autoFocus={true}
                        />
                        <TouchableOpacity style={styles.btn} onPress={onSaveLineUp}>
                            <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0, y: 0.5 }} colors={[COLORS.orange, COLORS.lightOrange]} style={styles.buttonStyle} >
                                <Text style={styles.buttonText}>SAVE</Text>
                            </LinearGradient>
                        </TouchableOpacity>
                    </View>
                </ImageBackground>
            </Modal>
        </View >
    );
}

export default CreateLineupPopup;
