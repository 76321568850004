import { MaterialCommunityIcons, Octicons, MaterialIcons, FontAwesome5, Ionicons, Zocial, Entypo } from '@expo/vector-icons';
import { formatPhoneNumber, parseFormattedNumber } from "../../../utils/appHelper";
import { APP_STRINGS, COLORS, ERROR_STRING, ROUTES } from "../../../constants";
import { createUserAPI } from "../../../controllers/signupController";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { APP_STYLES, TOAST_MESSAGE } from "../../../utils";
import urls from '../../../constants/urls';
import AppCheckBox from "../appCheckBox";
import { styles } from "./index.style";
import InputText from "../inputText";
import AppButton from "../appButton";
import { useState } from "react";
import Loader from "../loader";


function SignUpComponent(props) {

    const { navigation } = props

    const [isConfirmPasswordSecure, setConfirmPasswordSecure] = useState(true);
    const [isPasswordSecure, setPasswordSecure] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isShowLoader, setShowLoader] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isCheck, setIsCheck] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');



//----------Check Validations------------//
    const checkValidations = () => {

        if (fName.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankFname, "1")
            return false
        }

        if (lName.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankLname, "1")
            return false
        }

        if (email.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.emailBlank, "1")
            return false
        }

        if (!email.match(APP_STRINGS.emailRegex)) {
            TOAST_MESSAGE(ERROR_STRING.InvalidEmail, "1")
            return false
        }

        if (userName.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.userNameBlank, "1")
            return false
        }

        // if (address.trim().length == 0) {
        //     TOAST_MESSAGE(ERROR_STRING.blankAddress, "1")
        //     return false
        // }

        if (city.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankCity, "1")
            return false
        }

        if (state.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankState, "1")
            return false
        }

        if (zipCode.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankZip, "1")
            return false
        }

        if (!APP_STRINGS.zipPattern.test(zipCode)) {
            TOAST_MESSAGE(ERROR_STRING.validZip, '1');
            return
        }

        // if (phoneNumber.trim().length == 0) {
        //     TOAST_MESSAGE(ERROR_STRING.blankPhone, "1")
        //     return false
        // }

        let removeFormatter = parseFormattedNumber(phoneNumber)
        if(phoneNumber.trim().length!==0){
            if (removeFormatter.length != 10) {
                TOAST_MESSAGE(ERROR_STRING.validPhone, "1")
                return false
            }
        }
      

        if (password.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.passwordBlank, "1")
            return false
        }

        if (!APP_STRINGS.passwordPattern.test(password)) {
            TOAST_MESSAGE(ERROR_STRING.passwordPattern, "1")
            return false
        }

        if (confirmPassword.trim().length == 0 || password != confirmPassword) {
            TOAST_MESSAGE(ERROR_STRING.confirmPassword, "1")
            return false
        }

        if (!isCheck) {
            TOAST_MESSAGE(ERROR_STRING.acceptPrivacy, "1")
            return false
        }

        prepareSignupData()

    }

    const openUrl = () => {
        Linking.openURL(urls.PRIVACY_POLICY)
    }

    //---------Sign Up API Calling-------------//
    const prepareSignupData = () => {

        let param = {
            "UserName": userName,
            "email": email,
            "FirstName": fName,
            "LastName": lName,
            "Address1": address,
            "Address2": "",
            "City": city,
            "State": state,
            "Zip": zipCode,
            "Phone": parseFormattedNumber(phoneNumber),
            "Password": password
        }

        setShowLoader(true)
        createUserAPI(param).then((response) => {
            navigation.navigate(ROUTES.OTP, { email: email, type: 'register' })
        }).catch((err) => {
            setShowLoader(false)
        }).finally(() => {
            setShowLoader(false)
        })

    }


    return (
        <View style={APP_STYLES.authContainer}>
            <Text style={[APP_STYLES.authTitleStyle, { marginTop: (23).scaleWidth(), marginBottom: (5).scaleWidth(), }]}>Create an Account</Text>
            <InputText
                textContainer={styles.textContainer}
                value={fName}
                placeHolder={APP_STRINGS.firstName}
                autocapitalize={'none'}
                icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                changeValue={text => setFname(text)}
            />

            <InputText
                textContainer={styles.textContainer}
                value={lName}
                placeHolder={APP_STRINGS.lastName}
                autocapitalize={'none'}
                icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                changeValue={text => setLname(text)}
            />
            <InputText
                textContainer={styles.textContainer}
                defaultValue={email}
                placeHolder={APP_STRINGS.email}
                keyboardType={'email-address'}
                icons={<Zocial name="email" size={20} color={COLORS.lightGrayColor} />}
                changeValue={text => setEmail(text)}
                autoCapitalize={false}
            />
            <InputText
                textContainer={styles.textContainer}
                defaultValue={userName}
                placeHolder={APP_STRINGS.userName}
                autocapitalize={'none'}
                keyboardType={'email-address'}
                icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                changeValue={text => setUserName(text)}
            />
            <InputText
                textContainer={styles.textContainer}
                defaultValue={address}
                placeHolder={APP_STRINGS.address}
                icons={<Entypo name="location" size={20} color={COLORS.lightGrayColor} />}
                changeValue={text => setAddress(text)}
            />
            <View style={styles.innerContainer}>
                <View style={styles.inputViewStyle}>
                    <InputText
                        defaultValue={city}
                        placeHolder={APP_STRINGS.city}
                        changeValue={text => setCity(text)}
                        icons={<MaterialIcons name='location-city' size={20} color={COLORS.lightGrayColor} />}
                        textContainer={{ marginRight: (6).scaleWidth(), paddingRight: (10).scaleWidth() }}
                    />
                </View>
                <View style={styles.inputViewStyle}>
                    <InputText
                        defaultValue={state}
                        placeHolder={APP_STRINGS.state}
                        changeValue={text => setState(text)}
                        icons={<FontAwesome5 name="building" size={20} color={COLORS.lightGrayColor} />}
                        textContainer={{ marginLeft: (6).scaleWidth(),paddingRight: (10).scaleWidth() }}
                    />
                </View>
            </View>
            <View style={styles.innerContainer}>
                <View style={styles.inputViewStyle}>
                    <InputText
                        defaultValue={zipCode}
                        placeHolder={APP_STRINGS.zipCode}
                        keyboardType={'number-pad'}
                        maxLength={6}
                        changeValue={value => setZipCode(value)}
                        icons={<Entypo name="location-pin" size={20} color={COLORS.lightGrayColor} />}
                        textContainer={{ marginRight: (6).scaleWidth() }}
                    />
                </View>
                <View style={styles.inputViewStyle}>
                    <InputText
                        defaultValue={phoneNumber}
                        placeHolder={APP_STRINGS.phoneNum}
                        keyboardType={'number-pad'}
                        maxLength={14}
                        changeValue={value => setPhoneNumber(formatPhoneNumber(value))}
                        icons={<Ionicons name="call" size={20} color={COLORS.lightGrayColor} />}
                        textContainer={{ marginLeft: (6).scaleWidth() }}
                    />
                </View>
            </View>
            <InputText
                SecureText={isPasswordSecure}
                defaultValue={password}
                placeHolder={APP_STRINGS.password}
                icons={<MaterialCommunityIcons  name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                eyeIcon={
                    <Octicons name={isPasswordSecure ? "eye-closed" : "eye"}
                        onPress={() => { isPasswordSecure ? setPasswordSecure(false) : setPasswordSecure(true) }}
                        size={20} color={COLORS.lightGrayColor}
                    />}
                secureTextEntry={isPasswordSecure}

                changeValue={(value) => setPassword(value)}
                maxLength={20}
            />
            <InputText
                placeHolder={APP_STRINGS.confirmPassword}
                defaultValue={confirmPassword}
                icons={<MaterialCommunityIcons  name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                changeValue={(value) => setConfirmPassword(value)}
                maxLength={20}
                secureTextEntry={isConfirmPasswordSecure}
                eyeIcon={
                    <Octicons name={isConfirmPasswordSecure ? "eye-closed" : "eye"}
                        onPress={() => { isConfirmPasswordSecure ? setConfirmPasswordSecure(false) : setConfirmPasswordSecure(true) }}
                        size={20} color={COLORS.lightGrayColor}
                    />}
            />
            <View style={styles.checkBoxContainer}>
                <AppCheckBox checked={isCheck} checkedColor={COLORS.lightGrayColor} onPress={() => setIsCheck(!isCheck)} />
                <Text style={styles.checkBoxText}>I agree to the</Text>
                <TouchableOpacity onPress={openUrl}>
                    <Text style={styles.conditionText}>Terms</Text>
                </TouchableOpacity>
                <Text style={styles.checkBoxText}>and</Text>
                <TouchableOpacity onPress={openUrl}>
                    <Text style={styles.conditionText}>Privacy Policy.</Text>
                </TouchableOpacity>
            </View>
            <AppButton onPress={checkValidations} title={APP_STRINGS.signup} />
            <View style={styles.bottomContainer}>
                <Text style={styles.bottomText}>Already have an account?</Text>
                <TouchableOpacity onPress={() => props.navigation.navigate(ROUTES.LOGIN)}>
                    <Text style={styles.bottomButton}>Login</Text>
                </TouchableOpacity>
            </View>
            {isShowLoader && <Loader />}
        </View>
    )
}
export default SignUpComponent;