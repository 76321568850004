import { View, Text, Dimensions, Platform } from "react-native";
import { LATO } from "../../../constants/fonts";
import { StyleSheet } from "react-native";

function TableFooterView(props) {
  const { message = "Games Not Found" } = props;

  return (
    <View style={[styles.mainView,{  height: Dimensions.get("screen").height * 0.7,...props.mainView}]}>
      <Text style={[styles.msg,{...props.msg}]}>{message}</Text>
    </View>
  );
}

export default TableFooterView;

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  msg:{ 
    fontSize: Platform.OS=='web'?16:(15).scaleWidth(), 
    fontFamily: LATO.Bold 
  },
});
