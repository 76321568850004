import moment from "moment";

export default class RewardModel {
    constructor() {
        this.bannerFile = '';
        this.gameDetail = '';
        this.id = '';
        this.description = '';
        this.gameID = '';
        this.position = '';
        this.playerid = '';
        this.rewardID = '';
        this.createdDateTime = '';
        this.assignedDateTime = '';
        this.rewardType = '';
        this.rewardSponsor = '';
        this.rewardGroup = '';
        this.planID = '';
        this.status = '';
        this.reward = '';    
        this.redeemedDateTime = '';
        this.rewardExperationDate = '';
    }

    initWithObj(data) {
        this.bannerFile = data['BannerFile'] || '';
        this.gameDetail = data['GameDetail'] || '';
        this.id = data['ID'] || '';
        this.description = data['Description'] || '';
        this.gameID = data['GameID'] || '';
        this.position = data['Position'] || '';
        this.playerid = data['PlayerID'] || '';
        this.redeemPasscode = data["RedeemPasscode"]||'';
        this.rewardID = data['RewardID'] || '';
        this.createdDateTime = data['CreatedDateTime'] || '';
        this.assignedDateTime = data['AssignedDateTime'] || '';
        this.rewardType = data['RewardType'] || '';
        this.rewardSponsor = data['RewardSponsor'] || '';
        this.rewardGroup = data['RewardGroup'] || '';
        this.planID = data['PlanID'] || '';
        this.status = data['Status'] || '';
        this.reward = data['Reward'] || '';    
        this.redeemedDateTime = data['RedeemedDateTime'] || '';
        this.rewardExperationDate = data['RewardExperationDate'] || '';
        let date =  new Date(data['RewardExperationDate'])
        let utcMoment = moment.utc(data['RewardExperationDate'], "MM-DD-YYYY HH:mm:ss");
        let localMoment = utcMoment.local();
        let createdDate = localMoment.format('MM/DD/YYYY hh:mm A')
        let newDate =  moment.utc(date,"MM-DD-YYYY HH:mm:ss").local().format('DD/MM/YYY HH:mm A')
        this.expiry = newDate;

        return this;
    }
}
