import { StyleSheet } from "react-native";
import { COLORS } from "../../../../../constants";
import { LATO } from "../../../../../constants/fonts";

export const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  textStyle: {
    color: COLORS.green,
    fontSize: (11).scaleWidth(),
    marginLeft: 5,
    fontFamily: LATO.Medium,
  },
  prizeTab: {
    width: (50).scaleWidth(),
    height: (18).scaleHeight(),
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  colorStyle: {
    color: COLORS.black,
  },
  mainView: {
    backgroundColor: "white",
    paddingBottom: (80).scaleHeight(),
    flex: 1,
  },
  flatlist: {
    flex: 1,
    marginTop: (20).scaleHeight(),
    backgroundColor: COLORS.white,
  },
});