
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers';
import lineupSlice from './reducers/lineupReducer';

export const store = configureStore({
    reducer: {
        authReducers: authSlice,
        lineupReducers: lineupSlice,
    },
})

