import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
export const styles = StyleSheet.create({
    mainView:{ 
        flex: 1 
    },
    innerView: {
        shadowOffset: { width: 0, height: 3 },
        shadowRadius: 6,
        shadowOpacity: 0.1,
        elevation: 4,
        shadowColor: COLORS.shadowColor,
        marginTop: (15).scaleHeight(),
        marginHorizontal: (15).scaleWidth(),
        backgroundColor: COLORS.white,
        borderRadius: (10).scaleWidth(),
        marginBottom: (4).scaleHeight(),
        flex:1
      
    },
    titleView: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginHorizontal: (10).scaleWidth(),
        marginVertical: (10).scaleHeight()
    },
    titleText: {
        fontFamily: LATO.Semibold,
        fontSize: (14).scaleWidth(),
        alignSelf: 'center',
        color: COLORS.appColor
    },
    spendPositionView: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: (10).scaleHeight(),
        marginHorizontal: (10).scaleWidth(),
        flex:1
    },
    innerSpendView: {
        flexDirection: 'row',
        alignItems: 'center',
        // flex:1,

    },
    spendText: {
        color: COLORS.veryLightGrayColor,
        fontSize: (14).scaleWidth(),
        // textAlign:'center',
        // flex:1,
        fontFamily:LATO.Medium
    },
    outerTextView: {
        borderWidth: 1,
        marginLeft: (7).scaleWidth(),
        borderRadius: 5,
        borderColor: COLORS.lightBlueBorder,
        justifyContent: 'center',
        flexDirection:'row',
        alignItems:'center',
        
    },
    spendMoney: {
        fontFamily: LATO.Semibold,
        color: COLORS.darkGrayColor,
        fontSize: (13).scaleWidth(),
        paddingLeft: 8,
        alignItems: 'center',
        width: (80).scaleWidth(),
        height: (22).scaleHeight(),
    },
    positionView: {
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor:'yellow',
        justifyContent:'space-between',
        // marginLeft: (30).scaleWidth(),
        flex:1,
       
    },
    positionText: {
        marginRight: 8,
        color: COLORS.veryLightGrayColor,
        fontSize: (14).scaleWidth(),
        fontFamily:LATO.Medium,
        marginLeft: (30).scaleWidth(),
        flex:1,

    },
    bottomView: {
        backgroundColor: COLORS.lightBlueColor,
        flexDirection: 'row',
        borderBottomLeftRadius: (10).scaleWidth(),
        borderBottomRightRadius: (10).scaleWidth(),
        justifyContent: "space-between",
        paddingHorizontal: (15).scaleWidth(),
        // paddingVertical: (4).scaleHeight()
    },
    priceText: {
        color: COLORS.dateColor,
        fontSize: (12).scaleWidth(),
        textAlign: 'center',
        fontFamily: LATO.Medium
    },
    imgView: {
        flexDirection: 'row',
        borderRadius: 4,
        // backgroundColor: COLORS.white,
        paddingVertical: 3,
        paddingHorizontal: 5
    },
    iconView: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    img: {
        marginHorizontal: 3,
    },

    dropdown: {
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 8,
        paddingHorizontal: 8,
    },
    icon: {
        marginRight: 5,
    },
    dropdownContainer: {
     flex:1
      },
    
      dropdownList: {
       flex:1
      },
    
      dropdownItem: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray', // Add a border bottom to separate items
      },
      
})
