import moment from 'moment'
import { STORAGE_KEYS } from '../../constants'
import GameModel from '../../models/gameModel'
import ApiManager from '../../services/apiManager'
import LeaderBannerModel from '../../models/leaderboardBanner';


let apiManager = ApiManager.getInstance();

//----------------Get pending games for the current user---------------------//

export const getLeaderboardBannerAPI = async (gameId) => new Promise((resolve, reject) => {

    let requestParameter = {
        GameId : gameId
    }
    apiManager.onGetApi('Game/GetleaboardBanner', requestParameter).then((responseData) => {

        let json = JSON.parse(responseData.json)
        let tableRecord = json["Table"]
        let data = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                data.push(new LeaderBannerModel().initWithResObj(val))
            });
        }
        resolve(data)

    }).catch((error) => {
        console.log("game/GetleaboardBanner Error:", error);
        reject(error)
    })
})
