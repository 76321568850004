
import { View, TouchableOpacity, Text, Image } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { FontAwesome5 } from "@expo/vector-icons";
import styles from './index.style'
import { COLORS } from "../../../constants";

//---------------------Common header for login & sign up screens----------------//

function AuthHeader(props) {

    const onClickBackButton = () => {
        if (props.navigation) {
            props.navigation.goBack()
        }
    }

    return (
        <View style={[styles.mainView, { ...props.mainView }]}>
            {props.isBack &&
                <TouchableOpacity style={[{ position: 'absolute', left: 15 },{...props.backIcon}]} onPress={onClickBackButton}>
                    <Icon name="arrow-back-outline" size={25} color={'#fff'} />
                </TouchableOpacity>
            }
            <Text style={styles.title}>{props.title}</Text>
            {props.isShow &&
                <View style={{ position: 'absolute', top: 0, left: 80, right: 80, justifyContent: 'center', alignItems: 'center' }}>
                    <Image source={props.img} />
                </View>
            }
            {props.isShowHeader &&
                <View style={{ position: 'absolute', top: 0, left: 75,  justifyContent: 'center', alignItems: 'center' }}>
                    <Image source={props.img} />
                </View>
            }
            <TouchableOpacity style={{ position: 'absolute', right: 15 }}>{props.icon}</TouchableOpacity>
            {props.shouldShowIcon && <View>
                <FontAwesome5 name="question-circle" size={24} color={COLORS.white} style={styles.icon} />
            </View>}
        </View>
    );
}

export default AuthHeader;