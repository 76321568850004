import moment from 'moment'
import { APP_STRINGS, STORAGE_KEYS, URLS } from '../../constants'
import GameModel from '../../models/gameModel'
import { LOCAL_STORAGE } from '../../services'
import ApiManager from '../../services/apiManager'
import LeaderBoardModel from '../../models/leaderboardModel'
import FaqItem from '../../models/faqModel'
import CompletedGameModel from '../../models/completedGameModel'
import { Alert, Linking, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

let apiManager = ApiManager.getInstance();

//----------------Get pending games for the current user---------------------//

export const getPendingGamesAPI = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi('Player/GetPendingGamesForUserDetailed', requestParameter).then((responseData) => {


        let json = JSON.parse(responseData.json)
        console.log(responseData.json,'responseData.json');
        
        let tableRecord = json["Table"]
        console.log(tableRecord,'hdhhdh');
        
        let pendingGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                pendingGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(pendingGamesRecord)

    }).catch((error) => {
        console.log("getPendingGamesAPI Error:", error);
        reject(error)
    })
})

//----------------Get Completed games for the current user---------------------//


export const getCompletedGamesAPI = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi('Player/GetCompletedGamesForUserDetailed', requestParameter).then((responseData) => {

        let json = JSON.parse(responseData.json)
        let tableRecord = json["Table"]
        let completedGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                completedGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(completedGamesRecord)

    }).catch((error) => {
        console.log("getPendingGamesAPI Error:", error);
        reject(error)
    })
});
//----------------Get Cancelled games for the current user---------------------//

export const getCancelledGamesAPI = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi('Player/GetPlayerCancelledGames', requestParameter).then((responseData) => {

        let json = JSON.parse(responseData.json.d)

        let tableRecord = json["Table"]
        let completedGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                completedGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(completedGamesRecord)

    }).catch((error) => {
        console.log("get Cancelled Games API Error:", error);
        reject(error)
    })
});
//----------------Get Active games for the current user---------------------//

export const getActiveGamesAPI = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi('Player/GetActiveGamesForUserDetailed', requestParameter).then((responseData) => {
        let json = JSON.parse(responseData.json)

        let tableRecord = json["Table"]
        let activeGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                activeGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(activeGamesRecord)

    }).catch((error) => {
        console.log("get Active Games API Error:", error);
        reject(error)
    })
});

//----------------Get User Balance for the current user---------------------//


export const getUsersBalance = async () => new Promise((resolve, reject) => {
    AsyncStorage.getItem(STORAGE_KEYS.kEmail).then((res)=>{
        // console.log(res,'Res');
        let requestParameter = {
            ID: res,
        }
        console.log(requestParameter,'Request PArmaterrrf');
        apiManager.onPostApi('Player/GetUsersBalance', requestParameter).then((responseData) => {
            if (responseData.json != undefined) {
                resolve(parseFloat(responseData.json.d).toFixed(2))
            }
        }).catch((error) => {
            console.log("Get Users Balance Error:", error);
            reject(error)
        })
    }).catch((er)=>{
        console.log(er,'Error');
    })
});
//----------------Get challenges for the current user---------------------//

export const getChallengesList = async (requestParameter) => new Promise((resolve, reject) => {


    apiManager.onPostApi('Challenge/GetChallengesList', requestParameter).then((responseData) => {
      
        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let activeGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                activeGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(activeGamesRecord)

    }).catch((error) => {
        console.log("get Challenges Error:", error)
        reject(error)
    })
})

export const getFeaturedGames = async (requestParameter) => new Promise((resolve, reject) => {


    apiManager.onPostApi('Challenge/GetfeaturedChallengesList',requestParameter).then((responseData) => {
      
        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let activeGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                activeGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(activeGamesRecord)

    }).catch((error) => {
        console.log("get Challenges Error:", error)
        reject(error)
    })
})

//------------------ Controller to add a player to the registered players list-------------------//


export const addToRegisteredPlayers = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Player/addToRegisteredPlayers', requestParameter).then((responseData) => {

        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        } else {
            reject(false)
        }

    }).catch((error) => {
        console.log("addToRegisteredPlayers Error:", error);
        reject(error)
    })
});
//------------------ Controller to check if the game is full-------------------//

export const checkGameFull = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Player/IsGameFull', requestParameter).then((responseData) => {

        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        } else {
            reject(false)
        }

    }).catch((error) => {
        console.log("getPendingGamesAPI Error:", error);
        reject(error)
    })
});
//--------------Controller to check if a user is registered for a specific game----------------//

export const isUserRegisteredForThisGame = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Game/IsUserRegisteredforThisGame', requestParameter).then((responseData) => {

        if (responseData.json != undefined) {
            if (responseData.json.d == 'N') {
                resolve(true)
            } else {
                resolve(false)
            }
        }

    }).catch((error) => {
        reject(error)
    })
});
//----------------Controller to insert a game player lineup-----------------//

export const insertGamePlayerLineup = async (gameID, lineUpName) => new Promise((resolve, reject) => {

   

    let requestParameter = {
        "strGame_Id": gameID,
        "strPlayer_Id": LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
        "strDateTime": moment.utc().format('MM-DD-YYYY HH:mm:ss:SSS'),
        "sStatus": "PENDING",
        "strRef1": "1",
        "strRef2": "2",
        "strYcode": "W",
        "strMcode": "I",
        "strDcode": "N",
        "strScode": "N",
        "strTcode": "I",
        "strFcode": "N",
        "strIcode": "G",
        "strLineupName": lineUpName
    }
    apiManager.onPostApi('LineUp/InsertIntoStoredProcedureInsertGamePlayerLineup', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        }
    }).catch((error) => {
        reject(error)
    })
});

//--------------Controller to get registration fee by game ID-------------------//

export const getRegFeeByGameId = async (gameID) => new Promise((resolve, reject) => {

    let requestParameter = {
        locGame_Id: gameID
    }

    apiManager.onPostApi('Game/GetRegFeebyGameId', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        }
    }).catch((error) => {
        reject(error)
    })
});

//---------------Controller to insert general ledger-------------------------//

export const insertGeneralLedger = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Player/InsertGeneralLedger', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        }
    }).catch((error) => {
        reject(error)
    })
});
//----------------Controller to subtract from registered players-------------------------//

export const subtractFromRegisteredPlayers = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Game/SubtractFromRegisteredPlayers', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        }
    }).catch((error) => {
        reject(error)
    })
});
//-------------------Controller to unregister a player from a game-------------------------//

export const unregisterPlayerFromGame = async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi('Game/UnregisterPlayerFromGame', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            resolve(responseData.json.d)
        }
    }).catch((error) => {
        reject(error)
    })
})
//----------------Controller to get the leaderboard-------------------------//

export const getLeaderBoard = async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi('Game/GlGetLeaderBoard', requestParameter).then((responseData) => {
        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let leaderBoardRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                leaderBoardRecord.push(new LeaderBoardModel().initWithLeaderBoardRes(val))
            })
        }
        resolve(leaderBoardRecord)
    }).catch((error) => {
        reject(error)
    })
})
//----------------Controller to get leaderboard details-------------------------//

export const getLeaderBoardDetails = async (gameID, playerId) => new Promise((resolve, reject) => {
    let requestParameter = {
        PlayerId: playerId,
        GameId: gameID
    }
    apiManager.onPostApi('LearboardDetail/GetLeaderBoardDetails', requestParameter).then((responseData) => {
        let d = responseData.json.d
        console.log(responseData.json.d,'responseData.json.d');
        
        let gameDetailsModel = new CompletedGameModel().initWithCompletedGameRes(d)
        resolve(gameDetailsModel)

    }).catch((error) => {
        reject(error)
    })
})

//----------------Controller to get game status-------------------------//

export const getGameStatus = async (gameID) => new Promise((resolve, reject) => {
    let requestParameter = {
        GameId: gameID
    }
    apiManager.onGetApi('Game/GetGameStatus', requestParameter).then((responseData) => {
        let d = responseData.json.d
        resolve(d)

    }).catch((error) => {
        reject(error)
    })
})

//---------------------- Define a function to get the application status---------------------- //

export const getAppStatus = async () => new Promise((resolve, reject) => {
    apiManager.onGetApi('Token/UpdateVersion').then((responseData) => {
        const { status, message } = responseData.json;

        if (status === 0) {
            const storeUrl = Platform.OS === "ios" ? URLS.APP_STORE_URL : URLS.PLAY_STORE_URL;
            Alert.alert(APP_STRINGS.appName, message, [
              {
                text: APP_STRINGS.update,
                onPress: () => {
                  Linking.openURL(storeUrl);
                },
              },
            ]);
          }
      
        resolve(responseData.json.status)
    }).catch((error) => {
        console.log("get Update Version API Error:", error)
        reject(error)
    })
})


export const getParticipationStatus = async (state) => new Promise((resolve, reject) => {
    let request = {
        state:state
    }
    apiManager.onGetApi('Global/participation',request).then((responseData) => {
        const { flag, message } = responseData.json;
        resolve(responseData.json)
    }).catch((error) => {
        console.log("getParticipationStatus Error:", error)
        reject(error)
    })
})
export const getFAQs = async () => new Promise((resolve, reject) => {
    apiManager.onGetApi('Player/GetFAQ').then((responseData) => {
        let data = []
        if(responseData.json){
            responseData.json.map(val=>{
                data.push(new FaqItem(val))
            })
        }
        resolve(data)
    }).catch((error) => {
        console.log("get Update Version API Error:", error)
        reject(error)
    })
})
export const fetchAddress = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
            .then((response) => response.json())
            .then((data) => {
                const address = data.address
                resolve(address);
            })
            .catch((error) => {
                reject(error);
            });
    });
};