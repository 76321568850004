import { Platform, StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale, verticalScale,moderateScale } from "react-native-size-matters";


export const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.appColor,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 30
  },
  imageProfile: {
    borderColor: COLORS.white,
    borderWidth: 2,
    marginLeft: (10).scaleWidth(),
  },

  subinnerContainer: {
    flex: 1,
    marginHorizontal: moderateScale(15),
    marginTop:moderateScale(10)
  },
  titleText: {
    fontFamily: LATO.Semibold,
    color: COLORS.white,
  },
  subTitleText: {
    fontFamily: LATO.Medium,
    color: COLORS.lightWhite,
  },

  profileText: {
    fontFamily: LATO.Medium,
    fontSize: moderateScale(14),
    color: '#EDAB00',
    textDecorationLine: 'underline',
    marginTop: (5).scaleWidth()
  },

  iconStyle: {
    right: 15
  },
  drawerItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15
  },
  drawerItemTitle: {
    paddingLeft: 15,
    flex: 1,
    paddingRight: moderateScale(5),
    // backgroundColor:'red',
    color: '#06002C',
    fontFamily: LATO.Medium,
    fontSize:moderateScale(16)
  },
  dividerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey'
  }
})