import { getAddedCardList, removeCardAPI } from "../../../controllers/paymentController"
import { APP_STRINGS, COLORS, ROUTES, STORAGE_KEYS } from "../../../constants"
import { FacebookLoader } from "react-native-easy-content-loader"
import { View, Text, FlatList, Alert } from "react-native"
import { LOCAL_STORAGE } from "../../../services"
import TableFooterView from "../tableFooterView"
import { useEffect, useState } from "react"
import { SpeedDial } from '@rneui/themed'
import { styles } from "./index.style"
import { PAYMENTLIST } from ".."
import Loader from "../loader"


function PaymentComponent(props) {

    const [cardsList, setCardsList] = useState([])
    const [isShimmer, setShimmer] = useState(false)
    const [isShowLoader, setShowLoader] = useState(false)
//---------------Get Card List Api calling-----------------//

    useEffect(() => {
        setShimmer(true)

        const unsubscribe = props.navigation.addListener('focus', () => {
            getCardList()
            return unsubscribe
        }, [])


    }, [])

    const getCardList = () => {
        getAddedCardList().then((cardRes) => {
            setCardsList(cardRes)
        }).catch(() => {
            setShimmer(false)
        }).finally(() => {
            setShimmer(false)
        })
    }
//-----------------Rendering shimmer--------------------//
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {
                    Array(10).fill(0).map((i) => {
                        return <FacebookLoader active={true} avatarStyles={styles.avatar} pRows={0} />
                    })
                }
            </View>
        )
    }
    //-------------------------------Delete Card--------------//

    const didClickedDelete = (item) => {

        Alert.alert(APP_STRINGS.appName, 'Are you sure you want to delete?', [
            {
                text:APP_STRINGS.cancel,
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            },
            { text: APP_STRINGS.deleteBtn, onPress: () => deleteCard(item) },
        ])
    }
//---------------------------Delete Card Details--------------------//
    const deleteCard = (item) => {

        let requestParameter = {
            userName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            card_number: item.cardNumber
        }

        setShowLoader(true)
        removeCardAPI(requestParameter).then(() => {
            setShowLoader(false)
            getCardList()
        }).catch(() => {
            setShowLoader(false)
        })
    }
//-----------------------------Render Payment List item--------------------//
    const renderItem = ({ item }) => {
        return (
            <PAYMENTLIST itemData={item} onClickedDelete={didClickedDelete} />
        )
    }

    return (
        <View style={styles.container}>
            <Text style={styles.titleStyle}>Debit/Credit Cards</Text>

            {isShimmer ? renderShimmer() :
                <FlatList
                    data={cardsList}
                    keyExtractor={(item) => item.cardID}
                    renderItem={renderItem}
                    ListEmptyComponent={!isShowLoader && <TableFooterView message={'No Cards Found'} />}
                />

            }
            <SpeedDial overlayColor="transparent"
                color={COLORS.appColor}
                onOpen={() => props.navigation.navigate(ROUTES.CARDDETAILS)}
                icon={{ name: "add", color: COLORS.white }}>
            </SpeedDial>

            {isShowLoader && <Loader />}
        </View>
    )
}
export default PaymentComponent