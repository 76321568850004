class CompletedGameModel {

    constructor(
        score = 0.00,
        lineupPosition = [],
    ) {
        this.score = score;
        this.lineupPosition = lineupPosition;
    }

    initWithCompletedGameRes(gameRes) {
        let scoreArray = gameRes['score'] || []
        console.log('score Array',scoreArray,gameRes['score']);
        
        if (scoreArray.length > 0) {
            this.score = scoreArray[0].score || 0.00
        }

        this.lineupPosition = [];
        let lineupPositionArray = gameRes['lineupPosition'] || [];
        lineupPositionArray.forEach(element => {
            this.lineupPosition.push(new LineupPosition().initWithLineupData(element));
        });
        return this;
    }
}
export default CompletedGameModel;

class LineupPosition {
    constructor(
        symbol = '',
        spend = 0.00,
        open = 0.00,
        shares = 0.00,
        position = '',
        current = 0.00,
        delta = 0.00,
        total = 0.00
    ) {
        this.symbol = symbol;
        this.spend = spend;
        this.open = open;
        this.shares = shares;
        this.position = position;
        this.current = current;
        this.delta = delta;
        this.total = total;
    }

    initWithLineupData(lineRes) {
        this.symbol = lineRes['symbol'] || '';
        this.spend = lineRes['spend'] || 0.00;
        this.open = parseFloat(lineRes['open']).toFixed(2) || 0.00;
        this.shares = parseFloat(lineRes['shares']).toFixed(2) || 0.00;
        this.position = lineRes['position'] || '';
        this.current = parseFloat(lineRes['current']).toFixed(2) || 0.00;
        this.delta = parseFloat(lineRes['delta']).toFixed(2) || 0.00;
        this.total = parseFloat(lineRes['total']).toFixed(2) || 0.00;
        return this;
    }
}
