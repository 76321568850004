import {KeyboardAvoidingView, Platform, ScrollView, StyleSheet,Text, View,useWindowDimensions} from "react-native"
import {getRewards, validatePasscode} from "../../../../../controllers/rewardController"
import RewardComponent from "../../../../components/gameComponent/rewardComponent"
import { ROUTES,STORAGE_KEYS} from "../../../../../constants"
import RewardPopup from "../../../../components/rewardPopup"
import { useIsFocused, useNavigation } from "@react-navigation/native"
import { LOCAL_STORAGE } from "../../../../../services"
import { LATO } from "../../../../../constants/fonts"
import Loader from "../../../../components/loader"
import { useEffect, useState } from "react"
import moment from "moment"
import TableFooterView from "../../../../components/tableFooterView"



export default function ActiveRewards() {

  const [isLoading, setIsLoading] = useState(false)
  const [selectedReward, setSelectedReward] = useState(false)
  const [hasPermission, setHasPermission] = useState(false)
  const [activeRewards, setActiveRewards] = useState([])
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const {width} = useWindowDimensions()
//--------------------Width Calculations according to size of the screen---------------//
  let isLandscape = 0
  if (width <= 500) {
    isLandscape = "100%"
  } else if (501 < width && width <= 1100) {
    isLandscape = "48%"
  } else if (1100 < width && width <= 1400) {
    isLandscape = "33%"
  } else {
    isLandscape = "24%"
  }
//-----------------------Use effect to get Rewards-------------------------//
    useEffect(() => {
      if(isFocused){
        setIsLoading(true)
        getRewardsfunc()
      }
    }, [isFocused])
//-----------------------Function to fetch rewards-------------------------//
    const getRewardsfunc = () => {
        setIsLoading(true)
        getRewards().then((res) => {
          let temp=[...res]
          let rewardsData = temp.filter((item) => item.status.trim() == "ASSIGNED")
          let newArray =  rewardsData.sort((a, b) => {
            const dateA = new Date(a.rewardExperationDate)
            const dateB = new Date(b.rewardExperationDate)
            return dateA - dateB
            })
            setActiveRewards(newArray)
        })
        .catch((err) => {
            console.log(err, "error")
        })
        .finally(() => {
            setIsLoading(false)
        })
    }
 //-----------------------Function to handle QR Button Press-------------------------//

    const onRedeemWithQR = async () => {
        setHasPermission(false)       
        navigation.navigate(ROUTES.QR_CODE, { selectedReward: selectedReward })
    }
 //-----------------------Function to handle when passcode is entered-------------------------//

    const onRedeemWithPasscode = async (pass) => {
        let requestParameter = {
            RewardId: selectedReward.rewardID,
            PlayerId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            RedeemedPassCode: pass,
            RedeemedDateTime: moment.utc(new Date()),
        }
        console.log("Request ", requestParameter)
        validatePasscode(requestParameter).then((res) => {
            if (res.success) {
            alert(`Congratulations!, You won ${selectedReward.reward}`)
            } else {
            alert(res.message, "1")
            navigation.pop()
            }
        })
        .catch((err) => {
            console.log("errr", err)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }
//-----------------------Function to show Pop up for redemption-------------------------//

    const onPressActiveReward = (res) => {
        setHasPermission(true)
        setSelectedReward(res)
    }

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView  style={styles.container} behavior={Platform.OS === "ios" ? "padding" : null}>
        <View style={styles.container}>
          <View style={[styles.container]}>
            <ScrollView>
              <View style={styles.innerView}>
                {isLoading && ( <Loader />) }
                {activeRewards.length == 0 && !isLoading && 
                      <TableFooterView message={'No Rewards Available at the moment'}/>}
                 {activeRewards.length > 0 && (
                  activeRewards.map((res, index) => (
                    <View key={index} style={{ width: isLandscape, margin: 1 }}>
                      <RewardComponent
                        cellType="reward"
                        gameDetail={res.reward}
                        reward
                        data={res}
                        onPress={onPressActiveReward}
                      />
                    </View> )))} 
              </View>
            </ScrollView>
          </View>
          {hasPermission && (
                <RewardPopup
                    onRedeem={onRedeemWithQR}
                    onRedeemPasscode={onRedeemWithPasscode}
                    onDismissPopup={() => setHasPermission(false)}
                    data={selectedReward}
                />
           )}
           {isLoading && <Loader />}
        </View>
      </KeyboardAvoidingView>
    </View>
  )
}

const styles = StyleSheet.create({
    container:{ 
        flex: 1 
    },
    innerView:{
        flexDirection: "row",
        flex: 1,
        flexWrap: "wrap",
        marginTop: 20,
    },
    emptyContainer:{
        flex:1,
        alignItems:'center',
        justifyContent:'center'
    },
    emptyText:{
        fontFamily:LATO.Bold,
        fontSize:18,
        textAlign:'center'
    }
})


