export default {
  accHolder: "Account holder Name",
  accNum: "Account Number",
  activeRewards: "Active Rewards",
  addFund: "ADD FUNDS",
  address: "Address",
  alreadyReg: "You are already registered for this game.",
  amt: "Amount",
  amtAdded: "Amount Added Succesfully.",
  appName: "Day Trader",
  buildLineup: "Build Lineup",
  cancel: "Cancel",
  cancelled: "Cancelled",
  cancelledGames: "Cancelled Games",
  challenge: "Challenge",
  challenges: "Challenges",
  chooseStocks: "Choose More Stocks",
  city: "City",
  completed: "Completed",
  completedGames: "Completed Games",
  congratulations:"Congratulations! You are now subscribed to our monthly plan",
  connectingIos:'Connecting with iTunes Account...',
  connectingAndroid:'Connecting with Google Play Account...',
  confirmPass: "Confirm New Password",
  confirmReg: "Confirm your registration",
  confirmPassword: "Confirm Password",
  configBtn: "Configure Lineup",
  create: "Create!",
  currentPassword: "Current Password",
  deleteAcc: "Delete My Account",
  deleteAccMsg: "Are you sure you want to delete this Account?",
  deleteBtn: "Delete",
  deleteMsg: "You can not delete this one as this is already been in use",
  deleteText: "Yes, Delete it!",
  email: "Email Address",
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  error: "Error",
  fieldsReq: "Please enter all values",
  firstName: "First Name",
  gameFull: "Game Full",
  gameFullMsg: "This game is full",
  goToAddFund:'GO TO ADD FUNDS',
  goToWallet: "GO TO MY WALLET",
  history: "History",
  home: "Home",
  lastName: "Last Name",
  leaderboard: "Leaderboard",
  limitAlert: "Reached the maximum limit of selected Lineups!",
  lineUpValidationMessage: "You cannot have a lineup with less than 8 stocks",
  lineup: "Lineups",
  lineupMsg: "You don't have any lineups right now. Please create a lineup to proceed.",
  loading:"Loading...",
  login: "LOGIN",
  logout: "Logout",
  logoutMsg: "Are you sure you want to logout?",
  maxSpend: "Max Spend is $150,000",
  minusCircle: "minus-circle",
  myGames: "My Games",
  myGrps:"My Groups",
  availableGrps:'Available Groups',
  myLineup: "My Lineups",
  my_line: "My Lineup",
  newPassword: "New Password",
  no: "No",
  noChallenge: "No Challenge Found",
  ok: "Ok",
  open: "OPEN",
  password: "Password",
  passwordPattern: /^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\w\W]{8,}$/,
  payments: "Manage Payments",
  pending: "Pending",
  pendingGames: "Pending Games",
  phoneNum: "Phone Number",
  plusCircle: "plus-circle",
  positionDown: "Down",
  positionUp: "Up",
  remember: "Remember me?",
  redeemedRewards: "Redeemed Rewards",
  routeNum: "Routing Number",
  running: "Running",
  runningGames: "Running Games",
  searchBarPlaceholder: "Search by Name or Symbol",
  searchLocation:'Location / Zip Code',
  selectLineup: "Select a lineup from the list below for registration.",
  signup: "SIGN UP",
  skuAndroid: "monthly_plan_for_paid_games",
  skuIos: "monthlyPlanForPaidGames",
  spendLimit: "Spend Exceeds $1,000,000 Limit",
  state: "State",
  stateNotAllowed: "Unfortunately, participants from your current state are not permitted to join this game.",
  subscribe: "SUBSCRIBE",
  success: "Successfully Added",
  sureMsg: "Are you sure you want to delete?",
  submit: "SUBMIT",
  transactionFailed: "Transaction Failed.",
  unregMsg: "Would you like to unregister?",
  undoMsg: "You won't be able to undo this!",
  update: "UPDATE",
  userName: "User Name",
  verifyReceipt:'Verifying data to server, Please Wait...',
  withdrawFund: "WITHDRAW FUNDS",
  yesConfirm: "Yes, Confirm it!",
  yesDelete: "Yes, Delete!",
  yesLogout: "Yes, Logout!",
  yesUnreg: "Yes, Unregister!",
  zipCode: "Zip Code",
  zipPattern: /^[0-9]{5,6}$/
};
