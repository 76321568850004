import { CANCELLEDGAME, CHALLENGEREGISTER, COMPLETEDGAME, LINEUP, ACTIVE_REWARD, PENDINGGAME, REDEEMED_REWARD, RUNNINGGAME, MY_GROUPS, AVAILABLE_GROUPS } from "../../screens/containers/dashboardContainers";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { APP_STRINGS, COLORS, ROUTES } from "../../constants";
import { LATO } from "../../constants/fonts";


const TopBar = createMaterialTopTabNavigator();
export const HomeTopBar = () => {
    
    return (
        <TopBar.Navigator
            screenOptions={{
                tabBarIndicatorStyle: { backgroundColor: COLORS.topBarLabel },
                tabBarScrollEnabled: true,
                tabBarActiveTintColor: COLORS.topBarLabel,
                tabBarInactiveTintColor: COLORS.appColor,
                tabBarLabelStyle: { textTransform: "none", fontSize: (14).scaleWidth(), fontFamily: LATO.Medium },
            }}>
            <TopBar.Screen name={ROUTES.PENDINGGAME} component={PENDINGGAME} options={{ title: APP_STRINGS.pendingGames }} />
            <TopBar.Screen name={ROUTES.RUNNINGGAME} component={RUNNINGGAME} options={{ title: APP_STRINGS.runningGames }} />
            <TopBar.Screen name={ROUTES.COMPLETEDGAME} component={COMPLETEDGAME} options={{ title: APP_STRINGS.completedGames }} />
            <TopBar.Screen name={ROUTES.CANCELLEDGAME} component={CANCELLEDGAME} options={{ title: APP_STRINGS.cancelledGames}} />
        </TopBar.Navigator>
    )
}
export const LineupTopBar = () => {
    return (
        <TopBar.Navigator
            screenOptions={{
                tabBarIndicatorStyle: { backgroundColor: COLORS.topBarLabel },
                tabBarActiveTintColor: COLORS.topBarLabel,
                tabBarInactiveTintColor: COLORS.appColor,
                tabBarLabelStyle: { textTransform: "none", fontSize: (14).scaleWidth(), fontFamily: LATO.Medium }
            }}
        >
            <TopBar.Screen name={ROUTES.CHALLENGEREGISTER} component={CHALLENGEREGISTER} options={{ title: APP_STRINGS.myLineup }} />
            <TopBar.Screen name={ROUTES.LINEUP} component={LINEUP} options={{ title: APP_STRINGS.buildLineup }} />
        </TopBar.Navigator>
    )
}

export const RewardTopBar = () => {
    return (
        <TopBar.Navigator
            screenOptions={{
                tabBarIndicatorStyle: { backgroundColor: COLORS.topBarLabel },
                tabBarActiveTintColor: COLORS.topBarLabel,
                tabBarInactiveTintColor: COLORS.appColor,
                tabBarLabelStyle: { textTransform: "none", fontSize: (14).scaleWidth(), fontFamily: LATO.Medium }
            }}
        >
            <TopBar.Screen name={ROUTES.ACTIVE_REWARD} component={ACTIVE_REWARD} options={{ title: APP_STRINGS.activeRewards }} />
            <TopBar.Screen name={ROUTES.REDEEM_REWARD} component={REDEEMED_REWARD} options={{ title: APP_STRINGS.redeemedRewards }} />
        </TopBar.Navigator>
    )
}

export const GroupTopBar = () => {
    return (
        <TopBar.Navigator
            screenOptions={{
                tabBarIndicatorStyle: { backgroundColor: COLORS.topBarLabel,height:2 },
                tabBarActiveTintColor: COLORS.topBarLabel,
                swipeEnabled:false,
                tabBarInactiveTintColor: COLORS.appColor,
                tabBarLabelStyle: { textTransform: "none", fontSize: (14).scaleWidth(), fontFamily: LATO.Medium }
            }}
        >
            <TopBar.Screen name={ROUTES.MY_GROUP} component={MY_GROUPS} options={{ title: APP_STRINGS.myGrps }} />
            <TopBar.Screen name={ROUTES.AVAILABLE_GROUP} component={AVAILABLE_GROUPS} options={{ title: APP_STRINGS.availableGrps }} />
        </TopBar.Navigator>
    )
}
