
class StocksListData {

  constructor(id, symbol, companyName, price, isSelected) {
    this.id = id
    this.symbol = symbol
    this.companyName = companyName
    this.price = price
    this.isSelected = isSelected
  }
}
export default StocksListData; 