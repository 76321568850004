import { View, Text, TouchableOpacity } from "react-native";
import InputText from "../inputText";
import { styles } from "./index.style";
import { Ionicons, Zocial, MaterialIcons, Entypo, FontAwesome5 } from "@expo/vector-icons";
import { APP_STRINGS, COLORS, ERROR_STRING, ROUTES, STORAGE_KEYS } from "../../../constants";
import AppButton from "../appButton";
import { useEffect, useState } from "react";
import { getProfileDetails, updateUserInfo } from "../../../controllers/signupController";
import { TOAST_MESSAGE } from "../../../utils";
import Loader from "../loader";
import { formatPhoneNumber, parseFormattedNumber } from "../../../utils/appHelper";
import { FacebookLoader } from 'react-native-easy-content-loader';
import { LOCAL_STORAGE } from "../../../services";


function ProfileComponent(props) {

    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [fName, setFname] = useState('');
    const [lName, setLname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [isShowLoader, setShowLoader] = useState(false);
    const [isShowShimmer, setShowShimmer] = useState(false);



    useEffect(() => {
        getProfileDetailsAPI()
    }, [])
//-------------------Get Profile Details API------------------//
    const getProfileDetailsAPI = () => {

        setShowShimmer(true)
        getProfileDetails().then((resModel) => {
            setUserName(resModel.userName)
            setFname(resModel.firstName)
            setLname(resModel.lastName)
            setEmail(resModel.email)
            setPhoneNumber(formatPhoneNumber(resModel.phone))
            setAddress(resModel.address1)
            setCity(resModel.city)
            setState(resModel.state)
            setZipCode(resModel.zip)
        }).catch(() => {
            setShowShimmer(false)

        }).finally(() => {
            setShowShimmer(false)
        })
    }

    const didClickUpdateBtn = () => {

        if (checkValidations()) {
            prepareSignupData()
        }

    }
//---------------------Update Profile Function-----------------------//
    const prepareSignupData = () => {

        let param = {
            "userName": userName,
            "email": email,
            "firstName": fName,
            "lastName": lName,
            "address1": address,
            "Address2": "",
            "city": city,
            "state": state,
            "zip": zipCode,
            "phone": parseFormattedNumber(phoneNumber)
        }

        setShowLoader(true)
        console.log(param,'param');  
        updateUserInfo(param).then((response) => {
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kFirstName, fName)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kLastName, lName)
        }).catch((err) => {
            setShowLoader(false)
        }).finally(() => {
            setShowLoader(false)
        })

    }
//---------------Validations-----------------------//
    const checkValidations = () => {

        if (fName.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankFname, "1")
            return false
        }

        if (lName.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankLname, "1")
            return false
        }

        if (email.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.emailBlank, "1")
            return false
        }

        if (!email.match(APP_STRINGS.emailRegex)) {
            TOAST_MESSAGE(ERROR_STRING.InvalidEmail, "1")
            return false
        }

        // if (address.trim().length == 0) {
        //     TOAST_MESSAGE(ERROR_STRING.blankAddress, "1")
        //     return false
        // }

        if (city.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankCity, "1")
            return false
        }

        if (state.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankState, "1")
            return false
        }

        if (zipCode.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankZip, "1")
            return false
        }

        if (!APP_STRINGS.zipPattern.test(zipCode)) {
            TOAST_MESSAGE(ERROR_STRING.validZip, '1');
            return
        }

        // if (phoneNumber.trim().length == 0) {
        //     TOAST_MESSAGE(ERROR_STRING.blankPhone, "1")
        //     return false
        // }

        // let removeFormatter = parseFormattedNumber(phoneNumber)
        // if (removeFormatter.length != 10) {
        //     TOAST_MESSAGE(ERROR_STRING.validPhone, "1")
        //     return false
        // }

        return true

    }
//-------------------------Rendering Shimmer untill the data is loaded-------------------//
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {
                    Array(10).fill(0).map((i) => {
                        return <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                    })
                }
            </View>
        )
    }

    const renderBody = () => {

        return (
            <View style={styles.container}>
                <InputText
                    defaultValue={userName}
                    placeHolder={APP_STRINGS.userName}
                    autocapitalize={'none'}
                    keyboardType={'email-address'}
                    icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                    maxLength={30}
                    changeValue={text => setUserName(text)}
                    editable={false}
                    textContainer={{ backgroundColor: COLORS.lightGrayColor }}
                />

                <InputText
                    value={fName}
                    placeHolder={APP_STRINGS.firstName}
                    autocapitalize={'none'}
                    icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setFname(text)}
                />


                <InputText
                    value={lName}
                    placeHolder={APP_STRINGS.lastName}
                    autocapitalize={'none'}
                    icons={<Ionicons name="person" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setLname(text)}
                />

                <InputText
                    defaultValue={email}
                    placeHolder={APP_STRINGS.email}
                    icons={<Zocial name="email" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setEmail(text)}
                />
                <InputText
                    defaultValue={phoneNumber}
                    placeHolder={APP_STRINGS.phoneNum}
                    keyboardType={'number-pad'}
                    icons={<Ionicons name="call" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={value => setPhoneNumber(formatPhoneNumber(value))}
                />

                <InputText
                    defaultValue={address}
                    placeHolder={APP_STRINGS.address}
                    icons={<Entypo name="location" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setAddress(text)}
                />

                <InputText
                    defaultValue={city}
                    placeHolder={APP_STRINGS.city}
                    icons={<MaterialIcons name='location-city' size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setCity(text)}
                />

                <InputText
                    defaultValue={state}
                    placeHolder={APP_STRINGS.state}
                    icons={<FontAwesome5 name="building" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={text => setState(text)}
                />

                <InputText
                    defaultValue={zipCode}
                    placeHolder={APP_STRINGS.zipCode}
                    keyboardType={'number-pad'}
                    icons={<Entypo name="location-pin" size={20} color={COLORS.lightGrayColor} />}
                    changeValue={value => setZipCode(value)}
                />

                <AppButton title="UPDATE" onPress={didClickUpdateBtn} />

                <TouchableOpacity style={{ marginBottom: 10 }} onPress={() => props.navigation.navigate(ROUTES.CONFIRMPASSWORD)}>
                    <Text style={styles.bottomText}>Change Password?</Text>
                </TouchableOpacity>
                {isShowLoader && <Loader />}
            </View>
        )

    }

    return (
        isShowShimmer ? renderShimmer() : renderBody()
    )
}
export default ProfileComponent;