import { FlatList, Platform, View, StatusBar, RefreshControl, Alert } from "react-native"
import { getPendingGamesAPI } from "../../../../../controllers/gamesController"
import GameDetailPopUp from "../../../../components/gameDetailPopUp"
import TableFooterView from "../../../../components/tableFooterView"
import {InstagramLoader } from 'react-native-easy-content-loader'
import { SafeAreaView } from "react-native-safe-area-context"
import { APP_STRINGS } from "../../../../../constants"
import { GAMECOMPONENT } from "../../../../components"
import SplashScreen from 'react-native-splash-screen'
import { APP_STYLES } from "../../../../../utils"
import { useEffect, useState } from "react"
import { styles } from "./index.style"




function PendingGame({ navigation }) {

//---------------------State variables using the useState hook-----------------//
  const [pendingGames, setPendingGames] = useState([])
  const [isLoader, setLoader] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [gameTitle,setGameTitle] = useState('')
  const [gameDescription,setGameDescription] = useState()
  
//---------------------useEffect hook to handle component initialization and fetching data-----------------//
  useEffect(() => {   
    //---------------------Set loader to true and add a listener to fetch pending games on focus-----------------//
    setLoader(true)
    const unsubscribe = navigation.addListener('focus', () => {
      getPendingGames()

      return unsubscribe
    }, [])

    //---------------------Cleanup function for the useEffect hook-----------------//
    return () => {
      
    }
  }, [])

  //--------------------- Function to fetch pending games from the API-----------------//
  const getPendingGames = () => {
    getPendingGamesAPI().then((response) => {
      setPendingGames(response)
    }).catch((error) => {
      console.log('error', error)
      setLoader(false)
      setRefreshing(false)
    }).finally(() => {
      setLoader(false)
      setRefreshing(false)
    })
  }

  //--------------------- Function to handle refreshing of pending games-----------------//
  const onRefreshPending = () => {
    setRefreshing(true)
    getPendingGames()
  }

  //---------------------Function to handle pressing a cell/item in the list-----------------//
  const didPressCell = (item) => {
    Alert.alert(APP_STRINGS.appName, `Pending ${item.description}`, [
      {
        text: 'OK',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ])
  }


 
//---------------------- Function to Handle Question Mark Button Press ----------------------//
      const onPressQuestionMark = (title,description,gameDetail) => {
          if(gameDetail.length!=0){
              setShowPopup(true)
              setGameTitle(title)
              setGameDescription(description)
          }
      }

//---------------------Function to render a shimmer effect while loading-----------------//

  const renderShimmer = () => {
    return (
      <>
        <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
        <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
        <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
      </>
    )

  }
//---------------------Function to render a single cell/item in the FlatList-----------------//

  const renderPendingGamesCell = ({ item }) => {
    let parts =  item.gameDetail.length==0?'':item.gameDetail.split(':')
    let title = item.gameDetail.length==0?'':parts[0].trim()
    let description =  item.gameDetail.length==0?'':parts.slice(1).join(':').trim()
    return (
      <GAMECOMPONENT 
        onPress={didPressCell} 
        onPressQuestionMark={()=>onPressQuestionMark(title,description,item.gameDetail)}
        data={item} 
        cellType={'Pending'}
       />
    )
  }
  //---------------------Render the main component-----------------//
  return (
    <SafeAreaView edges={['left', 'right']} style={APP_STYLES.bodyContainer}>
      <StatusBar barStyle={"light-content"} />
      <View style={styles.container}>
        {isLoader ? renderShimmer() :
          <FlatList
            showsVerticalScrollIndicator={false}
            data={pendingGames}
            keyExtractor={(item) => item.gameID}
            renderItem={renderPendingGamesCell}
            style={styles.flatlist}
            ListEmptyComponent={!isLoader && <TableFooterView />}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefreshPending} />
            }
          />
        }
         {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
              />
            )}
      </View>
    </SafeAreaView>
  )
}


export default PendingGame

//------------- Export the PendingGame component as the default export---------------//
