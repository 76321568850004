import * as React from 'react';
import AuthNavigation from './authNavigation';
import {View} from 'react-native'
import { useFonts } from 'expo-font';
function AppNavigation() {
    const [fontsLoaded,fontError] = useFonts({
        'Lato-Black': require('../../assets/fonts/Lato-Black.ttf'),
        'Lato-Bold': require('../../assets/fonts/Lato-Bold.ttf'),
        'Lato-Semibold': require('../../assets/fonts/Lato-Semibold.ttf'),
        'Lato-Medium':require('../../assets/fonts/Lato-Medium.ttf'),
        'Overpass-Bold':require('../../assets/fonts/Overpass-Bold.ttf'),
     
      }) 
    const onLayoutRootView = React.useCallback(async () => {
        if (fontsLoaded ) {
          console.log('Fonts Loaded !!!!');
        }
      }, [fontsLoaded, fontError]);
    return (
        <View style={{flex:1}} onLayout={onLayoutRootView}>
            <AuthNavigation />
        </View>
    );
}

export default AppNavigation;