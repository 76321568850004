import { View, TextInput, Platform } from "react-native";
import { COLORS } from "../../../constants";
import { styles } from "./index.style"
import { TouchableOpacity } from "react-native";
import { _scale } from "../../../utils/appHelper";
function InputText(props) {
    return (
        <View style={[styles.textContainer, props.textContainer]}>
            <View style={[{ width:Platform.OS=='web'?35: (props.icons ? 35 : 5).scaleWidth(), height:Platform.OS=='web'?35:  (35).scaleHeight(), justifyContent: 'center', alignItems: 'center' },{...props.iconView}]}>
                {props.icons}
            </View>
            <TextInput
                style={[styles.inputStyle, { ...props.inputStyle }]}
                defaultValue={props.defaultValue}
                value={props.value}
                placeholder={props.placeHolder}
                placeholderTextColor={props.placeholderTextColor ?props.placeholderTextColor: COLORS.lightGrayColor}
                autoCapitalize={props.autoCapitalize}
                keyboardType={props.keyboardType}
                secureTextEntry={props.secureText}
                onChangeText={(text) => props.changeValue(text)}
                maxLength={props.maxLength}
                clearButtonMode={'while-editing'}
                spellCheck={false}
                autoCorrect={false}
                editable={props.editable}
                ref={props.reference}
                {...props}
            />

            {props.eyeIcon &&
                <TouchableOpacity onPress={props.onPressEyeIcon}style={{ width: _scale(35), height: _scale(35), justifyContent: 'center' }}>
                    {props.eyeIcon}
                </TouchableOpacity>
            }
        </View >
    )
}
export default InputText;