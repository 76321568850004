
import { STORAGE_KEYS } from '../../constants'
import { LOCAL_STORAGE } from '../../services'
import ApiManager from '../../services/apiManager'
import SubscriptionModel from '../../models/subStatusModel'


let apiManager = ApiManager.getInstance()
const controllerName = 'Subscription'

//---------------Function to get the list of added cards for a user----------------------//

 const checkIfUserSubscribed = async () => new Promise(async(resolve, reject) => {
    let endPoint = 'GetSubscriptionStatus'
    let requestParameter = {
        ID : LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi(`Subscription/${endPoint}`, requestParameter).then((responseData) => {
        let responseModel = new SubscriptionModel().initWithObj(responseData.json)
        resolve(responseData.json)

    }).catch((error) => {
        console.log("In check  error Error:", error)
        reject(error)
    })
})
//---------------Function to Verify Reciept For IOS----------------------//

 const sendIOSReceipt = async (receipt) => new Promise((resolve, reject) => {

    let requestParameter = {
        UserId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
        MonthlySubscriptionAmount:'4.99',
        Receipt:receipt
    }

    apiManager.onPostApi(`${controllerName}/VerifyIOSPayment`, requestParameter).then((responseData) => {
        resolve(responseData.json)

    }).catch((error) => {
        console.log("In app error Error:", error)
        reject(error)
    })
})
const sendAndroidReceipt = async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi(`${controllerName}/VerifyAndroidPayment`, requestParameter).then((responseData) => {
        resolve(responseData.json)

    }).catch((error) => {
        console.log("In app error Error:", error)
        reject(error)
    })
})
export { checkIfUserSubscribed,sendAndroidReceipt,sendIOSReceipt}