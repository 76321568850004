import { StyleSheet } from "react-native";
import { COLORS } from "../../../../constants";
import { LATO } from "../../../../constants/fonts"
export const styles = StyleSheet.create({
    mainView:{ 
        flex: 1, 
        backgroundColor: COLORS.white
    },
    upperView:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginVertical:(15).scaleHeight(),
        marginHorizontal:(15).scaleWidth(),
        alignItems:'center',
      
    },
    container: {
        flex: 1
    },
    innerView:{ 
        flexDirection: 'row', 
        alignItems: 'center' ,
        flex:1,
        justifyContent:'space-between',
        marginHorizontal:(15).scaleWidth(),


    },
    subInnerView:{ 
        flexDirection: 'row', 
        alignItems: 'center' ,
        width:'40%',
        // backgroundColor:'red'
    },
        
    titleStyle: {
        marginVertical:(15).scaleHeight(),
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: {
        color: 'rgba(6, 0, 44, 0.8)',
        fontSize: (18).scaleWidth(),
        fontFamily: LATO.Bold
    },
    filterContainer: {
        flexDirection: 'row',
        alignItems:'center',
        width:'40%',
    },
    filterText: {
        fontSize: (13).scaleWidth(),
        color: COLORS.appColor,
        fontFamily: LATO.Bold,
        flex:1,
    },
    subTitleContainer: {
        backgroundColor: COLORS.white,
        flexDirection: 'row',
        paddingVertical: 16,
        paddingHorizontal: 15,
        alignItems: 'flex-start',
        marginTop: 10
    },
    subTitleText: {
        color: 'rgba(6, 0, 44, 0.8)',
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Semibold,
        flex: 0.3,
        paddingHorizontal: 10,

    },
    subTitleText1: {
        color: 'rgba(6, 0, 44, 0.8)',
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Semibold,
        flex: 0.7,
        paddingHorizontal: 10,

    },
    bottomSheetText: {
        fontSize: (15).scaleWidth(),
        color: COLORS.appColor,
        fontFamily: LATO.Medium
    },
    paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: (10).scaleHeight(),
    },
    paginationDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: '#ccc',
        marginHorizontal: (5).scaleWidth(),
    },
    activeDot: {
        backgroundColor: COLORS.black,
    },
    shimmerView:{ 
        flex: 1, 
        paddingHorizontal: (16).scaleWidth(), 
        backgroundColor: COLORS.white, 
        paddingTop: (30).scaleHeight() 
    },
    avatarStyles:{ 
        width: '100%', 
        height:(50).scaleHeight(), 
        borderRadius: 10 
    },
})