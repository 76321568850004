import { ScrollView, StatusBar, View, KeyboardAvoidingView, Platform } from "react-native";
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import AppBottomSheetModel from '../../../../models/appBottomSheetModel'
import { AUTHHEADER, PROFILECOMPONENT } from '../../../components';
import AppBottomSheet from '../../../components/appBottomSheet';
import { AntDesign, Entypo } from '@expo/vector-icons';
import { APP_STYLES } from '../../../../utils';
import { COLORS } from '../../../../constants';
import { useState } from "react";
import * as React from 'react';



function Profile({ navigation }) {
    const [isVisible, setIsVisible] = useState(false)
    
//-------------------------Bottom Sheet Items---------------------------//
    const optionData = [
        // new AppBottomSheetModel('Logout', <AntDesign name="logout" size={22} color={COLORS.appColor} />),
        new AppBottomSheetModel('Delete My Account', <AntDesign name="delete" size={22} color={COLORS.appColor} />),
    ]

//------------------------Component Rendering---------------------------//

    return (
        <SafeAreaView style={APP_STYLES.bodyContainer} edges={['top', 'left', 'right']}>
            <StatusBar barStyle={"light-content"} />
            <AUTHHEADER mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title={"Profile"}
                icon={<Entypo name="dots-three-vertical" size={24} color={COLORS.white} onPress={() => setIsVisible(true)} />} />
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : 'padding'} style={{ flex: 1 }}>
                <ScrollView  showsVerticalScrollIndicator={false} contentContainerStyle={APP_STYLES.contentContainerStyle} style={APP_STYLES.mainContainer}>
                    <PROFILECOMPONENT navigation={navigation} />
                </ScrollView>
            </KeyboardAvoidingView>
            {isVisible && <AppBottomSheet optionList={optionData} onClose={() => setIsVisible(false)} />}
            <View style={{ height: useSafeAreaInsets().bottom, backgroundColor: COLORS.white }} />
        </SafeAreaView>
    );
}
export default Profile;