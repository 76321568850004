import { createLineupApi, checkLineupNameExist, generateLineupNumber, inactiveLineupAPI, insertLineupDetailsApi } from "../../../controllers/lineupController"
import { onUpdateSpendMoney, updateStockPositionUpDown, deleteStocks } from "../../../redux/reducers/lineupReducer"
import { Text, TouchableOpacity, View, Alert, FlatList, Keyboard } from "react-native"
import { APP_STRINGS, COLORS, ROUTES, STORAGE_KEYS } from "../../../constants"
import LinearGradient from "react-native-linear-gradient"
import { SpeedDial } from '@rneui/themed'
import toastMessage from "../../../utils/toastMessage"
import { useSelector, useDispatch } from "react-redux"
import CreateLineupPopup from "../createLineupPopup"
import { LOCAL_STORAGE } from "../../../services"
import { styles } from "./index.style"
import { CONFIGLINEUPLIST } from ".."
import { useState } from "react"
import Loader from "../loader"


function ConfigLineUp({ navigation, route }) {
    const { isScreenDisable } = route.params == undefined ? '' : route.params
    console.log(isScreenDisable,'isScreenDisable');
    
    const [isShowCreateLineupPopup, setShowCreateLineupPopup] = useState(false)
    const [loader, setLoader] = useState(false)

    const selectedStockData = useSelector((state) => state.lineupReducers.selectedStockData)
    const dispatch = useDispatch()
// console.log(selectedStockData,'selectedStockData');
//---------------Method for Adding Money----------------//
    const calculateAddedMoneyCopy = (editIndex) => {

        let tempStock = [...selectedStockData]
        tempStock.splice(editIndex, 1)
        return tempStock.reduce((acc, next) => {
            let money = 0
            if (next.money) {
                money = next.money.replace('$', '')
                money = money.replace('-', '');
                money = String(money).replace(/,/g, "");
            }
            return acc + +money
        }, 0)
    }
//------------------Method  for  updating the spend money --------------------//
    function onChangeSpendMoney(text, idx) {
        let withOutComma = text.replace(/[^0-9]/g, "").replace(/,/g, "");
        let withOutDollar = withOutComma.replace('$', '')
        let total = calculateAddedMoneyCopy(idx) + +withOutDollar
        if (total > 1000000) {
            toastMessage(APP_STRINGS.spendLimit, '1')
        }
        else if (withOutDollar > 150000) {
            toastMessage(APP_STRINGS.maxSpend, '1')
        }
        else {
            let priceVal = withOutDollar
            if (withOutDollar > 0) {
                priceVal = '$' + parseFloat(withOutDollar).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            dispatch(onUpdateSpendMoney({ text: priceVal, idx: idx }))
        }

    }
//--------------Method for Updating Change in Position of Stock---------------//
    function onClickPositionIcon(pos, idx) {
        dispatch(updateStockPositionUpDown({ idx: idx, pos: pos }))
    }

    function saveSelectedStocks() {
        Keyboard.dismiss()
        if (selectedStockData.length < 8) {
            Alert.alert(APP_STRINGS.appName, APP_STRINGS.lineUpValidationMessage, [
                {
                    text: 'Ok',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },

            ]);
            return
        }

        const itemIsFilled = selectedStockData.every((item) => item.money !== undefined && item.position !== undefined && item.money !== "")
        if (!itemIsFilled) {
            toastMessage(APP_STRINGS.fieldsReq, 1)
        }
        else {
            setShowCreateLineupPopup(true)

        }
    }
//------------Method to Delete Stock--------------------//
    function deleteStock(itemData) {
        const index = selectedStockData.findIndex((currValue) => {
            return currValue.id == itemData.id
        })
        Alert.alert(
            APP_STRINGS.appName,
            APP_STRINGS.sureMsg,     //message
            [{ text: APP_STRINGS.cancel, style: 'cancel' }, { text: APP_STRINGS.deleteBtn, onPress: () => { dispatch(deleteStocks({ index: index, navigation: navigation })) }, style: 'destructive' },]
        )
    }

//-----------------------Update Line Ups Method--------------------//

    const onClickSaveLineUp = (lineupText) => {
        setShowCreateLineupPopup(false)
        setLoader(true)
        checkLineupNameExist(lineupText).then((res) => {
            console.log(lineupText,'lineupText');
            setLoader(false)
            if (res) {
                console.log(res,'REs check line up name exists');
                setLoader(true)
                generateLineupID(lineupText)
            } else {
                setLoader(false)
                updateLineupWarningMessage(lineupText)
            }
        }).catch(() => {
            setLoader(false)
        })
    }
//----------------Update line up Warning Message----------//
    const updateLineupWarningMessage = (lineupText) => {

        let message =isScreenDisable?`Lineup can't be created with same name.`: `The lineup ${lineupText} already exists for user ${LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)} do you want to overwrite?`
        if(isScreenDisable){
            Alert.alert(APP_STRINGS.appName, message, [
                {
                    text: 'Okay!',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
               
            ]);
        }
        else{
            Alert.alert(APP_STRINGS.appName, message, [
                {
                    text: 'No, cancel!',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
                {
                    text: 'Yes, Overwrite it!', onPress: () => {
                        removeThisLineup(lineupText)
                    }
                },
            ]);
        }
       
    }
//------------------Remove line up method--------------------//
    const removeThisLineup = (lineupText) => {

        let requestParameter = {
            myUserId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            myLineupName: lineupText
        }
        setLoader(true)
        inactiveLineupAPI(requestParameter).then((res) => {
            generateLineupID(lineupText)
        }).catch(() => {
            setLoader(false)
        })

    }
//--------------Generate Line Up Id----------------//
    const generateLineupID = (lineupName) => {
        generateLineupNumber().then((lineupID) => {
            createLineup(lineupName, lineupID)
        }).catch(() => {
            setLoader(false)
        })
    }
//---------------Create Line Up----------------//
    const createLineup = (lineupName, lineupID) => {

        let requestParameter = {
            strPlayer_Id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            strLineup_Id: lineupID,
            strLineup_Name: lineupName,
            strDateTime: new Date(),
            vVersion: '1',
            sStatus: 'ACTIVE'
        }
        createLineupApi(requestParameter).then((res) => {
            insertLineupDetails(lineupID, 0)
        }).catch(() => {
            setLoader(false)
        })
    }
    function isPricePositive(priceWithOutComma) {
        // Remove any commas from the price and convert to an integer
        const price = parseInt(priceWithOutComma.replace(/,/g, ''));
    
        // Check if the price is positive
        return price > 0;
    }
//---------------------Insert LineUP Details---------------//
    const insertLineupDetails = (lineupID, index) => {
        console.log(selectedStockData,'selectedStockData',index);
        if (index < selectedStockData.length) {
            let currentStock = selectedStockData[index]
            console.log( selectedStockData[index],' selectedStockData[index]');
            let price = String(currentStock.money).replace('$', '')
            let priceWithOutComma = String(price).replace(/,/g, "");
            let priceNew
            if (currentStock.position == 'Down') {
              
                if (isPricePositive(priceWithOutComma)) {
                    console.log("Price is positive."); 
                    priceNew = -1 * parseInt(priceWithOutComma)
                    // Additional logic if needed
                } else {
                    console.log("Price is negative.");
                    priceNew = parseInt(priceWithOutComma)
                }
              
            }
            if (currentStock.position == 'Up') {
                if (isPricePositive(priceWithOutComma)) {
                    console.log("Position up "); 
                    priceNew = parseInt(priceWithOutComma)
                    // Additional logic if needed
                } else {
                    console.log("Price is negative.");
                  
                    priceNew = -1 * parseInt(priceWithOutComma)
                }
            }
            // else {
            //     priceNew = parseInt(priceWithOutComma)
            // }
            let requestParameter = {
                strLineup_Id: lineupID,
                strSymbol: currentStock.symbol,
                intAmount: priceNew,
                strPosition: currentStock.position,
                strDateTime: new Date(),
                vVersion: '1',
                sStatus: 'ACTIVE'
            }
            console.log(requestParameter,'Request PAramerrrt');
            insertLineupDetailsApi(requestParameter).then((res) => {
                

            }).catch(() => {

            }).finally(() => {

                insertLineupDetails(lineupID, index + 1)
            })
        } else {
            setLoader(false)
            navigation.navigate(ROUTES.CHALLENGEREGISTER)
        }
    }
    //---------Go to Line Up Screen-----------------------//
    const addMoreStocks = () => {
        navigation.navigate(ROUTES.LINEUP, { useScreen: 'updating' ,isScreenDisable:isScreenDisable})
    }
    //-----------------Flatlist Render Item-------------------//
    const RenderItem = (itemData) => {

        return (
            <CONFIGLINEUPLIST
                data={itemData.item}
                positionUp={() => onClickPositionIcon(APP_STRINGS.positionUp, itemData.index)}
                positionDown={() => onClickPositionIcon(APP_STRINGS.positionDown, itemData.index)}
                onChangeText={(text) => onChangeSpendMoney(text, itemData.index)}
                delete={() => deleteStock(itemData.item)}
                index={itemData.index}
                length={selectedStockData.length}
                isScreenDisable={isScreenDisable}
            />
        )
    }
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <View>
                    <View style={styles.titleContainer}>
                        <Text style={styles.titleStyle}>Selected Stocks </Text>
                        <View style={styles.lengthView}>
                            <Text style={styles.lengthStyle}>{selectedStockData.length}</Text>
                        </View>
                    </View>
                </View>
                <TouchableOpacity  style={[styles.buttonContainer, ]} onPress={saveSelectedStocks}>
                    <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0, y: 0.5 }} colors={[COLORS.orange, COLORS.lightOrange]} style={styles.buttonStyle} >
                        <Text style={styles.buttonText}>{isScreenDisable?'SAVE AS':'SAVE'}</Text>
                    </LinearGradient>
                </TouchableOpacity>
             </View>
            <View style={styles.subView}>
                <FlatList
                    data={selectedStockData}
                    keyExtractor={(item, index) => index}
                    renderItem={RenderItem}
                    style={styles.flatlist}
                    contentContainerStyle={styles.contentContainerStyle}
                    keyboardShouldPersistTaps={'handled'}
                    keyboardDismissMode="none"
                    removeClippedSubviews={false}
                />
                {isShowCreateLineupPopup && <CreateLineupPopup onClickSaveLineUp={onClickSaveLineUp} onDismissPopup={() => setShowCreateLineupPopup(false)} />}
                <SpeedDial overlayColor="transparent"
                    // disabled={isScreenDisable ? true : false}
                    color={COLORS.appColor}
                    onOpen={addMoreStocks}
                    icon={{ name: "add", color: COLORS.white }}>
                </SpeedDial>
                {loader && <Loader />}
            </View>

        </View>
    )
}
export default ConfigLineUp