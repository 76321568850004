export default class WithdrawModel {
    constructor(
      amount = '',
      batchStatus = '',
      grossAmount = '',
      netAmount = '',
      paypalFee = '',
      transactionDateTime = "",
      transactionId = "",
      transactionStatus = "",
      data = '',
      message = '',
      status = ''
    ) {
      this.amount = amount;
      this.batchStatus = batchStatus;
      this.grossAmount = grossAmount;
      this.netAmount = netAmount;
      this.paypalFee = paypalFee;
      this.transactionDateTime = transactionDateTime;
      this.transactionId = transactionId;
      this.transactionStatus = transactionStatus;
      this.data = data;
      this.message = message;
      this.status = status;
    }
  
    initWithJSON(json) {
      this.amount = json["amount"] || "";
      this.batchStatus = json["batchStatus"] || "";
      this.grossAmount = json["grossAmount"] || "";
      this.netAmount = json["netAmount"] || "";
      this.paypalFee = json["paypalFee"] || "";
      this.transactionDateTime = json["transactionDateTime"] || "";
      this.transactionId = json["transactionId"] || "";
      this.transactionStatus = json["transactionStatus"] || "";
      this.data = json['data']||""
      this.message = json["message"] || "";
      this.status = json["status"] || "";
      return this;
    }
  }
  