import {  AUTHHEADER, WITHDRAW_COMPONENT } from "../../../components";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { View } from "react-native";

function WithdrawFund({ navigation }) {
    return (
        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER  mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title="Withdraw Funds" />
            <View style={APP_STYLES.mainContainer}>
                <WITHDRAW_COMPONENT navigation={navigation} />
            </View>
        </SafeAreaView>
    )
}
export default WithdrawFund;