
import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export default StyleSheet.create({
    mainView: {
        height: (35).scaleHeight(),
        justifyContent: 'space-between',
        // alignItems: 'center',
        flexDirection: 'row',
    },
    title: {
        color: COLORS.white,
        fontSize: (16).scaleWidth(),
        fontFamily: LATO.Medium,
        marginLeft: 60,
    },
    icon: {
        marginRight: 10
    }
});