import { COLORS, ROUTES, STORAGE_KEYS, IMAGES, APP_STRINGS } from "../../../constants"
import { updateFilterOpt, updateUserBalance } from "../../../redux/reducers/lineupReducer"
import { getUsersBalance } from "../../../controllers/gamesController"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Text, View, TouchableOpacity, Alert, ScrollView } from "react-native"
import { changeLoginStatus } from "../../../redux/reducers"
import { moderateScale } from "react-native-size-matters"
import { FontAwesome5,Feather } from '@expo/vector-icons'
import { useDispatch, useSelector } from "react-redux"
import { LOCAL_STORAGE } from "../../../services"
import { styles } from "./index.style"
import { Avatar } from '@rneui/themed'
import { useEffect } from "react"


//---------------------------Custom Drawer Components--------------------------//

function CustomDrawer(props) {
    const fullName = LOCAL_STORAGE.getItem(STORAGE_KEYS.kFirstName) + ' ' + LOCAL_STORAGE.getItem(STORAGE_KEYS.kLastName)
    const userBalance = useSelector((state) => state.lineupReducers.userBalance)
    const dispatch = useDispatch()

    useEffect(() => {
        getUsersBalance().then((amount) => {
            dispatch(updateUserBalance(amount))
        })
    }, [])

    const onDrawerItemClick = (stackName, screenName) => {
        props.navigation.navigate(stackName, { screen: screenName })
    }
    const logout =()=>{
        Alert.alert(APP_STRINGS.appName, APP_STRINGS.logoutMsg, [
            {
                text: APP_STRINGS.no,
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            },
            { text: APP_STRINGS.yesLogout, onPress: () => onLogoutOptionClicked() },
        ])
    }

     //---------------------- Function to Handle Logout ----------------------//

     const onLogoutOptionClicked = () => {

        let reminder = LOCAL_STORAGE.getItem(STORAGE_KEYS.kRemember)
        let userName = LOCAL_STORAGE.getItem(STORAGE_KEYS.kUserNameRemember)
        let password = LOCAL_STORAGE.getItem(STORAGE_KEYS.kPasswordRemember)
        LOCAL_STORAGE.clear()
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kRemember, reminder)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kUserNameRemember, userName)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kPasswordRemember, password)
        dispatch(changeLoginStatus(false))
        dispatch(updateFilterOpt({filterOpt:{}}))

    }
    
    return (
        <View>
            <ScrollView showsVerticalScrollIndicator={false}>
            <View style={[styles.container, { paddingTop: useSafeAreaInsets().top }]}>
                <View style={styles.subinnerContainer}>
                    <Text style={[styles.titleText, { fontSize:moderateScale(16)}]}>{fullName}</Text>
                    <Text style={[styles.subTitleText, { fontSize: moderateScale(13),paddingTop:5 }]}>Account Balance: ${userBalance}</Text>
                    <TouchableOpacity onPress={() => props.navigation.navigate(ROUTES.PROFILE)}>
                        <Text style={[styles.profileText]}>Edit Profile</Text>
                    </TouchableOpacity>
                </View>
                <FontAwesome5 style={styles.iconStyle} name="chevron-right" size={20} color='rgba(255, 255, 255, 0.6)'onPress={() => props.navigation.closeDrawer()} />
            </View>
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.WALLET, ROUTES.WALLET)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.wallet} size={25} />
                <Text style={styles.drawerItemTitle}>My Wallet</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.HOME, ROUTES.CHALLENGE_STACK)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black,resizeMode:"contain" }} source={IMAGES.challengesIcon} size={25} />                
                <Text style={styles.drawerItemTitle}>Challenges</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.HOME, ROUTES.HOME_STACK)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black,resizeMode:"contain" }} source={IMAGES.gamesIcon} size={25} />                
                <Text style={styles.drawerItemTitle}>My Games</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.HOME, ROUTES.LINEUP_STACK)}>
                <Feather name="edit" size={25} color={ COLORS.black }/>                
                <Text style={styles.drawerItemTitle}>Lineups</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.HOME, ROUTES.LEADER_BOARD_STACK)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.leaderboardIcon} size={25} />
                <Text style={styles.drawerItemTitle}>Leaderboard</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.GROUP, ROUTES.GROUP)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.grp} size={25} />
                <Text style={styles.drawerItemTitle}>Groups</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.REWARD, ROUTES.REWARD)}> 
                 <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.reward} size={25} />
                <Text style={styles.drawerItemTitle}>My Rewards</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.FREQUENTQUESTION, ROUTES.FREQUENTQUESTION)}>
            <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.ques} size={25} />
                <Text style={styles.drawerItemTitle}>FAQs</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.PRIVACYPOLICY, ROUTES.PRIVACYPOLICY)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.privacy} size={25} />
                <Text style={styles.drawerItemTitle}>Privacy Policy</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={() => onDrawerItemClick(ROUTES.TERMCONDITION, ROUTES.TERMCONDITION)}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.privacy} size={25} />
                <Text style={styles.drawerItemTitle}>Terms & Conditions</Text>
            </TouchableOpacity>
            <View style={styles.dividerStyle} />
            <TouchableOpacity style={styles.drawerItemContainer} onPress={logout}>
                <Avatar avatarStyle={{ tintColor: COLORS.black }} source={IMAGES.logout} size={25} />
                <Text style={styles.drawerItemTitle}>Logout</Text>
            </TouchableOpacity>
            </ScrollView>
        </View>
    )
}
export default CustomDrawer
