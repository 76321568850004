import { showMessage } from 'react-native-flash-message'
import { Platform } from 'react-native'
import { LATO } from '../../constants/fonts';

const toastMessage = (message, type = '0') => {
    showMessage({
        message: "DayTrader",
        description: message,
        type: type == '0' ? 'success' : 'danger',
        duration: 5000,
        icon: 'auto',
        statusBarHeight: Platform.OS === 'ios' ? 20 : 5,
        style: { paddingTop: Platform.OS == 'ios' ? (30) : (15), },
        titleStyle: { fontFamily: LATO.Bold },
        textStyle: { fontFamily: LATO.Regular }
    });
}

export default toastMessage;