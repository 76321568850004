
export default {
    Black: 'Lato-Black',
    BlackItalic: 'Lato-BlackItalic',
    Bold: 'Lato-Bold',
    BoldItalic: 'Lato-BoldItalic',
    Hairline: 'Lato-Hairline',
    HairlineItalic: 'Lato-HairlineItalic',
    Heavy: 'Lato-Heavy',
    HeavyItalic: 'Lato-HeavyItalic',
    Italic: 'Lato-Italic',
    Light: 'Lato-Light',
    LightItalic: 'Lato-LightItalic',
    Medium: 'Lato-Medium',
    MediumItalic: 'Lato-MediumItalic',
    Regular: 'Lato-Regular',
    Semibold: 'Lato-Semibold',
    SemiboldItalic: 'Lato-SemiboldItalic',
    Thin: 'Lato-Thin',
    ThinItalic: 'Lato-ThinItalic',
};