import { StyleSheet, Text, View,TouchableOpacity, Dimensions } from 'react-native'
import React, { useRef } from 'react'
import LinearGradient from 'react-native-linear-gradient'
import { COLORS } from '../../../constants'
import { moderateScale } from 'react-native-size-matters'
import { LATO } from '../../../constants/fonts'
import { SUBSCRIBE_SHEET } from '..'
import RBSheet from 'react-native-raw-bottom-sheet'

export default function SUBSCRIBE_BTN() {
    const rbSheetref = useRef()
    const onClickSubscribe = () => {
      rbSheetref.current.open()
    }
   const onClose = () => {
    rbSheetref.current.close()
   }
  return (
      <View style={{flex:1,justifyContent:'center'}}>
        <View style={{marginBottom:moderateScale(5)}}>
          <LinearGradient
            colors={[COLORS.gradientBlue,COLORS.gradientYellow]}
            start={{ x: 0.0, y: 1.0 }}
            end={{ x: 1.0, y: 1.0 }}
            style={styles.grediant}
          >
            <TouchableOpacity style={styles.buttonContainer}onPress={onClickSubscribe} >
              <Text style={styles.buttonText}>Subscribe</Text>
            </TouchableOpacity>
          </LinearGradient>
      </View> 
      <RBSheet
        height={(Dimensions.get("screen").height * 0.7)}
        ref={rbSheetref}
        closeOnDragDown={false}
        closeOnPressMask={true}
        customStyles={{
          wrapper: {
            backgroundColor: COLORS._60PerGrey,
          },
          draggableIcon: {
            backgroundColor: COLORS.appColor,
          },
          container: styles.container
        }}
      >
        <SUBSCRIBE_SHEET onClose={onClose}/>
      </RBSheet>
      </View>
    )
}

const styles = StyleSheet.create({
  grediant: {
    marginRight: (10).scaleWidth(),
    justifyContent: "center",
    alignSelf: "center",
    padding: (1.5).scaleWidth(),
    borderRadius: (20).scaleWidth(),
    maxHeight: (35).scaleWidth(),
  },

  buttonContainer: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: COLORS.appColor,
    borderRadius: (20).scaleWidth(),
  },
  buttonText: {
    textAlign: "center",
    color: COLORS.lightYellowColor,
    alignSelf: "center",
    paddingHorizontal: 16,
    fontFamily: LATO.Black,
    fontSize: moderateScale(14),
  },
  container: {
    borderTopLeftRadius: (15).scaleWidth(),
    borderTopRightRadius: (15).scaleWidth(),
    backgroundColor: COLORS.appColor,
  },
});
