import { StyleSheet } from "react-native";
import { COLORS } from "../../../../../constants";
export const styles = StyleSheet.create({
    container:{ 
        backgroundColor: 'white', 
        flex: 1, 
        paddingBottom: (80).scaleHeight() 
    },
    iconStyle:{
        top:10,
        right:16
    },
    prizeTab:{
        width:(50).scaleWidth(),
        height:(18).scaleHeight(),
        backgroundColor:COLORS.green,
        borderRadius:5,
        justifyContent:'center',
        alignItems:'center',
        right:15,
    },
    colorStyle:{
        color:COLORS.black
    },
    avatarStyles:{ 
        width: 0, 
        height: 0, 
        borderRadius: 60 
    },
    flatlist:{
        flex:1,
        marginTop:(20).scaleHeight(),
        backgroundColor:COLORS.white
    },
})