import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import {scale} from 'react-native-size-matters'
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
    paddingTop: 20,
  },
  titleStyle: {
    marginHorizontal: 16,
    marginVertical: 15,
    fontSize: (14).scaleWidth(),
    fontFamily: LATO.Medium,
    color: COLORS.darkGrayColor,
  },
  radioButton: {
    flexDirection: "row",
    marginHorizontal: 10,
  },
  bottomText: {
    textAlign: "center",
    textDecorationLine: "underline",
    color: "#17202C",
    fontFamily: LATO.Medium,
    fontSize: (13).scaleWidth(),
  },
  textStyle: {
    color: "red",
  },
  shimmerView: {
    flex: 1,
    paddingHorizontal: scale(16),
    backgroundColor: "#fff",
    paddingTop: 30,
  },
  avatarStyles: { 
    width: "100%", 
    height: scale(50), 
    borderRadius: 10 
},
});