import { Dimensions, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useRef } from 'react'
import RBSheet from 'react-native-raw-bottom-sheet'
import { COLORS } from '../../../constants'
import { Ionicons } from '@expo/vector-icons'
import { LATO } from '../../../constants/fonts'
import AppButton from '../appButton'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Feather } from '@expo/vector-icons';
import { Divider } from '@rneui/base'
import InputText from '../inputText'
import { _scale } from '../../../utils/appHelper'

export default function PasscodePopup(props) {
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.titleView}>
          <Text style={styles.title}>Join Group</Text>
          <Feather onPress={props.onDismissPopup} name='x-circle' size={24} color={COLORS.black} style={styles.icon} />
        </View>
        <View style={styles.mainContent}>
          <View style={styles.walletBalanceContainer}>
            <Text style={styles.text}>{props.selectedGame.groupName}</Text>
          </View>
          {props.selectedGame.groupType=='Private'&& <InputText
            placeholderTextColor={COLORS._60PerGrey}
            placeHolder={"Enter Passcode"}
            inputStyle={styles.inputStyle}
            changeValue={props.onChangeText}
          />}
        </View>
        <AppButton title={'JOIN GROUP'} onPress={props.onJoinGame} />
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 20,
  },
  titleView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: COLORS.lightBlueBorder,
    padding: _scale(5),
  },
  title: {
    fontFamily: LATO.Bold,
    fontSize: (15).scaleWidth(),
    color: COLORS.black,
    flex: 1,
    marginLeft: 20,
    marginTop: 10,
  },
  icon: {
    marginVertical: 5,
    marginRight: 5,
    alignSelf: 'flex-end',
  },
  mainContent: {
    marginTop: 20,
  },
  walletBalanceContainer: {
    margin: 15,
    backgroundColor: COLORS.appTextInputBackgroundColor,
    padding: (15).scaleWidth(),
    marginBottom: 5,
    borderRadius: 10,
  },
  text: {
    fontFamily: LATO.Medium,
    fontSize: (14).scaleWidth(),
    color: COLORS._60PerGrey,
  },
  inputStyle: {
    paddingLeft: 10,
  },
})
