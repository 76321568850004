import { updateCompletedGameID } from "../../../../../redux/reducers/lineupReducer"
import { getCompletedGamesAPI } from "../../../../../controllers/gamesController"
import GameDetailPopUp from "../../../../components/gameDetailPopUp"
import TableFooterView from "../../../../components/tableFooterView"
import {  FlatList, View,RefreshControl } from "react-native"
import { GAMECOMPONENT } from "../../../../components"
import {  ROUTES } from "../../../../../constants"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {styles} from './index.style'


function CompletedGame({ navigation }) {

    //---------------State variables using the useState hook---------------//
    const [completedGames, setCompletedGames] = useState([])
    const [isLoader, setLoader] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [gameTitle,setGameTitle] = useState('')
    const [gameDescription,setGameDescription] = useState()

    //----------Redux dispatch function----------------------//
    const dispatch = useDispatch()

    //----------useEffect hook to handle component initialization and fetching data----------------------//
    useEffect(() => {
        setLoader(true)
        const unsubscribe = navigation.addListener('focus', () => {
            getCompletedGames()
            return unsubscribe
        }, [])

    }, [])

    //---------------------- Function to Fetch Completed Games ----------------------//

    const getCompletedGames = () => {

        getCompletedGamesAPI().then((response) => {
            setCompletedGames(response)
        }).catch((error) => {
            console.log('error', error)
            setLoader(false)
            setRefreshing(false)

        }).finally(() => {
            setLoader(false)
            setRefreshing(false)
        })
    }

    //---------------------- Function to Handle Refreshing ----------------------//
    const onRefreshPending = () => {
        setRefreshing(true)
        getCompletedGames()
    }

    //---------------------- Function to Handle Click on Completed Cell ----------------------//

    const didClickedCompletedCell = (item) => {
        // Dispatch action to update Redux store with selected completed game details
        dispatch(updateCompletedGameID({ id: item.gameID }))
        
        // Navigate to the specified screen and pass relevant parameters
        navigation.navigate(ROUTES.LEADER_BOARD_LIST, { screenName: 'Completed' })
    }
 
    //---------------------- Function to Handle Question Mark Button Press ----------------------//
    const onPressQuestionMark = (title,description,gameDetail) => {
        if(gameDetail.length!=0){
            setShowPopup(true)
            setGameTitle(title)
            setGameDescription(description)
        }
      
    }

    //---------------------- Function to Render Each Item in the FlatList ----------------------//
    const RenderItem = (itemData) => {
       
        let parts =  itemData.item.gameDetail.length==0?'':itemData.item.gameDetail.split(':')
        let title = itemData.item.gameDetail.length==0?'':parts[0].trim()
        let description =  itemData.item.gameDetail.length==0?'':parts.slice(1).join(':').trim()
        return (
           
            <GAMECOMPONENT 
              showBanner={true}
              onPressQuestionMark={()=>onPressQuestionMark(title,description,itemData.item.gameDetail)}
              onPress={() => didClickedCompletedCell(itemData.item)} 
              data={itemData.item} 
              cellType={'Completed'} 
              onDismissPopup={() => setShowPopup(false)}
            />
        )
    }

    //---------------------- Component Rendering ----------------------//
    return (
        <View style={styles.mainView}>
            <FlatList
                showsVerticalScrollIndicator={false}
                data={completedGames}
                keyExtractor={item =>  item.gameID}
                renderItem={RenderItem}
                style={styles.flatlist}
                ListEmptyComponent={!isLoader && <TableFooterView />}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={onRefreshPending} />
                }
            />
            {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
              />
            )}

        </View>
    )
}

//-------------------Export the Completed Game component as the default export------------//

export default CompletedGame
