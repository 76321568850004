export default class TransactionHistoryModel {
    constructor(grossAmount = "", transactionDateTime = "", transactionStatus = "", transactionType = "",paypalFee="",netAmount="",gameID="") {
        this.grossAmount = grossAmount;
        this.transactionDateTime = transactionDateTime;
        this.transactionStatus = transactionStatus;
        this.transactionType = transactionType;
        this.paypalFee = paypalFee;
        this.netAmount = netAmount;
        this.gameID = gameID;
    }

    initWithTransactionHistory(transactionHistory) {
        this.grossAmount = transactionHistory["GrossAmount"] || '';
        this.transactionDateTime = transactionHistory["TransactionDateTime"] || '';
        this.transactionStatus = transactionHistory["TransactionStatus"] || '';
        this.transactionType = transactionHistory["TransactionType"] || '';
        this.paypalFee = transactionHistory["PaypalFee"] || '';
        this.netAmount = transactionHistory["NetAmount"] || '';
        this.gameID = transactionHistory["GameId"] || '';
        return this;
    }
}
