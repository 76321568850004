import 'react-native-gesture-handler';
import * as React from 'react';
import { COLORS } from '../constants';
import { Provider } from "react-redux";
import { store } from '../redux/store'
import { StatusBar } from 'react-native';
import AppNavigation from '../navigation';
import appStyles from '../utils/appStyles';
import { LOCAL_STORAGE } from '../services';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';

//--------------------Root Component------------------------//

function Root() {
      //Local Storage Sync
    React.useEffect(() => {
        LOCAL_STORAGE.sync()
    }, [])
    
  
    return (
        <SafeAreaProvider style={appStyles.mainContainer}>
            <Provider store={store}>
                <StatusBar backgroundColor={COLORS.appColor} />
                <AppNavigation />
                <FlashMessage position={'top'} />
            </Provider>
        </SafeAreaProvider >
    );
}
export default Root;