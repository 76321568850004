import { Platform, Text, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { APP_STYLES } from '../../../utils';
import { COLORS } from '../../../constants';

//--------------------Common App button Component---------------------//
function AppButton(props) {
    return (
        <>
        {Platform.OS!=='web' ?
        <TouchableOpacity disabled={props.disabled} style={[APP_STYLES.buttonStyle,{...props.buttonStyle}]} onPress={props.onPress}>
            <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0.13, y: 0.5 }} colors={props.disabled?['rgba(6, 0, 44, 0.2)','rgba(6, 0, 44, 0.2)']:['#FF4E00', '#EC9F05']} style={[APP_STYLES.gradientStyle,{...props.gradientStyle}]}>
                <Text style={[APP_STYLES.buttontextStyle,{color:props.disabled?COLORS.black:COLORS.white}]}>{props.title}</Text>
            </LinearGradient>
        </TouchableOpacity>
        : 
        <TouchableOpacity disabled={props.disabled} style={[{...props.buttonStyleWeb}]} onPress={props.onPress}>
            <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0.13, y: 0.5 }} colors={props.disabled?['rgba(6, 0, 44, 0.2)','rgba(6, 0, 44, 0.2)']:['#FF4E00', '#EC9F05']} style={[APP_STYLES.gradientStyleWeb]}>
                <Text style={[APP_STYLES.buttontextStyleWeb,{color:props.disabled?COLORS.black:COLORS.white}]}>{props.title}</Text>
            </LinearGradient>
        </TouchableOpacity>
    }
        </>
    )
}
export default AppButton;