import { Entypo,MaterialIcons } from "@expo/vector-icons"
import { useRef, useState } from "react"
import { View, Text, Image, TextInput, Pressable, TouchableOpacity } from "react-native"
import { COLORS, IMAGES } from "../../../constants";
import { styles } from './index.style'
import SelectDropdown from 'react-native-select-dropdown'
import { moneyModel } from "../../../models/lineupBuilderModel";
import { LATO } from "../../../constants/fonts";


let positiveAmount = ''
export default function ConfigLineUp(props) {

  if (props.data.money != undefined) {
    positiveAmount = props.data.money.replace(/-/g, '')
  } else {
    positiveAmount = props.data.money
  }
  const [focus, setFocus] = useState(false);
  const { isScreenDisable } = props
  const textInputRef = useRef(null);

  let logo = `https://storage.googleapis.com/iex/api/logos/${props.data.symbol}.png`



  return (
    <View style={styles.mainView}>
      <View  style={styles.innerView}>
        <View style={styles.titleView}>
          <Text style={styles.titleText}>{props.data.companyName} ({props.data.symbol})</Text>
          <Pressable onPress={props.delete}>
            <Image source={IMAGES.deleteIcon} />
          </Pressable>
        </View>
        <View style={styles.spendPositionView}>
          <View style={styles.innerSpendView}>
            <Text style={styles.spendText}>Spend</Text>
            
              <View style={styles.outerTextView}>
                <TextInput
                  ref={textInputRef}
                  value={focus && (props.data.money == undefined || props.data.money == '') ? "$" : (!focus && props.data.money == '$') ? "" : positiveAmount}
                  onChangeText={props.onChangeText}
                  onFocus={() => {
                    setFocus(true)
                  }}
                  onBlur={() => {
                    setFocus(false)
                  }}
                  keyboardType='decimal-pad'
                  style={styles.spendMoney}

                />

                <SelectDropdown
                  data={moneyModel}
                  onSelect={(selectedItem, index) => {
                    props.onChangeText(selectedItem)
                  }}              
                  rowTextForSelection={(item, index) => {
                    return item
                  }}
                  buttonStyle={{
                    alignItems: 'center',
                    height: (22).scaleHeight(),
                    alignSelf: 'center',
                    width: (22).scaleWidth()
                  }}
                  rowTextStyle={{
                    fontFamily: LATO.Semibold,
                    color: COLORS.darkGrayColor,

                  }} 
                  
                  renderCustomizedButtonChild={() => (
                    <View style={{
                      height: (22).scaleHeight(),
                      width: (22).scaleWidth(),
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent:'center'
                      // marginTop:10
                    }}>
                      <MaterialIcons name='arrow-drop-down' size={20} style={{ alignSelf: 'center' }} />
                    </View>
                  )}
                  dropdownStyle={{
                    position: 'absolute',
                   
                    width: (110).scaleWidth(),
                    borderRadius: 10,
                    height: (180).scaleHeight(),
                    zIndex: 1,
                    backgroundColor: 'white', 
                  }}
                />
              </View>
          
          </View>
          <View style={styles.positionView}>
            <Text style={styles.positionText}>Position</Text>
            <View style={{flexDirection:'row'}}>
              <TouchableOpacity onPress={props.positionUp} >
                {(props.data.position != undefined && props.data.position.toLowerCase() == 'up') ?
                  <Image source={IMAGES.selectedUp} style={styles.img} /> : <Image source={IMAGES.unSelectedUp} style={styles.img} />}
              </TouchableOpacity>
              <TouchableOpacity  onPress={props.positionDown} disabled={props.disabledDown}>
                {(props.data.position != undefined && props.data.position.toLowerCase() == 'down') ?
                  <Image source={IMAGES.selectedDown} style={styles.img} /> : <Image source={IMAGES.unSelectedDown} style={styles.img} />}
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.bottomView}>
          <View style={styles.iconView}>
            <Entypo name="price-ribbon" size={20} color={COLORS.topBarLabel} />
            <Text style={styles.priceText}>${props.data.price}</Text>
          </View>
          <View style={styles.imgView}>
            <Image resizeMode='contain' style={{ height: 30, width: 40, }} source={{ uri: logo }} />
          </View>
        </View>

      </View>
    </View>
  )
}

