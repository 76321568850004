import { StyleSheet } from "react-native";
import { LATO } from "../../../../constants/fonts";
import { COLORS } from "../../../../constants";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  subContainer: {
    flex: 1,
  },
  innerView: {
    flex: 1,
    flexDirection: "row",
    alignItems:'center'
  },

  card: {
    backgroundColor: COLORS.white,
    paddingHorizontal: 20,
    paddingVertical: (10).scaleHeight(),
    borderRadius: 10,

    borderBottomWidth: 1,
    borderColor: "rgba(229,229,229,1)",
  },
  imageView: {
    padding: 5,
    height: (40).scaleWidth(),
    width: (40).scaleWidth(),
    backgroundColor: COLORS.lightBlueBorder,
    borderRadius: 65,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  shimmerView: {
    flex: 1,
    paddingHorizontal: scale(16),
    backgroundColor: "#fff",
    paddingTop: 30,
  },
  itemType: {
    fontFamily: LATO.Medium,
    color: COLORS.appColor,
    fontSize: scale(14),
  },
  success: {
    fontFamily: LATO.Medium,
    fontSize: scale(11),
  },
  time: {
    fontFamily: LATO.Regular,
    color: COLORS.gray,
    fontSize: scale(11),
    marginTop: 3,
   
  },
  sectionView: {
    marginLeft: 20,
    backgroundColor: "#fff",
    paddingVertical: 10,
  },
  title: {
    fontFamily: LATO.Bold,
    color: "#a1a1a1",
    fontSize: scale(14),
  },
  avatar: {
    width: "100%",
    height: scale(50),
    borderRadius: 10,
  },
  amt: {
    fontFamily: LATO.Bold,
    fontSize: scale(14),
    textAlign: "right",
  },
});
