import { amountColor, amountSign, backgroundColor, icon } from '../../../../utils/appHelper'
import { getTransactionHistory } from '../../../../controllers/paymentController'
import { updateUserBalance } from '../../../../redux/reducers/lineupReducer'
import { FlatList, View, Text, TouchableOpacity,Image } from 'react-native'
import { getUsersBalance } from '../../../../controllers/gamesController'
import { FacebookLoader } from 'react-native-easy-content-loader'
import { COLORS, ROUTES } from '../../../../constants'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {Entypo} from '@expo/vector-icons'
import { styles } from "./index.style"
import moment from 'moment'


function Wallet({ navigation }) {

    const [totalBalance, setTotalBalance] = useState('')
    const [transactionHistoryList, setTransactionHistoryList] = useState([])
    const [isShimmer, setShimmer] = useState(false)
    const dispatch = useDispatch()
   

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            getUserBalance()
            callTransactionHistory()
            return unsubscribe
        }, [])

    }, [])

    const callTransactionHistory = () => {
        setShimmer(true)
        getTransactionHistory().then((response) => {
            let temp = response.splice(0,5)          
            setTransactionHistoryList(temp)
            setShimmer(false)
        }).catch((error) => {
            setShimmer(false)
        })
    }
 

//------------------------Function to get user's balance---------------------//
    const getUserBalance = () => {
        setShimmer(true)
        getUsersBalance().then((balance) => {
            console.log(balance,'Balancee');
            dispatch(updateUserBalance(balance))
            setTotalBalance(balance)
        }).catch((error) => {

        }).finally(() => {
            setShimmer(false)
        })
    }

    const didAddFundButtonTapped = () => {
        navigation.navigate(ROUTES.ADD_FUND)
    }
    const didWithdrawFundButtonTapped = () => {
        navigation.navigate(ROUTES.WITHDRAW_FUND)
    }
    const check = () => {
        navigation.navigate(ROUTES.TRANSACTION_HISTORY)
    }
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {Array(5).fill(0).map((i) => {
                    return <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                })}
            </View>
        )
    }
    const renderItem = ({ item }) => {
        let utcMoment = moment.utc(item.transactionDateTime).local()
        let formattedDateStr = utcMoment.format('MM-DD-YYYY ,hh:mm A')
        let type = (item.transactionType).replace(/ /g, "").toLowerCase()

        return (
            <View style={styles.card}>
                <View style={styles.innerView}>
                    <View style={styles.subContainer}>
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                        <View style={{padding:5,height:(40).scaleWidth(),width:(40).scaleWidth(),backgroundColor:COLORS.lightBlueBorder,borderRadius:65,alignItems:'center',justifyContent:'center',marginRight:10}}>
                           <Image source={icon(item.transactionType)}/>
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.itemType}>{type=='addfunds'?'Deposit':item.transactionType}</Text>
                            <Text style={styles.time}>{formattedDateStr}</Text>
                            {(type=='gameentry'||type=='unregistered'||type==''||type=='gamecancelrefund'||type=='win')&&<Text style={styles.time}>Game ID : {item.gameID}</Text>}
                        </View>
                        </View>
                    </View>
                    <View>
                        <Text style={[styles.amt,{ color:COLORS.gray}]}>{amountSign(item.transactionType) + '$'+parseFloat(item.netAmount).toFixed(2)}</Text>
                        <View style={{backgroundColor:backgroundColor(item.transactionStatus),padding:5,borderRadius:5,marginTop:4}}>
                            <Text style={[{color:amountColor(item.transactionStatus)},styles.success]}>{item.transactionStatus}</Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    

    return (
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <View style={styles.balanceContainer}>
            <Text style={styles.titleText}>Your Balance</Text>
            <Text style={styles.priceText}>${totalBalance}</Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity  onPress={didAddFundButtonTapped} style={styles.addButton}>
                <Text style={styles.addButtonText}>ADD FUNDS</Text>
              </TouchableOpacity>
              <TouchableOpacity  onPress={didWithdrawFundButtonTapped} style={[styles.addButton,{ backgroundColor: COLORS.white, borderColor: COLORS.black }, ]}>
                <Text style={[styles.addButtonText, { color: COLORS.black }]}>WITHDRAW</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <TouchableOpacity style={styles.transactionView} onPress={check}>
          <Text style={styles.textStyle}>{"My Transactions"}</Text>
          <View style={styles.iconView}>
            <Text style={styles.subTextStyle}>See All</Text>
            <Entypo name="chevron-small-right" size={24} color = "#9B9B9B" />
          </View>
        </TouchableOpacity>
        {isShimmer ? renderShimmer() :
            <FlatList
            data={transactionHistoryList}
            renderItem={renderItem}
            keyExtractor={(item,index)=>index}
            style={{ flex: 1 }}
            />
        }
      </View>
    )
}
export default Wallet