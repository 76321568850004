import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale } from "react-native-size-matters";
export const styles = StyleSheet.create({
    
    container: {
        flex: 1,
        backgroundColor:COLORS.lighterBlue,
        paddingTop:(10).scaleHeight(),
       
    },
    view:{
        flex:1   
    },
    toggleBtn:{
        alignItems:'center',
        justifyContent:'center',
        padding:(10).scaleWidth(),
        marginHorizontal:(15).scaleWidth(), 
        marginBottom:(10).scaleWidth(), 
        borderRadius:(10).scaleWidth(), 
        backgroundColor:COLORS.skyBlue
    },
    toggleText:{
        fontFamily:LATO.Black,  
        color:COLORS.white 
    },
    innerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal:(10).scaleWidth(),
        // backgroundColor:COLORS.white
    },
    searchBarContainer: {
       borderRadius: 8,
       height: (40).scaleHeight(),
       justifyContent: 'center',
       alignItems: 'center',
       padding:0,
       elevation:4,
       shadowColor:COLORS.black,
       shadowOffset: { width: 0, height: 3 },
       shadowRadius: 8,
       shadowOpacity: 0.1,
    //    marginTop:(5).scaleHeight(),
    },
    searchBarInput: {
        backgroundColor: COLORS.white,
        borderRadius:8,
        height: (40).scaleHeight(),     

    },
    inputStyle: {
        fontSize: (10).scaleWidth(),
        fontFamily: LATO.Medium,
        color: COLORS.appGrayColor,
        marginLeft:0,
      

    },
    buttonContainer: {
        flex: 1,
        marginLeft: (10).scaleWidth(),
       
    },
    buttonStyle: {
        backgroundColor: COLORS.topBarLabel,
        height: (40).scaleHeight(),
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        padding:6,
     
    },
    buttonText: {
        color: COLORS.white,
        fontFamily: LATO.Bold,
        fontSize: (14).scaleWidth()
    },

   
    flatListStyle: {
        // height: (20).scaleHeight(),
        marginBottom:(10).scaleHeight(),
        backgroundColor:COLORS.white,
        borderBottomRightRadius: (20).scaleWidth(),
        borderBottomLeftRadius: (20).scaleWidth(),
        shadowColor: COLORS.black,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
    },
    flatlistContainer: {
        paddingBottom: (100).scaleHeight()
    },
    listHeader: {
        marginTop: (15).scaleHeight(),
        backgroundColor: COLORS.appColor,
        flexDirection: 'row',
        paddingVertical: (15).scaleHeight(),
        paddingHorizontal: (8).scaleWidth(),
        borderTopLeftRadius: (10).scaleWidth(),
        borderTopRightRadius: (10).scaleWidth(),
        shadowColor: COLORS.white,
        shadowOpacity: 0.25,
        shadowOffset: { width: 2, height: 0 }
    },
    headerContainer:{
        paddingHorizontal:(15).scaleWidth(),
        flex:1,
       
    },
    headerText: {
        fontSize: (14).scaleWidth(),
        fontFamily: LATO.Bold,
        color: COLORS.white,
    },
    avatarStyles:{ 
        width: '100%', 
        height:(50).scaleHeight(), 
        borderRadius: 10 
    },
    
})