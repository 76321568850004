import { ADD_FUND_COMPONENTS, AUTHHEADER } from "../../../components";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { View } from "react-native";

function AddFund({ navigation }) {
    return (
        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title="Add Fund" />
            <View style={APP_STYLES.mainContainer}>
                <ADD_FUND_COMPONENTS navigation={navigation} />
            </View>
        </SafeAreaView>
    )
}
export default AddFund;