import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { AUTHHEADER, CONFIRMPASSWORDCOMPONENT } from "../../../components";

//-------------------------Confirm Password----------------------//

function ConfirmPassword({ navigation }) {

    return (
        <SafeAreaView style={APP_STYLES.bodyContainer} edges={['top', 'left', 'right']}>
            <AUTHHEADER isBack={true} navigation={navigation} title={'Change Password'} />
            <ScrollView  showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1 }} style={{ flex: 1 }}>
                <CONFIRMPASSWORDCOMPONENT navigation={navigation} />
            </ScrollView>
        </SafeAreaView>
    )
}
export default ConfirmPassword;