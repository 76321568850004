export default class CountDown {

    constructor(props) {
        this.setData(props);
    }

    // Get how many seconds
    static getSeconds(time) {
        let type = typeof time;
        return (type === 'number' || type === 'string' && /^\d+$/.test(time))
            ? time
            : new Date(time).getTime() / 1000;
    }

    // digit completion
    static ten(t) {
        return t < 10 ? '0' + t : t;
    }

    // set data
    setData(props) {
        this.countType = props.countType; // Supports two timing methods, countdown of && seconds between two dates
        this.timerId = null; // timer
        this.endTime = props.endTime; // timer end time
        this.startTime = props.startTime; // timer start time
        this.timeLeft = props.timeLeft; // The remaining seconds of the timer, which is different from the timing method of the above time period
        this.timePassed = 0; // Forward is the cumulative time, reverse is the remaining time
        this.onInterval = props.onInterval; // timed callback
        this.onEnd = props.onEnd; // end callback
        this.step = props.step; // Timing step, in seconds, a positive number is a positive timing, a negative number is a countdown
        this.counter = 0;

        // Data validation

        if (!this.countType) {
            throw new Error('must pass in a countType: seconds || date');
        }

        if (
            (this.timeLeft && (this.endTime || this.startTime)) ||
            (!this.timeLeft && !(this.endTime || this.startTime))
        ) {
            throw new Error('Must pass in a [timeLeft] [startTime] [endTime]');
        }

        if (!this.timeLeft && typeof this.startTime === 'undefined') {
            this.startTime = Date.now() / 1000;
        }

        if (!this.timeLeft) {
            this.timeLeft = Math.floor(CountDown.getSeconds(this.endTime) - CountDown.getSeconds(this.startTime));
        }

        //    this.refreshTime(true);
    }

    // Cycle start update time

    auto() {

        this.timerId = setTimeout(() => {
            // Countdown to 0 stop timing
            if (this.timePassed <= 0 && this.step < 0) return this.end();

            this.refreshTime(true);

        }, 1000 * Math.abs(this.step)); // time interval as an integer, Find the absolute value of the step

    }

    refreshTime(isStart) {

        this.timePassed = (this.timeLeft * 1000 + this.step * 1000 * this.counter++) / 1000;

        if (this.countType === 'date') {

            let _timePassed = this.timePassed,
                second = CountDown.ten(_timePassed % 60);
            _timePassed = parseInt(_timePassed / 60);
            let minute = CountDown.ten(_timePassed % 60);
            _timePassed = parseInt(_timePassed / 60);
            let hour = CountDown.ten(_timePassed % 24);
            _timePassed = CountDown.ten(parseInt(_timePassed / 24));
            this.onInterval(_timePassed, hour, minute, second);

        } else if (this.countType === 'seconds') {

            this.onInterval(this.timePassed);

        }

        isStart && this.auto(); // Whether to start timing

    }

    // start the timer
    start() {
        clearTimeout(this.timerId);
        this.refreshTime(true);
    }

    // End: Not clearing the count + stop counting
    end() {
        clearTimeout(this.timerId);
        this.onEnd(this.timeLeft);
    }

    reset() {
        this.counter = 0;
        clearTimeout(this.timerId);
        this.refreshTime(false);
    }

}
