import { createSlice } from "@reduxjs/toolkit"
import { fetchStockList } from "../../actions/lineupActions"
import updateStates from "./lineActions"

const initialState = {
    stockList: [],
    loading: false,
    selectedStockData: [],
    updatedLineupName: '',
    userBalance: '',
    gameID:'',
    index:0,
    completedGameID:'',
    filterOpt:{},
    myGroups:[],
    userState:'',
    userZipCode:''
}

const lineupSlice = createSlice({
    name: 'lineup',
    initialState,
    reducers: {

        addStockList: (state, action) => {
            state.selectedStockData = updateStates(state.selectedStockData, action)
        },

        onUpdateSpendMoney: (state, action) => {
            state.selectedStockData = updateStates(state.selectedStockData, action)
        },

        updateStockPositionUpDown: (state, action) => {
            state.selectedStockData = updateStates(state.selectedStockData, action)
        },

        deleteStocks: (state, action) => {
            state.selectedStockData = updateStates(state.selectedStockData, action)
        },

        deleteAllStocks: (state, action) => {
            state.selectedStockData = []
        },

        addListInStocks: (state, action) => {
            console.log(state,action);
            state.selectedStockData = [...action.payload]
        },

        editLineupName: (state, action) => {
            state.updatedLineupName = action.payload
        },

        updateUserBalance: (state, action) => {
            state.userBalance = action.payload
        },
        updateGameID : (state,action) => {
            state.gameID = action.payload.id
        },
        updateIndex : (state,action) => {
            state.index = action.payload.index
        },
        updateCompletedGameID : (state,action) => {
            state.completedGameID = action.payload.id
        },
        updateFilterOpt:(state,action) => {
            state.filterOpt = action.payload.filterOpt
        },
        updateUserState : (state,action) => {
            state.userState = action.payload
        },
        updateUserZipCode : (state,action) => {
            state.userZipCode = action.payload
        },
    },

    extraReducers: {

        [fetchStockList.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchStockList.fulfilled]: (state, action) => {
            state.loading = false;
            state.stockList = action.payload;
        },
        [fetchStockList.rejected]: (state, action) => {
            state.loading = false;
        },
    }

})

export const {
    addStockList,
    onUpdateSpendMoney,
    updateStockPositionUpDown,
    deleteStocks,
    deleteAllStocks,
    addListInStocks,
    editLineupName,
    updateUserBalance,
    updateGameID,
    updateIndex,
    updateCompletedGameID,
    updateFilterOpt,
    updateUserZipCode,
    updateUserState
} = lineupSlice.actions;

export default lineupSlice.reducer



