import { AUTHHEADER, LINEUPCOMPONENT, LINEUPHEADER } from "../../../components";
import { SafeAreaView } from "react-native-safe-area-context";
import { useEffect, useState } from "react";
import { APP_STRINGS, COLORS, STORAGE_KEYS } from "../../../../constants";
import VideoComponent from "../../../components/videoComponent";
import { View, StyleSheet } from "react-native";
import { LOCAL_STORAGE } from "../../../../services";
import { APP_STYLES } from "../../../../utils";

//-----------------------------Lineup Component--------------------//

function LineUp({ navigation, route }) {

    const { useScreen } = route.params || route;
    
    const edges = useScreen != undefined ? ['left', 'right', 'top'] : ['left', 'right'];

    return (
        <SafeAreaView edges={edges} style={APP_STYLES.bodyContainer}>
            {useScreen != undefined && <AUTHHEADER isBack={true} navigation={navigation} title={APP_STRINGS.chooseStocks}  />}
            <View style={APP_STYLES.mainContainer}>
              <View style={APP_STYLES.mainContainer}>
                <LINEUPHEADER />
                <LINEUPCOMPONENT navigation={navigation} route={route} />
              </View> 
            </View>
        </SafeAreaView>
    )
}

export default LineUp;
