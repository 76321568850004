import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export const styles = StyleSheet.create({
    container:{
        flexDirection:'row',
        paddingVertical:(12).scaleHeight(),
        paddingHorizontal:(7).scaleWidth(),
        alignItems:'center',
        borderBottomWidth:1,
        borderBottomColor:COLORS.lighterGrey,
    },
    textStyle:{
        fontFamily:LATO.Medium,
        fontSize:(13).scaleWidth(),
        color:COLORS.appColor,
    },
    priceStyle:{
        fontFamily:LATO.Semibold,
        fontSize:(13).scaleWidth(),
        color:COLORS.price
    }
})