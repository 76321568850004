import { Text, View, FlatList, Dimensions, Alert } from "react-native";
import { APP_STRINGS, COLORS, STORAGE_KEYS } from '../../../constants';
import { deleteUser } from '../../../controllers/signupController';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { changeLoginStatus } from '../../../redux/reducers';
import RBSheet from "react-native-raw-bottom-sheet";
import { LOCAL_STORAGE } from '../../../services';
import { LATO } from '../../../constants/fonts';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { ListItem } from '@rneui/themed';
import PropTypes from 'prop-types';
import Loader from '../loader';
import * as React from 'react';
import { updateFilterOpt } from "../../../redux/reducers/lineupReducer";

//-------------------Bottom Sheet PopUp--------------------//


function AppBottomSheet(props) {

    const dispatch = useDispatch()

    let option = props.optionList
    const [popupHeight, setPopupHeight] = useState()
    const [isLoader, setLoader] = useState(false)

    const refRBSheet = React.useRef();
      // Calculate safe area bottom height

    let safeAreaBottomHeight = useSafeAreaInsets().bottom

    useEffect(() => {
        calculatePopupHeight()
        refRBSheet.current.open()
    })

    const calculatePopupHeight = () => {

        let maximumHeight = Dimensions.get('screen').height * 0.80
        let totalData = option.length
        let oneCellHeight = (45).scaleWidth()
        let topHeight = (55).scaleWidth()
        let totalHeight = (oneCellHeight * totalData) + safeAreaBottomHeight + topHeight

        setPopupHeight(totalHeight > maximumHeight ? maximumHeight : totalHeight)
    }
  //---------------------- Function to Handle Option Click ----------------------//

    const onClickOption = (item, index) => {
      
        if (item.description == APP_STRINGS.deleteAcc) {

            Alert.alert(APP_STRINGS.appName, APP_STRINGS.deleteAccMsg, [
                {
                    text: APP_STRINGS.no,
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
                { text:APP_STRINGS.yesDelete, onPress: () => onDeleteThisAccount() },
            ]);
        }

        else {

            if (props.onChooseOption != undefined) {
                props.onChooseOption(item, index)
            }

        }
    }
  //---------------------- Function to Delete Account ----------------------//

    const onDeleteThisAccount = () => {

        setLoader(true)
        deleteUser().then((res) => {
            setLoader(false)
            onLogoutOptionClicked()
        }).catch(() => {
            setLoader(false)
        })
    }
  //---------------------- Function to Handle Logout ----------------------//

    const onLogoutOptionClicked = () => {

        let reminder = LOCAL_STORAGE.getItem(STORAGE_KEYS.kRemember)
        let userName = LOCAL_STORAGE.getItem(STORAGE_KEYS.kUserNameRemember)
        let password = LOCAL_STORAGE.getItem(STORAGE_KEYS.kPasswordRemember)
        LOCAL_STORAGE.clear()
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kRemember, reminder)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kUserNameRemember, userName)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kPasswordRemember, password)
        dispatch(changeLoginStatus(false))
        dispatch(updateFilterOpt({filterOpt:{}}))

    }
  //---------------------- Render Component ----------------------//

    const keyExtractor = (_, index) => index.toString()

    const renderItem = ({ item, index }) => (
        <ListItem onPress={() => item.action != undefined ? item.action() : onClickOption(item, index)} >
            {item.icon != undefined && item.icon}
            <ListItem.Content>
                <ListItem.Title style={{ color: COLORS.appColor, fontFamily: LATO.Medium, fontSize: (13).scaleWidth() }}>{item.description}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
        </ListItem>
    )

    return (
        <View style={{}} >
            <RBSheet
                onClose={props.onClose}
                ref={refRBSheet}
                closeOnDragDown={true}
                dragFromTopOnly={true}
                height={popupHeight}
                customStyles={{
                    wrapper: {
                        backgroundColor: "rgba(0,0,0,0.2)",
                    },
                    container: {
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 30
                    },
                    draggableIcon: {
                        backgroundColor: "#000"
                    }
                }}
            >
                <View style={{ flex: 1}}>
                    <View style={{ paddingHorizontal: (20).scaleWidth() }}>
                        <Text style={{ fontFamily: LATO.Bold, color: COLORS.darkGrayColor, fontSize: (14).scaleWidth() }}>{props.popupTitle}</Text>
                    </View>
                    <View style={{ paddingHorizontal: (8).scaleWidth(), flex: 1, marginTop: (10).scaleHeight() }}>
                        <FlatList
                            keyExtractor={keyExtractor}
                            data={option}
                            renderItem={renderItem}
                        />
                    </View>
                    <View style={{ height: useSafeAreaInsets().bottom, backgroundColor: COLORS.white }} />
                    {isLoader && <Loader />}
                </View>
            </RBSheet>
        </View>
    );
}
export default AppBottomSheet;

//-------------------------Default Prop----------------//
AppBottomSheet.defaultProps = {
    optionList: [],
    popupTitle: 'Select below option'
}

AppBottomSheet.propTypes = {
    optionList: PropTypes.array
}

