import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:COLORS.lighterBlue
    },
    titleStyle:{
        marginHorizontal:16,
        marginVertical:20,
        color:COLORS.black,
        fontSize:(16).scaleWidth(),
        fontFamily:LATO.Semibold
    },
    shimmerView:{ 
        flex: 1, 
        paddingHorizontal: scale(16), 
        backgroundColor: '#fff', 
        paddingTop: 30 
    },
    avatar:{ 
        width: '100%', 
        height: scale(50), 
        borderRadius: 10 
    },
})