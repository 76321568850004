
class LineUpData {

    constructor(
        lineupName = '',
        dateTimeStampCreated = '',
        entries = '',
        wins = '',
        places = '',
        shows = '',
        ITM = '',
        OTM = "",
        Locked = ''

    ) {
        this.lineupName = lineupName
        this.dateTimeStampCreated = dateTimeStampCreated
        this.entries = entries
        this.wins = wins
        this.places = places
        this.shows = shows
        this.ITM = ITM
        this.OTM = OTM
        this.Locked = Locked
    }

    initWithLineupRes(lineUpRes) {
        this.lineupName = lineUpRes['Lineup_Name']
        this.dateTimeStampCreated = lineUpRes['DateTimeStampCreated']
        this.entries = lineUpRes['Entries']
        this.wins = lineUpRes['Wins']
        this.places = lineUpRes['Places']
        this.shows = lineUpRes['Shows']
        this.ITM = lineUpRes['ITM']
        this.OTM = lineUpRes['OTM']
        this.Locked = lineUpRes['Locked']
        return this
    }
}
export default LineUpData; 