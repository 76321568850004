import { updateGameID, updateIndex } from "../../../../../redux/reducers/lineupReducer"
import { getActiveGamesAPI } from "../../../../../controllers/gamesController"
import GameDetailPopUp from "../../../../components/gameDetailPopUp"
import TableFooterView from "../../../../components/tableFooterView"
import { FlatList, RefreshControl, View } from "react-native"
import { GAMECOMPONENT } from "../../../../components"
import {  ROUTES } from "../../../../../constants"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { styles } from "./index.style"


function RunningGame({ navigation, route }) {

     //-------------State variables using the useState hook---------------//
     const [activeGames, setActiveGames] = useState([])
     const [isLoader, setLoader] = useState(false)
     const [refreshing, setRefreshing] = useState(false)
     const [showPopup, setShowPopup] = useState(false);
     const [gameTitle,setGameTitle] = useState('')
     const [gameDescription,setGameDescription] = useState()
     //------------- Redux dispatch function---------------------------//
     const dispatch = useDispatch()
 
     // useEffect hook to handle component initialization and fetching data
     useEffect(() => {
         // Set loader to true and add a listener to fetch running games on focus
         setLoader(true)
         const unsubscribe = navigation.addListener('focus', () => {
             getRunningGames()
             return unsubscribe
         }, [])
     }, [])
 
     //---------------------- Function to Fetch Running Games ----------------------//
     const getRunningGames = () => {
         // Call API to get active/running games
         getActiveGamesAPI().then((response) => {
            console.log(response,'response');
            
             setActiveGames(response)
         }).catch((error) => {
             console.log('error', error)
             setLoader(false)
             setRefreshing(false)
         }).finally(() => {
             setLoader(false)
             setRefreshing(false)
         })
     }
 
     //---------------------- Function to Handle Refreshing ----------------------//
     const onRefreshPending = () => {
         setRefreshing(true)
         getRunningGames()
     }
 
     //---------------------- Function to Handle Selection of Running Game ----------------------//
     const didSelectRunningGame = (item, idx) => {
         // Dispatch actions to update Redux store with selected game details
         dispatch(updateGameID({ id: item.gameID }))
         dispatch(updateIndex({ index: idx }))
         
         // Navigate to the specified screen and pass relevant parameters
         navigation.navigate(ROUTES.DRAWER, { 
             screen: ROUTES.HOME, 
             params: { 
                 screen: ROUTES.LEADER_BOARD_STACK, 
                 params: { 
                     screen: 'leaderBoardStack', 
                     params: { screenName: 'Running' } 
                 } 
             } 
         })
     }
 
    //---------------------- Function to Handle Question Mark Button Press ----------------------//
    const onPressQuestionMark = (title,description,gameDetail) => {
        if(gameDetail.length!=0){
            setShowPopup(true)
            setGameTitle(title)
            setGameDescription(description)
        }
      
    }
  
     //---------------------- Function to Render Each Item in the FlatList ----------------------//
     const RenderItem = (itemData) => {
        let parts =  itemData.item.gameDetail.length==0?'':itemData.item.gameDetail.split(':')
        let title = itemData.item.gameDetail.length==0?'':parts[0].trim()
        let description =  itemData.item.gameDetail.length==0?'':parts.slice(1).join(':').trim()
         return (
             <GAMECOMPONENT 
                 onPress={() => didSelectRunningGame(itemData.item, itemData.index)} 
                 data={itemData.item} 
                 cellType={'Pending'} 
                 onPressQuestionMark={()=>onPressQuestionMark(title,description,itemData.item.gameDetail)}

             />
         )
     }
 
     //---------------------- Component Rendering ----------------------//
     return (
         <View style={styles.mainView}>
 
             <FlatList
                 showsVerticalScrollIndicator={false}
                 data={activeGames}
                 keyExtractor={item => item.gameID}
                 style={styles.flatlist}
                 renderItem={RenderItem}
                 ListEmptyComponent={!isLoader && <TableFooterView />}
                 refreshControl={
                     <RefreshControl refreshing={refreshing} onRefresh={onRefreshPending} />
                 }
             />
              {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
              />
            )}
         </View>
     )
 }
 
 // Export the RunningGame component as the default export
export default RunningGame