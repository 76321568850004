import { STORAGE_KEYS } from '../../constants';
import UserModel from '../../models/userModel';
import { LOCAL_STORAGE } from '../../services';
import ApiManager from '../../services/apiManager';
import { TOAST_MESSAGE } from '../../utils';
let apiManager = ApiManager.getInstance();

//----------------Function to create a new user using API------------------//

export const createUserAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Register/Register', requestParameter).then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)      
        }
    }).catch((error) => {
        console.log("Register Error:", error);
        reject(error)
    })
});
//-------------------------------Function to resend registration OTP code--------------------------------//
export const resendRegisterOtp = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('/Register/ResendCode', requestParameter).then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
        }
    }).catch((error) => {
        console.log("Otp Error:", error);
        reject(error)
    })
});
//------------------------------- Function to validate a user account--------------------------------//

export const validatedAccount = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('/Register/ValidatedAccount', requestParameter).then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            let data = responseData.json['data']
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kAuthToken, data.token)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kFirstName, data.firstname)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kLastName, data.lastname)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kEmail, data.email)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.isUserLoggedIn, '1')

            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        console.log("ValidatedAccount Error:", error);
        reject(error)
    })
});
//------------------------------- Function to authenticate a user during login--------------------------------//

export const loginApi = async (requestParameter) => new Promise((resolve, reject) => {
   

    apiManager.onPostApi('Token/Authenticate', requestParameter).then((responseData) => {
        if (responseData.json.status == 0) {
            TOAST_MESSAGE(responseData.json.message, 1)
        }
        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            let data = responseData.json['data']
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kAuthToken, data.token)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kFirstName, data.firstname)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kLastName, data.lastname)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kEmail, data.email)
            LOCAL_STORAGE.setItem(STORAGE_KEYS.isUserLoggedIn, '1')

            resolve(responseData.json)
        }
        else if(responseData.json.status == 0 && responseData.json.data.action =='NAVTOOTP') {
            TOAST_MESSAGE(responseData.json.message,'1')
            resolve(responseData.json)
        }
        else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        console.log("Login Error:", error);
        reject(error)
    })
});
export const checkPassword  =  async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi('Token/Authenticate', requestParameter).then((responseData) => {
        resolve(responseData.json)
    }).catch((error) => {
        console.log("Login Error:", error);
        reject(error)
    })
});
//-------------------------------Function to request a password reset code--------------------------------//

export const requestPasswordResetCode = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Register/RequestPasswordResetCode', requestParameter).then((responseData) => {
        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)

            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        console.log("resendError:", error);
        reject(error)
    })
});
//-------------------------------Function to validate a password reset--------------------------------//

export const validatedPasswordReset = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Register/validatedPasswordReset', requestParameter).then((responseData) => {
        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        console.log("validatedPasswordReset Error:", error);
        reject(error)
    })
});
//-------------------------------Function to get profile details--------------------------------//

export const getProfileDetails = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        UserName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
    }

    apiManager.onPostApi('Register/user/UserProfileDetail', requestParameter).then((responseData) => {

        if (responseData.json.status == 1) {
            let data = responseData.json.data
            let userModel = new UserModel().initWithJson(data)
            resolve(userModel)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
});
//-------------------------------Function to update user info--------------------------------//

export const updateUserInfo = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Register/user/UserProfileUpdate', requestParameter, "PUT").then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        console.log(error,'Errorn in controller');
        reject(error)
    })
});
//-------------------------------Function to change  password--------------------------------//

export const changePassword = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Register/user/ChangePassword', requestParameter,).then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
});
//-------------------------------Function to delete user--------------------------------//

export const deleteUser = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        UserName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }

    apiManager.onPostApi('Register/user/UserDelete', requestParameter,).then((responseData) => {

        if (responseData.json.status == 1) {
            TOAST_MESSAGE(responseData.json.message)
            resolve(responseData.json)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
});
//-------------------------------Function to add funds--------------------------------//
