import { View } from "react-native";
import { styles } from './index.style.web'; // Import web styles
import { IMAGES } from "../../../constants";

//-----------------------App Logo component -------------------------//
function Logo(props) {
    return (
        <View style={[styles.container, props.container]}>
            <img
                src={IMAGES.webHeader}
                alt="Logo"
            />
        </View>
    )
}
export default Logo;
