
class LeaderBoardModel {

    constructor(
        id = '',
        email = '',
        playerID = '',
        score = '',
        userID = ''
    ) {
        this.id = id
        this.email = email
        this.playerID = playerID
        this.score = score
        this.userID = userID

    }

    initWithLeaderBoardRes(leaderBoardRes) {

        this.id = leaderBoardRes['ID'] || ''
        this.email = leaderBoardRes['Email'] || ''
        this.playerID = leaderBoardRes['Player_Id'] || ''
        this.score = leaderBoardRes['SCORE'] || ''
        this.userID = leaderBoardRes['UserName'] || ''

        return this
    }
}
export default LeaderBoardModel; 