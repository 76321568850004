import { SafeAreaView } from "react-native-safe-area-context";
import LeaderBoardList from "../../../components/leaderBoardList";
import { styles } from "./index.style";
//-----------------------Leaderboard Component---------------------//
function LeaderBoard({ navigation ,route}) {   
    return (
        <SafeAreaView style={styles.container} edges={['left', 'right','bottom']}>
            <LeaderBoardList 
               navigation = {navigation} 
               screenName = {route.params.screenName} 
            />
        </SafeAreaView>
    )
}
export default LeaderBoard;