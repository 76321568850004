export default class AddFundModel {
    constructor(
      id = '',
      links = [],
      paymentSource = {},
      status = ''
    ) {
      this.id = id;
      this.links = links;
      this.paymentSource = paymentSource;
      this.status = status;
    }
  
    initWithJSON(json) {
      this.id = json["id"] || "";
      this.links = json["links"] || [];
      this.paymentSource = json["payment_source"] || {};
      this.status = json["status"] || "";
      return this;
    }
  }
  