import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {AntDesign} from '@expo/vector-icons'
import { APP_STRINGS, COLORS, ERROR_STRING, IMAGES, ROUTES, STORAGE_KEYS, URLS } from "../../../constants";
import { Ionicons, MaterialCommunityIcons, Octicons } from '@expo/vector-icons';
import { Text, TouchableOpacity, Keyboard,Image, useWindowDimensions,StyleSheet, ScrollView, View, Platform, KeyboardAvoidingView, Linking, ImageBackground  } from "react-native";
import { loginApi } from "../../../controllers/signupController";
import { changeLoginStatus } from "../../../redux/reducers";
import React,{ useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../loader";
import AppButton from "../appButton";
import InputText from "../inputText";
import { LOCAL_STORAGE } from "../../../services";
import { APP_STYLES,TOAST_MESSAGE } from "../../../utils";
import { LATO, OVERPASS } from '../../../constants/fonts';
import { LOGO } from '..';
import { _scale } from '../../../utils/appHelper';

export default function Login(props) {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isRemember, setRemember] = useState(false);
    const [isPasswordSecure, setPasswordSecure] = useState(true);
    const [isShowLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const field1 = useRef('');
    const field2 = useRef('');
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;
    const { height, width } = useWindowDimensions();

    let isLandscape = 0
    console.log('width', width);
    if (width <= 500) {
        isLandscape = 30
    }
    else if ( width > 501  && width <= 1100) {
        isLandscape = 50
    }
     else if ( width > 501  && width <= 1100) {
        isLandscape = 50
    } else if (1100 < width && width <= 1400) {
        isLandscape = 80
    } else {
        isLandscape = 0
    }
   
    useEffect(() => {
        let remember = LOCAL_STORAGE.getItem(STORAGE_KEYS.kRemember);
        if (remember == null || remember == undefined) {
            setRemember(false);
            setUserName('');
            setPassword('');
        } else {
            setRemember(remember == 'true');
            if (remember == 'true') {
                setUserName(LOCAL_STORAGE.getItem(STORAGE_KEYS.kUserNameRemember));
                setPassword(LOCAL_STORAGE.getItem(STORAGE_KEYS.kPasswordRemember));
            } else {
                setUserName('');
                setPassword('');
            }
        }
    }, []);

    const checkInput = () => {
        if (username.trim().length < 1) {
            TOAST_MESSAGE(ERROR_STRING.userNameBlank, '1');
        } else if (password.trim().length < 1) {
            TOAST_MESSAGE(ERROR_STRING.passwordBlank, '1');
        } else {
            LOCAL_STORAGE.setItem(STORAGE_KEYS.kRemember, String(isRemember));
            if (isRemember) {
                LOCAL_STORAGE.setItem(STORAGE_KEYS.kUserNameRemember, username);
                LOCAL_STORAGE.setItem(STORAGE_KEYS.kPasswordRemember, password);
            }

            setShowLoader(true);
            let requestParameter = {
                userName: username,
                password: password
            };
            Keyboard.dismiss();

            loginApi(requestParameter).then((response) => {
                if (response.data.action == 'NAVTOOTP') {
                    props.navigation.navigate(ROUTES.OTP, { email: response.data.email, type: 'register' });
                } else {
                    dispatch(changeLoginStatus(true));
                }
            }).catch((error) => {
                console.log(error,'Erroo');
                setShowLoader(false);
            }).finally(() => {
                setShowLoader(false);
            });
           
        }
    };

    return (
        <View style={{flex:1}}>
            <KeyboardAvoidingView style={APP_STYLES.mainContainer} behavior={Platform.OS === 'ios' ? 'padding' : null}>
                <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps='handled' contentContainerStyle={{ flexGrow: 1 ,marginBottom:20}}>
                    <View style={{backgroundColor:COLORS.appColor,height:140,alignItems:'center',justifyContent:'center'}}>
                        <LOGO />
                    </View>
                   <View style={APP_STYLES.mainContainer}>    
                        <Text style={styles.authTitleStyle}>Welcome to Day Trader Challenge Reward Redemption</Text>
                        <View style={styles.authContainer}>
                            <View style={styles.innerView}>                    
                                <View style={styles.inputView}>
                                    <InputText
                                        reference={field1}
                                        textContainer={[styles.textContainer,{marginTop:40}]}
                                        inputStyle={{ fontSize: 16,color:COLORS._60PerWhite,height:55,outline: "none"}}
                                    
                                        value={username}
                                        placeHolder={'Username / Email'}
                                        keyboardType={'email-address'}
                                        autoCapitalize='none'
                                        placeholderTextColor={COLORS._60PerWhite}
                                        icons={<Ionicons name="person" size={22} color={COLORS._60PerWhite} />}
                                        changeValue={text => setUserName(text)}
                                        onSubmitEditing={() => field2.current.focus()}
                                    />
                                    <InputText
                                        reference={field2}
                                        textContainer={styles.textContainer}
                                        inputStyle={{ fontSize: 16,height:55,outline: "none" ,color:COLORS._60PerWhite,}}
                                        secureText={isPasswordSecure}
                                    
                                        value={password}
                                        placeHolder={'Password'}
                                        placeholderTextColor={COLORS._60PerWhite}
                                        autocapitalize={'none'}
                                        icons={<MaterialCommunityIcons name="lock-outline" size={22} color={COLORS._60PerWhite} />}
                                        eyeIcon={
                                            <Octicons
                                                name={isPasswordSecure ? "eye-closed" : "eye"}
                                                onPress={() => setPasswordSecure(!isPasswordSecure)}
                                                size={20} color={COLORS._60PerWhite}
                                            />}
                                        changeValue={value => setPassword(value)}
                                        onSubmitEditing={checkInput}
                                        textInputStyle={{ outline: "none" }}
                                        iconStyle={{}}
                                    />
                                    <TouchableOpacity style={{ marginEnd: 10 }} onPress={() =>{props.navigation.navigate(ROUTES.FORGOT)}}>
                                        <Text style={styles.forgotText}>Forgot Password?</Text>
                                    </TouchableOpacity>

                                    <AppButton buttonStyleWeb={{marginHorizontal:30,marginVertical:20}} title={APP_STRINGS.login} onPress={checkInput} />
                                </View>
                                <View style={{alignItems:'center'}}>
                                    <Text style={styles.marketing}>Consider downloading the full version of our app to play games and redeem rewards on your phone.</Text>
                                    
                                    <View style={styles.storeContainer}>
                                    <TouchableOpacity onPress={()=>Linking.openURL(URLS.APP_STORE_URL)} style={styles.btn} >
                                        <AntDesign style={{marginRight:10}} size={24} name={'apple1'} color={COLORS.white}/>
                                        <View>
                                            <Text style={styles.download}>Download it on   </Text>
                                            <Text style={styles.storeText}>Apple Store</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>Linking.openURL(URLS.PLAY_STORE_URL)} style={styles.btn}>    
                                        <Image source={IMAGES.playStore} style={{marginRight:10,height:30,width:30}} size={24} />
                                        <View>
                                            <Text style={styles.download}>Get it on</Text>
                                            <Text style={styles.storeText}>Play Store</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                </View>
                            </View>       
                        </View>
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
            {isShowLoader && <Loader />}
        </View>
    );
}




 const styles = StyleSheet.create({
   authContainer: {
        flex: 1,
   },
   authContainerSmall: {
        width: "100%",
        flex: 1,
   },
   textContainer: {
        borderWidth: 1,
        borderColor: "rgba(223, 225, 229, 1)",
        height: 55,
        marginVertical: 15,
        color: COLORS.white,
   },
   inputView: {
        backgroundColor: COLORS._10PerWhite,
        margin: 20,
        borderRadius: 20,
        maxHeight: 500,
        
   },
   innerView: {
     flex: 1,
     backgroundColor: COLORS.appColor,
   },
   filedContainerStyle: {
     marginTop: _scale(50),
     marginHorizontal: _scale(80),
   },
   innerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginVertical: 10,
   },
   forgotText: {
        color: COLORS.white,
        textDecorationLine: "underline",
        textAlign: "right",
        marginTop: 20,
     
   },
   bottomContainer: {
        flexDirection: "row",
        justifyContent: "center",
        marginVertical: 20,
   },
   bottomText: {
        color: COLORS.lightGrayColor,
        marginRight: 5,
   },
   bottomButton: {
        color: COLORS.primaryColor,
        textDecorationLine: "underline",
   },
   button: {
     alignItems: "center",
   },
   authTitleStyle: {
     textAlign: "center",
     fontSize: 17,

     color: COLORS.white,
     fontFamily: OVERPASS.Bold,
     marginBottom: 10,
     marginHorizontal: 20,
   },
   marketing: {
     color: COLORS.white,
     fontFamily: LATO.Medium,
     fontSize: 16,
     textAlign: "center",
     margin: 20,
     marginBottom: 0,
     maxWidth: 400,
   },
   btn: {
     padding: 10,
     borderWidth: 2,
     borderColor: COLORS.white,
     margin: 5,
     borderRadius: 10,
     flexDirection: "row",
     alignItems: "center",
     flex: 1,
   },
   storeContainer: {
     flexDirection: "row",
     marginTop: 20,
     alignItems: "center",
     justifyContent: "center",
     flex: 1,
   },
   storeText: {
     color: COLORS.white,
     fontSize: 20,
     fontFamily: LATO.Bold,
   },
   download: {
     color: "rgba(255,255,255,0.6)",
     fontFamily: LATO.Medium,
   },
 });


