import { Text, TouchableOpacity } from "react-native";
import { styles } from "./index.style";
import { Feather } from '@expo/vector-icons';

//----------------------------Line up list component------------------------//
function LineUpList(props) {
    return (
        <TouchableOpacity key={props.index} style={[styles.container, { ...props.buttonColor}]} onPress={props.onPress}>
            <Feather name={props.name} size={24} color={props.color} />
            <Text style={[styles.textStyle, { flex: 0.4, paddingLeft: 15 }]}>{props.data.symbol}</Text>
            <Text style={[styles.textStyle, { flex: 1,paddingLeft: 18 }]}>{props.data.companyName}</Text>
        </TouchableOpacity>
    )
}
export default LineUpList