import { validatePasscode } from '../../../../controllers/rewardController'
import { COLORS, STORAGE_KEYS } from '../../../../constants'
import { APP_STYLES, TOAST_MESSAGE } from '../../../../utils'
import { LOCAL_STORAGE } from '../../../../services'
import { View, StyleSheet, Dimensions } from 'react-native'
import { LOGO } from '../../../components'
import Loader from '../../../components/loader'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Html5QrcodeScanner } from 'html5-qrcode';
import './webqr.css';


export default function QrScanner({navigation,route}) {
  const [scanned, setScanned] = useState(false)
  const [selectedReward,setSelectedReward] = useState(route.params.selectedReward) 
  const {height,width}=Dimensions.get('screen')
  const [isLoading, setIsLoading] = useState(false)
  useEffect(()=>{
    const unsubscribe = navigation.addListener( "focus", () => {
      setScanned(null)
      return unsubscribe
    },[])
  },[])
  const handleBarCodeScanned = async (decodedText, decodedResult) => {
    setScanned(true)
    setIsLoading(true)
    let requestParameter = {
      "RewardId": selectedReward.rewardID,
      "PlayerId": LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
      "RedeemedPassCode": decodedText,
      "RedeemedDateTime":moment.utc(new Date())
    }        
      validatePasscode(requestParameter).then((res)=>{
        if(res.success){
          TOAST_MESSAGE(`Congratulations!, You won ${selectedReward.reward}`)  
        }
        else{
          TOAST_MESSAGE(res.message,'1')     
          navigation.pop()
        }
      }).catch((err)=>{
        console.log('errr',err)
      }).finally(()=>{
        setIsLoading(false)
      })
  }
  const [result, setResult] = useState('');
  const handleScanSuccess = (decodedText, decodedResult) => {
    alert(`QR Code scanned: ${decodedText}`);
    console.log(`Decoded result: ${decodedResult}`);
  };

  const handleScanFailure = (error) => {
    console.log(`QR Code scan error: ${error}`);
  };

  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      'reader',
      {
        fps: 10,
        qrbox: { width: width, height: height },  // Optional, if you want bounded box UI  
        videoConstraints:{facingMode:'back',},
        rememberLastUsedCamera:true
      },
      /* verbose= */ false

    );

    scanner.render(handleBarCodeScanned, handleScanFailure);

    return () => {
      scanner.clear().catch(error => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
  }, []);
  
  return (
    <View  style={APP_STYLES.bodyContainer}>
     
      <View style={styles.outerView}>
       {isLoading? <Loader/>: 
       <View  style={styles.container}>
          <View nativeID='reader' style={{height:'100%',width:'100%',maxHeight:350,maxWidth:350}}>
          </View>
        </View>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems:'center',
    justifyContent: 'center',
  },
  mainView:{
    backgroundColor:COLORS.appColor
  },
  outerView:{
    flex:1,
    backgroundColor:COLORS.white
  },
  qrReader: {
    width: '100%',
    height: '100%',
  },
})

const QRCodeScanner = ({ onScanSuccess, onScanFailure }) => {
  useEffect(() => {
    const scanner = new Html5QrcodeScanner(
      'reader',
      {
        fps: 10,
        qrbox: 250,

      },
      /* verbose= */ false
    );

    scanner.render(onScanSuccess, onScanFailure);

    return () => {
      scanner.clear().catch(error => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
  }, [onScanSuccess, onScanFailure]);

  return <div id="reader" style={{ width: '100%' }} />;
};

