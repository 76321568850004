import { View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { AUTHHEADER, PAYMENTCOMPONENT } from "../../../components";
import { APP_STRINGS } from "../../../../constants";
function Payment({ navigation }) {
    return (
        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER isBack={true} navigation={navigation} title={APP_STRINGS.payments} />
            <View style={APP_STYLES.mainContainer}>
                <PAYMENTCOMPONENT navigation={navigation} />
            </View>
        </SafeAreaView>
    )
}
export default Payment;