import LeaderBoardGameDetailsForOthers from "../leaderboardGameDetailsForOthers";
import { getLeaderBoardDetails } from "../../../controllers/gamesController";
import LeaderBoardGameDetailsCell from "../leaderBoardGameDetailsCell";
import { InstagramLoader } from "react-native-easy-content-loader";
import { LOCAL_STORAGE } from "../../../services";
import { STORAGE_KEYS } from "../../../constants";
import { FlatList, View ,Text} from "react-native";
import { useEffect, useState } from "react";
import { styles } from "./index.style";


function CompletedGameDetails(props) {
    const { navigation, route } = props
    const { gameID, playerId } = route.params

    const [lineupPosition, setLineupPosition] = useState([])
    const [isShimmer, setShimmer] = useState(false)
    const[score,setScore] = useState('')

    let itsME = LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)

//-----------------------Use effect to call game details Api------------------------//
    useEffect(() => {
        getGameDetails()
    }, [])
    
//-----------------------Method to get the game details using the gameID----------------//

    const getGameDetails = () => {
        setShimmer(true)
        console.log(gameID, playerId);
        getLeaderBoardDetails(gameID, playerId).then((gameModel) => {
            console.log(gameModel,'gameModel.score');
            console.log(gameModel.score,'jbdjbsdjbhed');
            
            let tempScore = (gameModel.score).toFixed(2)
            setScore(tempScore)
            console.log(gameModel.lineupPosition,'gameModel.lineupPosition');
            setLineupPosition(gameModel.lineupPosition)
        }).catch(() => {
            setShimmer(false)
        }).finally(() => {
            setShimmer(false)
        })
    }

//-----------------------Render Leaderboard game detail cell-------------------------//

    const renderItem = ({ item, index }) => {
        

        return <LeaderBoardGameDetailsCell data={item} gameID={gameID} playerId={playerId} />
    }
//-----------------------Render Leaderboard  detail for others -------------------------//

    const renderItemForOthers = ({ item, index }) => {
console.log(lineupPosition,'lineupPosition');
        return <LeaderBoardGameDetailsForOthers data={item} gameID={gameID} playerId={playerId} />
    }
    
//-----------------------Render Shimmer while the api is gettingh called-------------------------//

    const renderShimmer = () => {
        return (
            <>
                <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
                <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
                <InstagramLoader avatarStyles={styles.avatarStyles} active tHeight={0} sTHeight={0} />
            </>
        )
    }

    return (
        <View style={styles.mainView}>
            <View style={styles.innerView}>
             <Text style={[styles.gameID,{        flex:1}]}>Game ID : {gameID}</Text>
             <Text style={[styles.gameID]}>Score : {score}</Text>
             </View>
            {isShimmer ? renderShimmer() :
                itsME == playerId ?
                <FlatList
                    data={lineupPosition}
                    keyExtractor={(item, index) => index.toString()} // Use index as key extractor
                    renderItem={renderItem}
                    style={{marginBottom:20}}
                    showsHorizontalScrollIndicator={false}
                /> 
                :
                <FlatList 
                    data={lineupPosition}          
                    numColumns={2}
                    renderItem={renderItemForOthers}
                    showsHorizontalScrollIndicator={false}
                    style={{marginTop:20,marginHorizontal:10}}
                    keyExtractor={(item, index) => index.toString()} // Use index as key extractor
                />
            }

        </View>
    );
}

export default CompletedGameDetails;