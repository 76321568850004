import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { AUTHHEADER, COMPLETED_GAME_DETAILS_COMPONENTS } from "../../../components";
//--------------------------Completed Game Details Component--------------------------//
function CompletedGameDetails({ navigation, route }) {

    return (
        <SafeAreaView edges={['left', 'right', 'top']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title="Game Details"  />
            <COMPLETED_GAME_DETAILS_COMPONENTS navigation={navigation} route={route} />
        </SafeAreaView>
    )
}
export default CompletedGameDetails;