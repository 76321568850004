import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {AntDesign} from '@expo/vector-icons'
import { APP_STRINGS, COLORS, ERROR_STRING, IMAGES, ROUTES, URLS } from "../../../constants";
import { Ionicons } from '@expo/vector-icons';
import { Text,Image, TouchableOpacity, useWindowDimensions,StyleSheet, ScrollView, View, Platform, KeyboardAvoidingView, Linking  } from "react-native";
import {  useState } from "react";
import Loader from "../loader";
import AppButton from "../appButton";
import InputText from "../inputText";
import { TOAST_MESSAGE } from "../../../utils";
import { LOGO } from "..";
import { moderateScale, verticalScale } from 'react-native-size-matters';
import { LATO, OVERPASS } from '../../../constants/fonts';
import { useNavigation, useRoute } from '@react-navigation/native';
import { _scale } from '../../../utils/appHelper';
import { requestPasswordResetCode } from '../../../controllers/signupController';


export default function ForgotComponent() {

    const [isShowLoader, setShowLoader] = useState(false)
    const [email, setEmail] = useState('');
    const navigation = useNavigation()
    const route = useRoute()
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;
   

//-------------------Check Input Validations----------//

    const CheckInput = () => {
        if (email.trim().length < 1) {
            TOAST_MESSAGE(ERROR_STRING.emailBlank, '1')
        } else if (!APP_STRINGS.emailRegex.test(email)) {
            TOAST_MESSAGE(ERROR_STRING.InvalidEmail, '1')
        } else {
            onRequestPassword()

        }
    }
//-------------------Method to call forgot password API----------//
    const onRequestPassword = () => {

        setShowLoader(true)
        let request = {
            email: email
        }
        requestPasswordResetCode(request).then((res) => {
            navigation.navigate(ROUTES.SET_NEW_PASSWORD, { email: email })
        }).catch(() => {
            setShowLoader(false)

        }).finally(() => {
            setShowLoader(false)
        })
    }

    return (
        <View style={styles.authContainerLarge}>
          <KeyboardAvoidingView style={styles.commonViewStyles} behavior={Platform.OS === 'ios' ? 'padding' : null}>
            <ScrollView showsVerticalScrollIndicator={false} keyboardShouldPersistTaps='handled' contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.logoView}>
                        <LOGO/>
                    </View>
              <View style={styles.commonViewStyles}>   
              <View style={{alignItems:'center'}}>   
              <Text style={styles.authTitleStyle}>Forgot Password</Text>
              <Text style={[styles.authTitleStyle,{fontSize:17,maxWidth:550}]}>Please enter your email address. You will receive a link to create a new password via email.</Text>
              </View>
                  <View style={styles.authContainer}>
                   
                    <View style={styles.innerView}>
                      <View style={styles.inputView}>
                        <InputText
                            textContainer={[styles.textContainer,{marginTop:40}]}
                            inputStyle={{ fontSize: 16,color:COLORS._60PerWhite,height:55,outline: "none"}}
                            value={email}
                            placeHolder={'Email Address'}
                            keyboardType={'email-address'}
                            autoCapitalize='none'
                            placeholderTextColor={COLORS._60PerWhite}
                            icons={<Ionicons name="person" size={22} color={COLORS._60PerWhite} />}
                            changeValue={text => setEmail(text)}                  
                        />
                        <AppButton buttonStyleWeb={{marginHorizontal:20,marginVertical:20}}  title={APP_STRINGS.submit} onPress={CheckInput} /> 
                      </View>
                        <View style={{alignItems:'center'}}>
                            <Text style={styles.marketing}>Consider downloading the full version of our app to play games and redeem rewards on your phone.</Text>
                        </View>            
                        <View style={{flexDirection:'row',margin:20,alignItems:'center',justifyContent:'center'}}>
                          <TouchableOpacity onPress={()=>Linking.openURL(URLS.APP_STORE_URL)} style={styles.btn} >
                            <AntDesign style={{marginRight:10}} size={24} name={'apple1'} color={COLORS.white}/>
                              <View>
                                <Text style={styles.download}>Download it on   </Text>
                                <Text style={styles.storeText}>App Store</Text>
                              </View>
                          </TouchableOpacity>
                          <TouchableOpacity onPress={()=>Linking.openURL(URLS.PLAY_STORE_URL)} style={styles.btn}>
                            <Image source={IMAGES.playStore} style={{marginRight:10,height:30,width:30}} size={24} />
                            <View>
                              <Text style={styles.download}>Get it on   </Text>
                              <Text style={styles.storeText}>Play Store</Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                    </View>
                  </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
          {isShowLoader && <Loader />}
        </View>
    );
}

 const styles = StyleSheet.create({
  commonViewStyles:{ flex: 1 },
    authContainerLarge: {
     flex:1
   },
   authContainerSmall: {
   
     flex:1
   },
   logoView:{
    backgroundColor:COLORS.appColor,height:140,alignItems:'center',justifyContent:'center'},
   textContainer: {
    borderWidth: 1,
    borderColor: "rgba(223, 225, 229, 1)",
    height: 50,
    marginVertical: 15,
    marginHorizontal:20,
    color: COLORS.white,
   
},
   innerView:{flex:1,backgroundColor:COLORS.appColor},
   innerContainer: {
     flexDirection: "row",
     justifyContent: "space-between",
     alignItems: "center",
     marginVertical: 10,
   },
   inputView: {
    backgroundColor: COLORS._10PerWhite,
    margin: 20,
    borderRadius: 20,
    maxHeight: 500,
 
    
},
   forgotText: {
     color: COLORS.white,
     textDecorationLine: "underline",
     textAlign: "right",
     marginTop: 20,
   },
   bottomContainer: {
     flexDirection: "row",
     justifyContent: "center",
     marginVertical: 20,
   },
   bottomText: {
     color: COLORS.lightGrayColor,
     marginRight: 5,
   },
   bottomButton: {
     color: COLORS.primaryColor,
     textDecorationLine: "underline",
   },
   button: {
     alignItems: "center",
   },
   authTitleStyle: {
    textAlign: "center",
    fontSize: 17,
    
    color: COLORS.white,
    fontFamily: OVERPASS.Bold,
    marginBottom: (10),
    marginHorizontal: 20,
   },
   marketing: {
     color: COLORS.white,
     fontFamily: LATO.Medium,
     fontSize: 20,
     textAlign: "center",
     margin: 20,
     maxWidth:450
   },
   btn: {
     padding: 10,
     borderWidth: 2,
     borderColor: COLORS.white,
     margin: 10,
     borderRadius: 10,
     flexDirection: "row",
     alignItems:'center'
   },
   storeText: { 
        color: COLORS.white, 
        fontSize: 20, 
        fontWeight: "600" 
    },
   download: {
        color: "rgba(255,255,255,0.6)" 
    },
 });


