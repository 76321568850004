import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO, OVERPASS } from "../../../constants/fonts";
export const styles = StyleSheet.create({
    innerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    inputViewStyle: {
        flex: 1,
    },
    checkBoxContainer: {
        flexDirection: 'row',
        marginHorizontal: 14,
        alignItems: 'center',
        flex:1,
        flexWrap:'wrap'
    },
    checkBoxText: {
        color: COLORS.lightGrayColor,
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Medium,
        
    },
    conditionText: {
        padding: 5,
        fontSize: (12).scaleWidth(),
        color: COLORS.darkGrayColor,
        textDecorationLine: 'underline',
        fontFamily: LATO.Medium,
       
    },
    bottomContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: (35).scaleWidth(),
    },
    bottomText: {
        color: COLORS.dateColor,
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Medium
    },
    bottomButton: {
        marginLeft: 5,
        fontSize: (13).scaleWidth(),
        fontFamily: OVERPASS.Regular,
        color: COLORS.lightYellowColor
    },
    textContainer: {
        paddingRight: (10).scaleWidth(),
    }
})