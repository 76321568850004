import { Text, View, Image } from "react-native";
import { styles } from "./index.style";
import { AntDesign } from '@expo/vector-icons';
import { COLORS } from "../../../constants";
import Entypo from 'react-native-vector-icons/Entypo'

function PaymentList(props) {
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <Entypo name="credit-card" size={40} />
                <View style={{ marginLeft: 15 }}>
                    <Text style={styles.cardNameStyle}>{props.itemData.cardholderName}</Text>
                    <Text style={styles.cardNumberStyle}>{props.itemData.cardNumber}</Text>
                </View>
                <AntDesign onPress={() => props.onClickedDelete(props.itemData)} name="delete" size={20} color={COLORS.lightRedColor} style={{ position: 'absolute', right: 2 }} />
            </View>
        </View>
    )
}
export default PaymentList;
