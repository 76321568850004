import { View, ImageBackground, Modal, StyleSheet, Text, useWindowDimensions, ScrollView } from 'react-native'
import { scale } from 'react-native-size-matters'
import { Feather } from '@expo/vector-icons'
import { LATO } from '../../../constants/fonts'
import { COLORS } from '../../../constants'
import RenderHTML from 'react-native-render-html'



function GameDetailPopUp(props) {
    const { width } = useWindowDimensions()
    const hasHtmlTags = (str) => {
        return /<\/?[a-z][\s\S]*>/i.test(str)
      }
      
    return (
        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                <View style={styles.gifContainer}>
                    <View style={styles.innerView}>
                        <Feather onPress={props.onDismissPopup} name='x-circle' size={24} color={COLORS.black} style={styles.icon} />
                    </View>  
                    <ScrollView  showsVerticalScrollIndicator={false}>
                    <View style={{paddingHorizontal:(20).scaleWidth(),paddingBottom:(20).scaleHeight(),paddingTop:10}}>
                    {
                       hasHtmlTags(props.title) ?
                       <RenderHTML 
                        source={{html:props.title}} 
                        contentWidth={width}/> 
                        :
                         <Text style={[styles.gameTitle]}>{props.title}</Text>
                        }   
                       
                       {
                       hasHtmlTags(props.gameDescription) ?
                       <RenderHTML 
                        source={{html:props.gameDescription}} 
                        contentWidth={width}/> :
                        <Text style={styles.gameDesc}>{props.gameDescription}</Text>
                        }
                    </View>
                    </ScrollView>
                </View>
                </ImageBackground>
            </Modal>
        </View >
    )
}

export default GameDetailPopUp

const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',//x#cfcec6
    },

    gifContainer: {
        width: '90%',
        borderRadius: scale(8),
        backgroundColor: '#E6E6E6',
        marginVertical:(20).scaleHeight()
    },
    innerView:{ 
        width: '90%', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end',  
        marginTop:(10).scaleHeight(),
        marginLeft:(20).scaleHeight(),
    },
    gameTitle:{
        alignSelf:'center',
        color:COLORS.black,
        fontFamily:LATO.Black,
        fontSize:scale(14),
        marginHorizontal:scale(10),
    },
    gameDesc:{
        alignSelf:'center', 
        fontFamily:LATO.Black,
        fontSize:scale(16),
        color:COLORS.black,
        marginTop:scale(20)
       
    },
    icon:{
        paddingLeft:(10).scaleWidth(),
        paddingTop:(5).scaleHeight()
    },
   
})
