import { createAsyncThunk } from "@reduxjs/toolkit";
import LineUpData from "../../../models/stocksModelClass";
import ApiManager from '../../../services/apiManager';
let apiManager = ApiManager.getInstance();


export const fetchStockList = createAsyncThunk(
    "lineup/stockList",
    async () => {

        let requestParameter = {
            strGame: "SANDP500"
        }

        return apiManager.onPostApi('GetStockList', requestParameter).then((responseData) => {

            let json = responseData.json
            let stockList = []
            if (json != undefined) {
                let data = json.d
                var obj = JSON.parse(data);
                let table = obj["Table"]
                table.map((val) => {
                    stockList.push(new LineUpData(val.Symbol, val.Symbol, val.Security, val.Price))
                });
            }
            return stockList

        }).catch((error) => {
            console.log("GetStockList Error:", error);
            return error
        })
    }
);