import React, { useState, useEffect, useRef } from "react";
import { View, Text, Keyboard, TouchableOpacity, ScrollView, KeyboardAvoidingView } from "react-native";
import { addFundAPI } from "../../../controllers/paymentController";
import { LOCAL_STORAGE } from "../../../services";
import { TOAST_MESSAGE } from "../../../utils";
import { styles } from "./index.style";
import InputText from "../inputText";
import AppButton from "../appButton";
import Loader from "../loader";
import { Avatar } from "@rneui/base";
import { IMAGES, ERROR_STRING, STORAGE_KEYS, APP_STRINGS, ROUTES } from "../../../constants";
import { Foundation } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { getUsersBalance } from "../../../controllers/gamesController";
import { updateUserBalance } from "../../../redux/reducers/lineupReducer";
import { useIsFocused } from "@react-navigation/native";
import { FlatList } from "react-native";
import { LATO } from "../../../constants/fonts";

function AddFundComponents(props) {

    // ------------- Destructure navigation from props --------------------- //

    const { navigation } = props;

    // -------------- State variables for keyboard height, loader visibility, and form inputs ----------------- //

    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [isShowLoader, setShowLoader] = useState(false);
    const [amount, setAmount] = useState('');
    const [money,setMoney] = useState(['$50','$100','$250','$500','$1000'])
    const userBalance = useSelector((state) => state.lineupReducers.userBalance)
    const dispatch = useDispatch()
    const isFocused = useIsFocused()
    
    


    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            (event) => {
                setKeyboardHeight(event.endCoordinates.height)
            }
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardHeight(0)
            }
        );
        if(isFocused){
            getUsersBalance().then((amount) => {
                console.log(amount)
                dispatch(updateUserBalance(amount))
            }).catch((err)=>{
                console.log(err,'Error in fetching user balance');
            })
    
        }
      
        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);

    // ------------------------ API Calling Add Funds ------------------------ //

    const onClickSubmitBtn = () => {
        if (checkValidation()) {
            let amt = parseFloat(amount).toFixed(2)
            let requestParameter = {
                userId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                amount: amt
            }
            setShowLoader(true)
            addFundAPI(requestParameter).then((res)=>{
                console.log(res,'Links res');
                if (res['links'] && res['links'].length == 2) {
                    navigation.navigate(ROUTES.WEBVIEW,{url:res['links'][1].href})
                } else {
                    console.log("Insufficient links in the response");
                }
                setShowLoader(false);
            }).catch((err)=>{
                console.log(err,'Error');
                setShowLoader(false);
            });
            
        }
    };

    // ------------------------ Check Validations ------------------------ //

    const checkValidation = () => {
       
       if (+amount.trim() == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankAmt, '1');
            return false;
        }
        const trimmedAmount = amount.trim();

    // Check if the entered amount is empty or not a valid number
    if (!trimmedAmount || isNaN(trimmedAmount)) {
        TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
        return false;
    }
    const parsedAmount = parseFloat(trimmedAmount).toFixed(2)
    if (parsedAmount <= 0) {
        TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
        return false;
    }
    const minAmount = 1;
    if (parsedAmount < minAmount) {
        TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
        return false;
    }
    if (amount.trim() !== "" && amount.trim() !== "0") {
        // Amount is entered, you can parse it later
        let amt =  parseFloat(amount).toFixed(2)
        console.log(String(amt),'String(amt)');
        setAmount(String(amt))
       
    } 
    return true;
    }
    const renderItem = ({item,index}) => {
        return (
            <TouchableOpacity
              key={index}
              style={styles.moneyBtn}
              onPress={()=>{
                 let x= item.replace('$','')
                  setAmount(x)}}
            >
              <Text style={styles.moneyText}>{item}</Text>
            </TouchableOpacity>
          
          )
    }

    return (
        <View style={styles.mainView}>
        <KeyboardAvoidingView style={styles.mainView}>
                <ScrollView  contentContainerStyle={styles.contentContainerStyle}style={{flexGrow:1}}  keyboardShouldPersistTaps='handled' showsVerticalScrollIndicator={false}>
                    <View style={styles.innerView}>
                        <View style={styles.balanceView}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar source={IMAGES.circleWallet} />
                                <Text style={styles.dateTextStyle}>Your Balance</Text>
                            </View>
                            <Text style={styles.userBalance}>${userBalance}</Text>
                        </View>
                        <Text style={styles.enterAmt}>Enter Amount</Text>
                        <View style={styles.inputView}>
                            <InputText
                                textContainer={styles.textContainer}
                                inputStyle={styles.inputStyle}
                                iconView={styles.iconView}
                                blurOnSubmit={false}
                                returnKeyLabel='next'
                                returnKeyType='next'
                                defaultValue={amount}
                                changeValue={text => setAmount(text)}
                                keyboardType='decimal-pad'
                                autoCapitalize={true}
                                icons={<Foundation name="dollar" size={34} color={'rgba(110,116,122,1)'} />}
                            />
                        </View>
                        <View>
                            <FlatList
                                data={money}
                                horizontal
                                style={{marginRight:(15).scaleWidth()}}
                                showsHorizontalScrollIndicator={false}
                                renderItem={renderItem}
                            /> 
                      </View> 
                    <View style={styles.textView}>      
                        <Text style={styles.feeTitle}>Funds added to your account are subject to fees from PayPal</Text>
                        <Text style={styles.feeSchedule}>Fee Schedule:</Text>
                        <Text style={styles.paypal}>PayPal: 3.49% + $0.49</Text>
                        <Text style={styles.paypal}>Credit Card: 3.49% + $0.49</Text> 
                    </View>      
                    </View>
                   
                </ScrollView>

                <View style={[styles.btnView, { bottom: keyboardHeight }]}>
                    <AppButton disabled={!amount} onPress={onClickSubmitBtn} title={APP_STRINGS.addFund} />
                </View>
               
            {isShowLoader && <Loader />}
        </KeyboardAvoidingView>
        </View>
    )
}

export default AddFundComponents;

