
class UserModel {

    constructor(
        firstName = "",
        lastName = "",
        email = "",
        userName = "",
        phone = "",
        address1 = "",
        address2 = "",
        city = "",
        state = "",
        zip = ""
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.userName = userName;
        this.phone = phone;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zip = zip;
    }

    getFullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    getAddress() {
        return `${this.address1}${this.address2 ? ', ' + this.address2 : ''}, ${this.city}, ${this.state} ${this.zip}`;
    }

    initWithJson(json) {
        this.firstName = json['firstName'] || '';
        this.lastName = json['lastName'] || '';
        this.email = json['email'] || '';
        this.userName = json['userName'] || '';
        this.phone = json['phone'] || '';
        this.address1 = json['address1'] || '';
        this.address2 = json['address2'] || '';
        this.city = json['city'] || '';
        this.state = json['state'] || '';
        this.zip = json['zip'] || '';
        return this;
    }
}
export default UserModel; 