import { APP_STRINGS, COLORS, ERROR_STRING, STORAGE_KEYS } from "../../../constants"
import { changePassword } from "../../../controllers/signupController";
import { MaterialCommunityIcons, Octicons } from "@expo/vector-icons";
import { LOCAL_STORAGE } from "../../../services";
import { TOAST_MESSAGE } from "../../../utils";
import { styles } from "./index.style";
import InputText from "../inputText";
import AppButton from "../appButton";
import { View } from "react-native";
import { useState } from "react";
import Loader from "../loader";

function ConfirmPasswordComponent(props) {
    const { navigation } = props
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordSecure, setPasswordSecure] = useState(true);
    const [isNewPasswordSecure, setNewPasswordSecure] = useState(true);
    const [isConfPasswordSecure, setConfPasswordSecure] = useState(true);
    const [isLoader, setLoader] = useState(false)
    const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\w\W]{8,}$/;
    
//---------------On submit Change Password--------------//
    const onSubmitChangePassword = () => {

        if (checkValidation()) {

            let requestParameter = {
                UserName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                OldPassword: currentPassword,
                NewPassword: newPassword
            }
            setLoader(true)
            changePassword(requestParameter).then((res) => {
                navigation.pop()
            }).catch(() => {
                setLoader(false)
            }).finally(() => {
                setLoader(false)
            })

        }

    }
//--------------Check Validation----------------------//
    const checkValidation = () => {

        if (currentPassword.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.currentPassword, "1")
            return false

        } else if (!passwordPattern.test(newPassword)) {
            TOAST_MESSAGE(ERROR_STRING.passwordPattern, "1")
            return false
        }

        if (confirmPassword.trim().length == 0 || newPassword != confirmPassword) {
            TOAST_MESSAGE(ERROR_STRING.confirmPassword, "1")
            return false
        }
        return true
    }

    return (
        <View style={styles.container}>
            <InputText
                defaultValue={currentPassword}
                placeHolder={APP_STRINGS.currentPassword}
                autocapitalize={'none'}
                icons={<MaterialCommunityIcons name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                eyeIcon={
                    <Octicons name={isPasswordSecure ? "eye-closed" : "eye"}
                        onPress={() => { isPasswordSecure ? setPasswordSecure(false) : setPasswordSecure(true) }}
                        size={20} color={COLORS.lightGrayColor}
                    />}
                changeValue={value => setCurrentPassword(value)}
                secureText={isPasswordSecure}
            />
            <InputText
                secureText={isNewPasswordSecure}
                defaultValue={newPassword}
                placeHolder={APP_STRINGS.newPassword}
                autocapitalize={'none'}
                icons={<MaterialCommunityIcons name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                eyeIcon={
                    <Octicons name={isNewPasswordSecure ? "eye-closed" : "eye"}
                        onPress={() => { isNewPasswordSecure ? setNewPasswordSecure(false) : setNewPasswordSecure(true) }}
                        size={20} color={COLORS.lightGrayColor}
                    />}
                changeValue={value => setNewPassword(value)}
            />

            <InputText
                secureText={isConfPasswordSecure}
                defaultValue={confirmPassword}
                placeHolder={APP_STRINGS.confirmPass}
                autocapitalize={'none'}
                icons={<MaterialCommunityIcons name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                eyeIcon={
                    <Octicons name={isConfPasswordSecure ? "eye-closed" : "eye"}
                        onPress={() => { isConfPasswordSecure ? setConfPasswordSecure(false) : setConfPasswordSecure(true) }}
                        size={20} color={COLORS.lightGrayColor}
                    />}
                changeValue={value => setConfirmPassword(value)}
            />
            <AppButton onPress={onSubmitChangePassword} title={APP_STRINGS.update} />

            {isLoader && <Loader />}
        </View>
    )
}
export default ConfirmPasswordComponent;