import { CHALLENGE, LEADERBOARD } from "../../screens/containers/dashboardContainers"
import { APP_STRINGS, COLORS, IMAGES, ROUTES, STORAGE_KEYS } from "../../constants"
import { changeLoginStatus, changeSubscriptionStatus } from "../../redux/reducers"
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { checkIfUserSubscribed } from "../../controllers/verifyPayments"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { updateFilterOpt } from "../../redux/reducers/lineupReducer"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { getAppStatus } from "../../controllers/gamesController"
import { HomeTopBar, LineupTopBar } from "../topBarNavigation"
import { EventRegister } from "react-native-event-listeners"
import { LOCAL_STORAGE } from "../../services"
import { LATO } from "../../constants/fonts"
import { useDispatch } from "react-redux"
import { APP_STYLES } from "../../utils"
import { Avatar } from '@rneui/themed'
import { useEffect } from "react"


//------------------------Bottom Tab and Native Stack navigators-----------------------//
const BottomTab = createBottomTabNavigator()
const Stack = createNativeStackNavigator()

//-------------Stack for Home screens----------------------//

function HomeStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name={'homeStack'} component={HomeTopBar} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}
//-----------------Stack for Lineup screens------------------//
function LineupStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name={'lineupStack'} component={LineupTopBar} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}
//-------------Stack for Challenges screens----------------------//
function ChallengesStack() {
    return (
        <Stack.Navigator>
            <Stack.Screen name={'challengesStack'} component={CHALLENGE} options={{ headerShown: false }} />
        </Stack.Navigator>
    )
}
//-------------Stack for Leaderboard screens----------------------//

function LeaderBoardStack({ route }) {
    return (
        <Stack.Navigator>
            <Stack.Screen 
                name={'leaderBoardStack'} 
                component={LEADERBOARD} 
                options={{ headerShown: false }}
                initialParams={route.params !== undefined && {  screenName: route.params.screenName }}
            />
        </Stack.Navigator>
    )
}
//--------------------------Bottom Tab Navigation--------------------------------//

function BottomNavigation({ route }) {
    
const dispatch = useDispatch()
    useEffect(()=>{
        const checkAndUpdateAppStatus = async () => {
            try {
             await getAppStatus()
            } catch (err) {
              console.log("Error in update version in bottom ", err)
            }
          }
           
            checkAndUpdateAppStatus()
            setTimeout(()=>{
                checkSubStatus()
            },2000)
           
        let listener = EventRegister.addEventListener('unauthorized',onLogoutOptionClicked)
            return () => {
                EventRegister.removeEventListener(listener)
            }
    },[route])
        const checkSubStatus = async() => {
           
            checkIfUserSubscribed().then((res)=>{
                if(res.status){
                    dispatch(changeSubscriptionStatus(true))
                }
                else{
                    dispatch(changeSubscriptionStatus(false))
                }
            }).catch((err)=>{
              console.log(err,'Error in Checking Subscription');
            })
           }
    
     //---------------------- Function to Handle Logout ----------------------//

     const onLogoutOptionClicked = () => {

        let reminder = LOCAL_STORAGE.getItem(STORAGE_KEYS.kRemember)
        let userName = LOCAL_STORAGE.getItem(STORAGE_KEYS.kUserNameRemember)
        let password = LOCAL_STORAGE.getItem(STORAGE_KEYS.kPasswordRemember)
        LOCAL_STORAGE.clear()
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kRemember, reminder)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kUserNameRemember, userName)
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kPasswordRemember, password)
        dispatch(changeLoginStatus(false))
        dispatch(updateFilterOpt({filterOpt:{}}))
    }
 
    
    return (
        <BottomTab.Navigator
            screenOptions={{
                tabBarStyle:[APP_STYLES.tabBarStyle,{marginBottom: useSafeAreaInsets().bottom + 5,paddingRight:15,paddingLeft:15}],
                tabBarActiveTintColor: COLORS.white,
                tabBarLabelStyle: { fontSize: (11).scaleWidth(), fontFamily: LATO.Bold ,},
            }}>
            <BottomTab.Screen name={ROUTES.CHALLENGE_STACK} component={ChallengesStack}
                options={{
                    title: APP_STRINGS.challenges, headerShown: false,
                    tabBarIcon: ({ focused }) => (<Avatar avatarStyle={{ tintColor: focused ? COLORS.white : COLORS.appTabColor,resizeMode:'contain' }} source={IMAGES.challengesIcon} size={27} />)
                }} 
            />
            <BottomTab.Screen name={ROUTES.HOME_STACK} component={HomeStack}
                options={{
                    title:APP_STRINGS.myGames, headerShown: false,
                    tabBarIcon: ({ focused }) => (<Avatar avatarStyle={{ tintColor: focused ? COLORS.white : COLORS.appTabColor,resizeMode:'contain' }} source={IMAGES.gamesIcon} size={27} />                )
                }} />
            <BottomTab.Screen name={ROUTES.LINEUP_STACK} component={LineupStack}
                options={{
                    title: APP_STRINGS.lineup, headerShown: false,
                    tabBarIcon: ({ focused }) => (<Avatar avatarStyle={{ tintColor: focused ? COLORS.white : COLORS.appTabColor,resizeMode:'contain' }} source={IMAGES.lineup} size={27} />)
                }} />
           
            <BottomTab.Screen name={ROUTES.LEADER_BOARD_STACK} component={LeaderBoardStack}
                options={{
                    title: APP_STRINGS.leaderboard, headerShown: false,
                    tabBarIcon: ({ focused }) => (<Avatar avatarStyle={{ tintColor: focused ? COLORS.white : COLORS.appTabColor ,resizeMode:'contain' }} source={IMAGES.leaderboardIcon} size={27} />)
                }}
                initialParams={route.params !== undefined && { gameID: route.params.gameID, screenName: route.params.screenName, currentIdx: route.params.idx }} />
        </BottomTab.Navigator>
    )
}

export default BottomNavigation