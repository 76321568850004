// Importing utility functions for responsive design in React Native
import { scale, verticalScale } from "react-native-size-matters";
import { IMAGES,COLORS } from "../../constants";
import { Platform } from "react-native";

// Extending the Number prototype to provide convenient scaling methods
Number.prototype.scaleWidth = function () {
    return scale(this); // Scale the number for width using the scale function
};

Number.prototype.scaleHeight = function () {
    return verticalScale(this); // Scale the number for height using the verticalScale function
};

export const _scale = (number) => {
    return Platform.OS == 'ios' ? scale(number) : number
}

// Function to format a given phone number string into a more readable format
 function formatPhoneNumber(phoneNumberString) {
    // Remove all non-numeric characters from the phone number string
    let phone = phoneNumberString.replace(/\D/g, '');

    // Use a regular expression to match and format the phone number
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
        // Format the matched components of the phone number
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
    }

    // Return the formatted phone number
    return phone;
}

// Function to parse a formatted phone number string and return only numeric characters
 function parseFormattedNumber(phoneNumberString) {
    // Remove all non-numeric characters from the phone number string
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');

    // Return the cleaned numeric phone number
    return cleaned;
}



const amountColor = (type) => {
        if(type=='SUCCESS'||type=='COMPLETED')
            return COLORS.green
        if(type='PENDING')
            return COLORS.lightYellowColor
        
    return COLORS.redCancelColor
}
const backgroundColor = (status) => {
    if(status=='SUCCESS'||status=='COMPLETED')
        return 'rgba(34, 197, 94, 0.16)'
    if(status=='PENDING')
        return COLORS.gradientLightYellow
    
    if (status.includes('Game Entry')) {
        return 'transparent'
    }
    return 'rgba(255, 86, 48, 0.16)'
    
}

const amountSign = (item) => {
    let type = item.replace(/ /g, "").toLowerCase()

    if (type.includes('addfunds') || type.includes('unregistered')||type.includes('win')||type.includes('gamecancelrefund')) {
        return "+"
    }
    if (type.includes('withdraw')||(type.includes('gameentry'))) {
        return "-"
    }
    if (type.includes('pending')) {
        return " "
    }
   
    return ""
}
const icon = (item) => {
    let type = item.replace(/ /g, "").toLowerCase()
    if (type.includes('gameentry')){
        return IMAGES.gameEntry
    }
    if (type.includes('addfunds')){
        return IMAGES.depositSuccess
    }
    if (type.includes('unregistered')){
        return IMAGES.depositSuccess
    }
    if (type.includes('withdraw')){
        return IMAGES.withdraw
    }
    if (type.includes('win')){
        return IMAGES.trophy
    }
    if (type.includes('refund')){
        return IMAGES.depositSuccess
    }
    return IMAGES.gameEntry
}

const isValidVideoUrl = (url) => {
    // Regular expression to match common video URL patterns
    const videoUrlPattern = /^(http(s)?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/|vimeo\.com\/)([a-zA-Z0-9_-]{11}|[0-9]+)(\S*)?$/;
    return videoUrlPattern.test(url);
  };
export {
    formatPhoneNumber,
    parseFormattedNumber,
    amountColor,
    backgroundColor,
    amountSign,
    icon,
    isValidVideoUrl
};
