import { View,Text, ImageBackground, Modal, StyleSheet, ActivityIndicator, Dimensions, Platform } from 'react-native'
import { scale } from 'react-native-size-matters';
import { COLORS } from '../../../constants';
import { LATO } from '../../../constants/fonts';

export default function Loader(props) {
    const isLargeScreen = Dimensions.get('window').width >= 768;

    return (
        <View >
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                    <View style={[styles.gifContainer,Platform.OS=='web'&&{height:65,width:65}]}>
                        <ActivityIndicator size={'large'} color={COLORS.appColor} />
                      
                    </View>
                    {props.message &&
                        <Text style={{ color: 'white', fontSize: scale(14), fontFamily:LATO.Bold, backgroundColor: 'black', marginTop: 10, textAlign: 'center' }}>{props.message}</Text>
                    }
                </ImageBackground>
            </Modal>
           
        </View>
    )
}
const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: (35),
    },

    gifContainer: {
        height: scale(65),
        width: scale(65),
        borderRadius: scale(8),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
    },

});

