import { View, Text, StyleSheet, TouchableOpacity, KeyboardAvoidingView, ScrollView, Platform } from "react-native"
import { resendRegisterOtp, validatedAccount } from "../../../controllers/signupController"
import { scale, verticalScale } from "react-native-size-matters"
import { changeLoginStatus } from "../../../redux/reducers"
import { APP_STRINGS, COLORS } from "../../../constants"
import { CountDownText, CountDown } from '../CountDown'
import OTPTextInput from 'react-native-otp-textinput'
import { LATO } from "../../../constants/fonts"
import { APP_STYLES } from "../../../utils"
import { useDispatch } from "react-redux"
import { useRef, useState } from "react"
import AppButton from "../appButton"
import Loader from "../loader"


function OtpComponent(props) {

    const { navigation, route } = props
    const { email, type } = route.params
    const [showResentText, setShowResentText] = useState(true)
    const [isShowLoader, setShowLoader] = useState(false)
    const otpInput = useRef()
    const dispatch = useDispatch()

    const otpSubmit = () => {
        
    }
//--------------------Clear Otp text---------------//
    const clearText = () => {
        console.log('Clear')
        otpInput.current.clear()
    }
//--------------------Resend OTP---------------//

    const resendBtnPressed = () => {

        if (!showResentText) {
            setShowResentText(true)
            let dict = {
                email: email,
            }
            resendRegisterOtp(dict).then(() => {

            }).catch((error) => {
                console.log(error)
            }).finally(() => {
            })
        }
    }
//------------------Enter Otp for Validation  ---------------//

    const onEnteredOtp = (otp) => {

        let requestParameter = {
            Email: email,
            Code: otp
        }
        if (type == 'register') {
            setShowLoader(true)
            validatedAccount(requestParameter).then((response) => {
                dispatch(changeLoginStatus(true))

            }).catch((error) => {
                console.log(error)
                clearText()
                setShowLoader(false)

            }).finally(() => {
                setShowLoader(false)
            })
        }
    }

    return (
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : null} style={styles.container}>
            <ScrollView  showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'handled'} contentContainerStyle={styles.contentContainerStyle} style={styles.container}>

                <View style={APP_STYLES.authContainer}>
                    <View style={styles.outerView}>
                        <Text style={APP_STYLES.authTitleStyle}>Enter OTP</Text>
                        <Text style={styles.subTitleText}>Please enter otp that sent to be on given email.</Text>
                        <View style={styles.otpView}>
                            <OTPTextInput
                                ref={otpInput}
                                textInputStyle={styles.textInputStyle}
                                offTintColor={'red'}
                                inputCount={6}
                                secureTextEntry={true}
                                handleTextChange={(text) => {
                                    if (text.length >= 6) {
                                        onEnteredOtp(text)
                                    }
                                }}
                            />
                        </View>

                        <View style={styles.resendOtpView}>
                            <Text style={styles.footerTextStyle} >Didn’t work? </Text>
                            <TouchableOpacity onPress={resendBtnPressed}>
                                <Text style={[styles.footerTextStyle, { color: COLORS.appColor, }]} >Request a new code</Text>
                            </TouchableOpacity>
                            {showResentText &&
                                <CountDownText
                                    style={[styles.footerTextStyle, { color: COLORS.appColor, }]}
                                    countType='seconds'
                                    auto={true}
                                    afterEnd={() => { setShowResentText(false) }}
                                    timeLeft={40}
                                    step={-1}
                                    startText=''
                                    endText=''
                                    intervalText={(sec) => `in 00:${CountDown.ten(sec)}.`}
                                />
                            }
                        </View>
                        <AppButton title={APP_STRINGS.submit} onPress={otpSubmit} />
                    </View>
                </View>
            </ScrollView>
            {isShowLoader && <Loader />}
        </KeyboardAvoidingView >
    )
}

export default OtpComponent

const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor:COLORS.appColor
  },
  mainView: {
    flex: 1,
    paddingHorizontal: scale(20),
    paddingTop: verticalScale(20),
    alignItems: "center",
  },
  contentContainerStyle:{
    flexGrow:1
  },
  outerView:{ 
    flex: 1, 
    marginBottom: 20 
},
  otpView: {
    flex: 1, 
    padding: 20, 
    marginTop: 20 
  },
  textInputStyle: {
    width: scale(40),
    height: scale(40),
    borderRadius: scale(20),
    margin: 3,
    backgroundColor: COLORS.lightBlueColor,
    borderBottomWidth: 0,
    fontFamily: LATO.Bold,
    fontSize: scale(20),
  },

  footerTextStyle: {
    color: COLORS.darkGrayColor,
    fontFamily: LATO.Regular,
    fontSize: scale(13),
  },

  subTitleText: {
    fontFamily: LATO.Regular,
    marginHorizontal: (40).scaleWidth(),
    color: COLORS.darkGrayColor,
    fontSize: (11).scaleWidth(),
    textAlign: "center",
  },
  resendOtpView:{ 
    flexDirection: 'row', 
    justifyContent: 'center' 
}
})
