import { View, Text, FlatList, Alert,  } from "react-native"
import { styles } from "./index.style"
import { SearchBar, Button } from '@rneui/themed'
import { LINEUPLIST } from ".."
import { APP_STRINGS, COLORS, ROUTES } from "../../../constants"
import { useCallback, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchStockList } from "../../../controllers/lineupController"
import { addStockList } from "../../../redux/reducers/lineupReducer"
import { Switch } from "@rneui/base"
import { LATO } from "../../../constants/fonts"
import { FacebookLoader } from "react-native-easy-content-loader"
import { useFocusEffect } from "@react-navigation/native"

let originalData = []

function LineUpComponent(props) {
    
    const { navigation, route } = props
    const { useScreen,isScreenDisable } = route.params || route

    const [stockList, setStockList] = useState([])
    const [isEnabled, setIsEnabled] = useState(false)
    const [search, setSearch] = useState('')
    const [isShimmer, setShimmer] = useState(false)
    const selectedStockData = useSelector((state) => state.lineupReducers.selectedStockData)
    const dispatch = useDispatch()

//---------------------Fetch Stock List-----------------//
useFocusEffect(
    useCallback(() => {
        setShimmer(true)
        fetchStockList()
            .then((data) => {
                console.log(data.length, 'Data length in Build Line up component')
                originalData = [...data]
                setStockList(data)
                setShimmer(false)
                setIsEnabled(false)
                console.log(isEnabled,'isEnabled',selectedStockData)
            })
            .catch((err) => {
                setShimmer(false)
                console.log("Error in fetching stock list", err)
            })
            .finally(() => {
                setShimmer(false)
            })

        // Cleanup function
        return () => {
            // Optional cleanup logic
        }
    }, [])
)



//------------------- Function is to limit the frequency ------------------//
    const debounce = (func) => {
        let timer
        return function (...args) {
            const context = this
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 500)
        }
    }
    //-------------------Filtering data based on a given value and updating the StockList----------------------//
    const handleChange = (value) => {
        let filteredArray = originalData.filter((obj) => obj.symbol.toLowerCase().includes(value.toLowerCase()) || obj.companyName.toLowerCase().includes(value.toLowerCase()))
        setStockList(filteredArray)
    }
//-------------------------The resulting optimizedFn is a memoized version of debounce(handleChange)-----------------//
    const optimizedFn = useCallback(debounce(handleChange), [])

    const onCreateLineup = () => {

        if (selectedStockData.length >= 8) {
            setIsEnabled(false)
            navigation.navigate(ROUTES.CONFIGLINEUP,{isScreenDisable:isScreenDisable})
        } else {

            Alert.alert(APP_STRINGS.appName, APP_STRINGS.lineUpValidationMessage, [
                {
                    text: 'Ok',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },

            ])
        }
    }
 
    
    const renderShimmer = () => {
        return (
            <View style={{flex:1}}>
                {Array(10).fill(0).map((i) => {
                    return <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                })}
            </View>
        )
    }
     
//---------------------Render Line up List-----------------//

const renderItem = (itemData) => {
    const index = selectedStockData.findIndex((currValue) => {
        return currValue.id == itemData.item.id
    }) == -1
    return (
        <LINEUPLIST 
            data={itemData.item}
            onPress={() => {
                if(selectedStockData.length == 1){
                    setIsEnabled(false)
                }
                const index = selectedStockData.findIndex((currValue) => {
                    return currValue.id == itemData.item.id
                })

                if (index == -1 && selectedStockData.length == 8) {
                    Alert.alert(APP_STRINGS.appName,APP_STRINGS.limitAlert)
                    return
                }
                dispatch(addStockList({ index: index, itemData: { ...itemData.item, isSelected: false } }))
            }
            }
            index={itemData.index}
            buttonColor={{ backgroundColor: !index ? COLORS.blueColor : COLORS.white }}
            color={!index ? COLORS.appColor : COLORS.lightYellowColor}
            name={!index ? APP_STRINGS.minusCircle : APP_STRINGS.plusCircle}
        />
    )
}
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <View style={styles.view}>
                    <SearchBar
                        placeholder={APP_STRINGS.searchBarPlaceholder}
                        value={search}
                        onChangeText={(text) => {
                            setSearch(text)
                            optimizedFn(text)
                        }}
                        lightTheme={true}
                        containerStyle={styles.searchBarContainer}
                        inputContainerStyle={styles.searchBarInput}
                        inputStyle={styles.inputStyle}
                    />
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        title={APP_STRINGS.configBtn}
                        disabled={selectedStockData.length == 0}
                        titleStyle={styles.buttonText}
                        buttonStyle={styles.buttonStyle}
                        onPress={onCreateLineup}
                    />
                </View>
            </View>
            {selectedStockData.length!==0 && 
            <View style={{flexDirection:'row',marginHorizontal:20,marginTop:20,alignItems:'center'}}>
                <Text  style={{fontSize:(15).scaleWidth(),fontFamily:LATO.Bold}}>Show Selected Stocks</Text>
             <Switch
                trackColor={{false:"rgba(147,157,169,1)", true: COLORS.skyBlue}}
                thumbColor={ 'white' }
                ios_backgroundColor="rgba(147,157,169,1)"
                onValueChange={()=>isEnabled?setIsEnabled(false):setIsEnabled(true)}
                value={isEnabled}
                style={{marginHorizontal:10}}
             />
             </View>
             }
            
            <View style={[styles.headerContainer]}>
                <View style={styles.listHeader}>
                    <Text style={[styles.headerText, { flex: 0.4, paddingLeft: 35 }]}>Symbol</Text>
                    <Text style={[styles.headerText, { flex: 1, marginLeft: 22 }]}>Company Name</Text>
                </View>
               <View style={{flex:1,marginBottom:useScreen != undefined ? (20).scaleWidth():(80).scaleWidth()}}>
                {isShimmer ? renderShimmer() : 
                     <FlatList
                        data={isEnabled ? selectedStockData : stockList}
                        keyExtractor={item =>item.id}
                        renderItem={renderItem}
                        showsVerticalScrollIndicator={false}
                        style={{flex:1,}}
                        ListFooterComponent={<View style={styles.flatListStyle}></View>}
                    />
                }
                </View>
            </View>
        </View>
    )
}
export default LineUpComponent