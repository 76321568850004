import { addToRegisteredPlayers, getGameStatus, getRegFeeByGameId, getUsersBalance, insertGamePlayerLineup, insertGeneralLedger, isUserRegisteredForThisGame } from "../../../controllers/gamesController"
import { addListInStocks, deleteAllStocks, editLineupName, updateUserBalance } from "../../../redux/reducers/lineupReducer"
import { fetchCreatedLineup, inactiveLineupAPI } from "../../../controllers/lineupController"
import { APP_STRINGS, COLORS, ROUTES, STORAGE_KEYS } from "../../../constants"
import { Text, View, TouchableOpacity, FlatList, Alert } from "react-native"
import { lineupDetailsByIdAPI } from "../../../controllers/lineupController"
import { FacebookLoader } from "react-native-easy-content-loader"
import LinearGradient from "react-native-linear-gradient"
import { useNavigation } from "@react-navigation/native"
import { useDispatch, useSelector } from "react-redux"
import { LOCAL_STORAGE } from "../../../services"
import { scale } from "react-native-size-matters"
import { LATO } from "../../../constants/fonts"
import { CHALLENGEREGISTERLIST } from ".."
import { useEffect, useState } from "react"
import { SpeedDial } from "@rneui/themed"
import { styles } from "./index.style"
import Loader from "../loader"
import moment from "moment"


function ChallengeRegisterComponent(props) {


    const { gameDetails } = props
    const navigation = useNavigation()
    const [selectedLineUp, setSelectedLineUp] = useState([])
    const [createdLineUpData, setCreatedLineUpData] = useState([])
    const [isApiCalling, setApiCalling] = useState(false)
    const [isShowShimmer, setShowShimmer] = useState(false)

    const userBalance = useSelector((state) => state.lineupReducers.userBalance)

    const dispatch = useDispatch()

//-------------------Fetching the Line Up List-------------------//
    useEffect(() => {
        setShowShimmer(true)
        const unsubscribe = navigation.addListener('focus', () => {
            dispatch(deleteAllStocks())
            dispatch(editLineupName(''))
            getLineupList()
            return unsubscribe
        }, [])
    }, [])



    function getLineupList() {

        fetchCreatedLineup().then((resData) => {
            setCreatedLineUpData(resData)
        }).catch((error) => {
            setApiCalling(false)
            setShowShimmer(false)
        }).finally(() => {
            setApiCalling(false)
            setShowShimmer(false)

        })
    }
//-------------------Function to delete the Line up-------------------//

    const onDeleteLineup = (itemData) => {
        if (itemData.Locked.toLowerCase() == 'true') {
            showAlertMessage()
        }
        else {
            let message = `Are you sure you want to delete ${itemData.lineupName}?`
            Alert.alert(message, APP_STRINGS.undoMsg, [
                {
                    text: APP_STRINGS.cancel,
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
                { text: APP_STRINGS.deleteText, onPress: () => callDeleteLineup(itemData) },
            ])
        }
    }

    const callDeleteLineup = (itemData) => {

        let requestParameter = {
            myUserId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            myLineupName: itemData.lineupName
        }
        setApiCalling(true)
        inactiveLineupAPI(requestParameter).then((res) => {
            getLineupList()
        }).catch(() => {
            setApiCalling(false)
        })

    }
//-------------------Method When the Line Up is selected-------------------//

    const didSelectLineup = (itemData) => {
        if (props.screen != undefined) {
            setSelectedLineUp([itemData])

        } else {
            let requestParameter = {
                locUserID: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                locLineupName: itemData.lineupName
            }
            setApiCalling(true)
            lineupDetailsByIdAPI(requestParameter).then((selectedStocksModel) => {
                dispatch(deleteAllStocks())
                dispatch(addListInStocks(selectedStocksModel))
                dispatch(editLineupName(itemData.lineupName))
                navigation.navigate(ROUTES.CONFIGLINEUP, { isScreenDisable: itemData.Locked.toLowerCase() == 'true' ? true : false })
            }).catch((error) => {
                console.log('Error in lineup Details By Id API',error);
                setApiCalling(false)

            }).finally(() => {
                setApiCalling(false)
            })
        }
    }
//-------------------Show Delete Alert Msg-------------------//

    const showAlertMessage = () => {
        Alert.alert(APP_STRINGS.appName, APP_STRINGS.deleteMsg,)
    }

//-------------------Method for registering the lineup-------------------//

    const onClickRegisterBtn = () => {
        if(createdLineUpData.length == 0){
            Alert.alert(APP_STRINGS.appName, APP_STRINGS.lineupMsg, [
                {
                    text: APP_STRINGS.create,
                    onPress: () => navigation.navigate(ROUTES.DRAWER, { screen: ROUTES.HOME, params: { screen: ROUTES.LINEUP_STACK, params: { screen: 'lineupStack',params:{screen:ROUTES.LINEUP} }} }),
                    style: 'cancel',
                },
            ])
        }
        else if (selectedLineUp.length == 0) {
            Alert.alert(APP_STRINGS.appName, APP_STRINGS.selectLineup, [
                {
                    text: APP_STRINGS.ok,
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
            ])
        } else {
            checkUserRegisteredForThisGame()
        }
    }
//-------------------check if the User id Registered For specific Game-------------------//

    const checkUserRegisteredForThisGame = () => {
console.log('Check user Already regiset');
        let requestParameter = {
            strlocDayCode: gameDetails.gameID,
            strlocUserID: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
        }

        setApiCalling(true)
        getGameStatus(gameDetails.gameID).then(res=>{
            const status = String(res).toUpperCase()
            if(status == APP_STRINGS.open){
                isUserRegisteredForThisGame(requestParameter).then((status) => {
                    console.log(status,'status is registered');
                    if (status) {
                        getGameFee()
                    } else {
                        alertForGameFull()
                    }
        
                }).catch((error) => {
                    setApiCalling(false)
                }).finally(() => {
                 
                })
            }
            else {
                setApiCalling(false)
                let convertedStr = status.toLowerCase().charAt(0).toUpperCase() + status.toLowerCase().slice(1)
                Alert.alert(APP_STRINGS.appName,`Registration for the game is not allowed because the game is in ${convertedStr} state`)
                
            }
        }).catch(err=>{
            setApiCalling(false)
        }).finally(()=>{
            // setApiCalling(false)
        })
        
    }
//-------------------Fetch the Game fees-------------------//

const getGameFee = async () => {
    setApiCalling(true);
    try {
        const [gameAmount, userAmount] = await Promise.all([
            getRegFeeByGameId(gameDetails.gameID),
            getUsersBalance()
        ]);

        console.log(gameAmount, 'Game Amount');
        console.log(userAmount, 'User Amount');
        
        dispatch(updateUserBalance(userAmount));

        if (parseFloat(gameAmount) <= parseFloat(userAmount)) {
            confirmRegistration(gameAmount);
        } else {
            amountNotEnough();
        }
    } catch (error) {
        console.error("Error fetching game fee or user balance:", error);
        // handle specific errors if necessary
    } finally {
        setApiCalling(false);
    }
};
//-------------------Fetch the User Balance-------------------//

    const getUserBalanceAPI = (gameAmount) => {
        // setApiCalling(true)
        getUsersBalance().then((userAmount) => {
            dispatch(updateUserBalance(userAmount))
            if (parseFloat(gameAmount) <= parseFloat(userAmount)) {
             
                confirmRegistration(gameAmount)
            } else {
                setApiCalling(false)
                amountNotEnough()
               
            }
            // setApiCalling(false)
        }).catch((error) => {
            setApiCalling(false)

        }).finally(() => {
            // setApiCalling(false)
        })
    }

    const amountNotEnough = () => {
        setApiCalling(false)
        Alert.alert(APP_STRINGS.error, 'You do not have enough money in your account to join this game.', [
            {
                text: APP_STRINGS.ok,
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            },
        ])

    }
//-------------------Confirm Registration-------------------//

    const confirmRegistration = (gameAmount) => {
        console.log(gameAmount,' gameAmount in Confirming');
        setApiCalling(false)
        let message = `Game ${gameDetails.gameID}`
        Alert.alert(APP_STRINGS.confirmReg, message, [
            {
                text: APP_STRINGS.cancel,
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            },
            { text: APP_STRINGS.yesConfirm, onPress: () =>{
                 setApiCalling(true)
                 addGamePlayerLineup(gameAmount)} },
        ])

    }
//-------------------Add the Game Player for the Lineup ------------------//

    const addGamePlayerLineup = (gameAmount) => {
        

        insertGamePlayerLineup(gameDetails.gameID, selectedLineUp[0].lineupName).then(() => {
            callFinishAPIs(gameAmount)
        }).catch((error) => {
            setApiCalling(false)

        }).finally(() => {

        })
    }
//---------------------Call Finish Apis ------------------//

    const callFinishAPIs = async (gameAmount) => {

        let requestParameter = {
            myGameID: gameDetails.gameID
        }
        let resReg = await addToRegisteredPlayers(requestParameter)
        let resFee = await getRegFeeByGameId(gameDetails.gameID)
        requestParameter = {
            locStrUser_Id: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            strDateTime: moment.utc().format('MM-DD-YYYY HH:mm:ss:SSS'),
            strType: 'Game Entry',
            strSource: gameDetails.gameID,
            dblAmount: gameAmount
        }

        insertGeneralLedger(requestParameter).then((res) => {
            dispatch(updateUserBalance(userBalance - gameAmount))

        }).catch(() => {
            setApiCalling(false)

        }).finally(() => {
            setApiCalling(false)
            navigation.navigate(ROUTES.CHALLENGE_STACK)
        })


    }
//-------------------Alert When the Game is Full-------------------//

    const alertForGameFull = () => {

        setApiCalling(false)
        Alert.alert(APP_STRINGS.gameFull, APP_STRINGS.gameFullMsg, [
            {
                text: APP_STRINGS.ok,
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            }
        ])
    }
//-------------------Challenge register list component-------------------//

    const renderItem = (itemData) => {

        const isSelected = selectedLineUp.filter((ids) => ids.lineupName === itemData.item.lineupName).length > 0
        const backgroundColor = isSelected ? COLORS.blueColor: COLORS.white
        let isLastIndex = itemData.index == createdLineUpData.length - 1

        return (
            <CHALLENGEREGISTERLIST
                itemData={itemData.item}
                onPress={() => {
                    didSelectLineup(itemData.item)
                }}
                backgroundColor={{ backgroundColor }}
                isLastIndex={isLastIndex}
                onClickCancel={onDeleteLineup}

            />
        )
    }
//-------------------Render No Line Up Found Component-------------------//

    const renderEmptyList = () => {

        if (!isApiCalling) {
            return (
                <View style={styles.lineupView}>
                    <Text style={styles.lineupNotFound}>Lineup Not Found</Text>
                </View>
            )

        }
    }

//-------------------Add More Line Ups-------------------//

    const addMoreLineup = () => {

        if (props.screen) {
            navigation.navigate(ROUTES.LINEUP, { useScreen: 'updating' })
        } else {
            navigation.navigate(ROUTES.LINEUP)
        }
    }
//-------------------Render Shimmer (loadeR)-------------------//

    const renderShimmer = () => {
        return (
            <View style={{ marginTop: (20).scaleHeight() }}>
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />

            </View>
        )
    }

    return (
        <View style={styles.container}>
            {props.screen != undefined &&
                <View>
                    <View style={styles.innerView}>
                        <Text style={styles.gameName}>Game Name:  <Text style={{ fontFamily: LATO.Regular, fontSize: (13).scaleWidth() }}> {gameDetails.description}</Text></Text>
                    </View>
                    <View style={styles.titleContainer}>
                        <Text style={styles.subText}>Select a lineup from the list below for registration.</Text>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity onPress={onClickRegisterBtn} activeOpacity={selectedLineUp.length == 0 ? 1 : 0.2} >
                                <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0, y: 0.5 }} colors={selectedLineUp.length == 0 ? ['rgba(255, 78, 0, 0.7)', 'rgba(236, 159, 5, 0.7)'] : ['#FF4E00', '#EC9F05']} style={styles.buttonStyle} >
                                    <Text style={styles.buttonText}>Register</Text>
                                </LinearGradient>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            }

            <View style={styles.shadowView}>

                <View style={styles.headerContainer}>
                    <Text style={[styles.headerText, { flex: 1 }]}>Name</Text>
                    <Text style={[styles.headerText, { flex: 1.8, }]}>Date</Text>
                </View>
                {isShowShimmer ? renderShimmer() :
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        ListFooterComponent={<View style={{ height: scale(150) }}></View>}
                        data={createdLineUpData}
                        keyExtractor={(item) => item.id}
                        renderItem={renderItem}
                        ListEmptyComponent={renderEmptyList}
                    />
                }
            </View>
            {props.screen == undefined && <View style={[{ bottom: props.screen != undefined ? scale(30) : scale(80)},styles.speedDial]} >
                <SpeedDial overlayColor="transparent"
                    color={COLORS.appColor}
                    onOpen={addMoreLineup}
                    icon={{ name: "add", color: COLORS.white }}>
                </SpeedDial>
            </View>}

            {isApiCalling && <Loader />}
        </View >
    )
}
export default ChallengeRegisterComponent