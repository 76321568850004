export default {
    authLogo:require('./daytraderlogo/daytraderlogo.png'),
    deleteIcon:require('./deleteIcon/deleteIcon.png'),
    header:require('./headerLogo/header.png'),
    selectedUp:require('./position/selectedUp.png'),
    selectedDown:require('./position/selectedDown.png'),
    unSelectedUp:require('./position/unselectedUp.png'),
    unSelectedDown:require('./position/unSelectedDown.png'),
    gamesIcon:require('./tabbarIcons/tabbarGames/games.png'),
    challengesIcon:require('./tabbarIcons/tabbarChallenges/challenges.png'),
    homeIcon:require('./tabbarIcons/tabbarHome/home.png'),
    leaderboardIcon:require('./tabbarIcons/tabbarLeaderboard/leaderboard.png'),
    wallet:require('./tabbarIcons/wallet/wallet.png'),
    circleWallet:require('./circleWallet/circleWallet.png'),
    logout:require('./tabbarIcons/logout/logout.png'),
    withdraw:require('./transactionsIcon/withdraw/withdraw.png'),
    depositSuccess:require('./transactionsIcon/depositSuccess/deposit.png'),
    depositFail:require('./transactionsIcon/depositFail/fail.png'),
    depositPending:require('./transactionsIcon/depositPending/pending.png'),
    trophy:require('./transactionsIcon/trophy/trophy.png'),
    withdraw:require('./transactionsIcon/withdraw/withdraw.png'),
    gameEntry:require('./transactionsIcon/gameEntry/game.png'),
    successIcon:require('./successIcon/successIcon.png'),
    defaultBanner:"http://147.135.116.238/content/banners/UnsponsoredGames1030x300.png",
    subscibe:require('./tabbarIcons/subscribe/subscribeBtn.png'),
    reward:require('./tabbarIcons/reward/reward.png'),
    privacy:require('./tabbarIcons/privacy/privacy.png'),
    ques:require('./tabbarIcons/ques/quesIcon.png'),
    lineup:require('./tabbarIcons/lineup/lineup.png'),
    radius:require('./radius/radius.png'),
    filter:require('./filter/filter.png'),
    grp:require('./grp/grp.png'),
    webHeader:require('./webheader/header.png'),
    playStore:require('./playStore/playstore.png'),
    lock:require('./lock/lock.png'),
    nearMe:require('./nearMe/nearMe.png')


}