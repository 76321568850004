import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { LATO } from '../../../../constants/fonts';
import { COLORS } from '../../../../constants';
import { getFAQs } from '../../../../controllers/gamesController';

const faqData = [
    {
        question: "How do I play Day Trader Challenge?",
        answer: "1. Create a lineup. 2. Register your lineup into a Challenge/Game. 3. Once the Challenge/Game starts, view the Leaderboard."
    },
    {
        question: "How do I create a lineup?",
        answer: "1. Click on the 'Lineup' Icon on the bottom of the screen. 2. Click on 'Build Lineup'. 3. Select 8 Stocks from the list of over 500 listed on this page. 4. After you select 8 stocks, the configure lineup button will turn blue. Click this blue button. 5. Enter the dollar amount in the 'spend' block for each stock. Use the entire $1,000,0000 bankroll. The most you can spend on any stock is 150k. 6. Choose whether you believe this stock will go up or down during the duration of the challenge by selecting the up arrow or down arrow in the position section. Do this for all 8 stocks. 7. Click 'SAVE', this will bring up a window to give name your lineup. 8. After you name the lineup, click 'SAVE'."
    },
    {
        question: "How do I register for a challenge?",
        answer: "1. Click the 'Challenges' Icon on the bottom of the page. 2. Select a Challenge from the list of Challenges on this page. This will take you to the lineups you have created so you can choose which one you would like to use for the challenge you selected. 3. Click on a lineup from the lineup list you should now see. 4. Click the Orange Register button. 5. Confirm your selection. 6. CONGRATULATIONS! YOU HAVE JUST REGISTERED FOR YOUR FIRST DAY TRADER CHALLENGE!"
    },
    {
        question: "How do I view the leaderboard?",
        answer: "Once the Challenge begins, click on the 'Leaderboard' Icon at the bottom of the page."
    }
];



function FrequentQuestion() {
    const [expandedIndices, setExpandedIndices] = useState([]);
    const [faqData, setFaqData] = useState([]);

    useEffect(()=>{
        getFAQs().then((res)=>{
                setFaqData(res)
        }).catch((err)=>{
            console.log(err,'ERror in FAQQQQ');
        })
    },[])
    const toggleAnswer = (index) => {
        if (expandedIndices.includes(index)) {
            // If already expanded, remove from array
            setExpandedIndices(expandedIndices.filter(item => item !== index));
        } else {
            // If not expanded, add to array
            setExpandedIndices([...expandedIndices, index]);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>Frequently Asked Questions</Text>
            </View>
            <ScrollView  showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewContent}>


            {faqData.map((faq, index) => (
    <View key={index}>
        <TouchableOpacity style={styles.item} onPress={() => toggleAnswer(index)}>
            <Text style={styles.question}>{index+1}. </Text>                
            <Text style={styles.question}>{faq.question}</Text>
        </TouchableOpacity>
        {expandedIndices.includes(index) && (
            <View style={styles.answer}>
                {faq.answer && (
                    <View style={{flexDirection:"column", flex:1}}>
                        {faq.answer.split(/\d+\./).map((point, i) => {
                            if (point.trim().length > 0) {
                                return (
                                    <View style={{ flexDirection: "row" }} key={i}>
                                        <Text style={styles.numbering}>{i}. </Text>
                                        <Text style={styles.answerText}>{point.trim()}</Text>
                                    </View>
                                );
                            }
                        })}
                    </View>
                )}
            </View>
        )}
    </View>
))}




            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
    },
    scrollViewContent: {
        paddingVertical: (15).scaleWidth(),
        paddingHorizontal: (10).scaleWidth(),
    },
    header: {
        alignItems: 'center',
        marginBottom: (10).scaleWidth(),
        marginTop: (20).scaleWidth()
    },
    title: {
        fontFamily: LATO.Bold,
        fontSize: (20).scaleWidth(),
        color: COLORS.black,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: (15).scaleWidth(),
        backgroundColor: COLORS.white,
        padding: (15).scaleWidth(),
        borderRadius: (10).scaleWidth(),
        shadowColor:COLORS.appColor,
        shadowOffset: { width: 0, height: (2).scaleWidth() },
        shadowOpacity: 0.25,
        shadowRadius: (3.84).scaleWidth(),
        elevation: (5).scaleWidth(),
    },
   
    question: {
        fontFamily: LATO.Bold,
        fontSize: (15).scaleWidth(),
        color: COLORS.black,
    },
    answer: {
        paddingHorizontal: (10).scaleWidth(),
        flex:1,
        paddingVertical: (5).scaleWidth(),
    },
    answerText: {
        fontFamily: LATO.Medium,
        fontSize: (12).scaleWidth(),
        color: COLORS.appColor,
        marginBottom: (5).scaleHeight(),
        paddingBottom: (5).scaleWidth(),
        paddingTop:0,
        lineHeight:20,
        flex:1
        
       
    },
    numbering:{
        fontFamily: LATO.Medium,
        fontSize: (12).scaleWidth(),
        color: COLORS.appColor,
        marginBottom: (5).scaleHeight(),
        paddingBottom: (5).scaleWidth(),
        marginLeft:(5).scaleWidth()
    }
});

export default FrequentQuestion;
