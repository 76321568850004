import moment from "moment";

class LeaderBannerModel {

    constructor(
       
        gameDetail,
        leaderboardBanner
    ) {
        
        this.gameDetail = gameDetail;
        this.leaderboardBanner = leaderboardBanner
    }
    initWithResObj(res){
        this.gameDetail = res['GameDetail']||''
        this.leaderboardBanner = res['LeaderboardBanner']||''
        return this
    }
   



}
export default LeaderBannerModel; 