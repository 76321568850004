import { StyleSheet } from "react-native";
import { COLORS } from "../../../../../constants";
export const styles = StyleSheet.create({
    iconStyle:{
        top:10,
        right:16
    },
    prizeTab:{
        width:(50).scaleWidth(),
        height:(18).scaleHeight(),
        borderRadius:5,
        justifyContent:'center',
        alignItems:'center',
    },
    colorStyle:{
        color:COLORS.black
    },
    mainView:{ 
        backgroundColor:COLORS.white, 
        paddingBottom: (80).scaleHeight(), 
        flex: 1 
    },
    flatlist:{
        flex:1,
        marginTop:(20).scaleHeight(),
        backgroundColor:COLORS.white
    },
})