import { View, ImageBackground, Modal, TouchableOpacity, Text, Platform, Image, StyleSheet, Dimensions } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { Feather,MaterialCommunityIcons } from '@expo/vector-icons';
import {  COLORS, IMAGES } from '../../../constants';
import { useSelector } from 'react-redux';
import InputText from '../inputText';
import AppButton from '../appButton';
import { Divider } from '@rneui/base';
import { ScrollView } from 'react-native';
import { scale } from 'react-native-size-matters';
import { LATO } from '../../../constants/fonts';
import { DIVIDER_TEXT } from '..';


function RewardPopup(props) {


    const [passcode, setPasscode] = useState('')

    const textInputRef = useRef()
    const isLargeScreen = Dimensions.get('window').width >= 768;
   
    useEffect(() => {
      setTimeout(keyboarOnFocus, 3000);
      if (textInputRef.current) {
        textInputRef.current.focus();
       
      }

    }, []);
//-----------------Method to focus on Keyboard-----------//
    function keyboarOnFocus() {
        if (textInputRef.current) {
            textInputRef.current.focus();
        }
    }
    const onPress= () => {
        if(passcode.trim().length==0){
            alert('Please enter passcode')
        }
        else{
            props.onRedeemPasscode(passcode)
        }
       
    }
      
//-----------Save Line Up Method ------------------//
   

    return (

        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                    <View style={[styles.gifContainer,isLargeScreen&&{width:500,height:600}]}>
                        <View style={{flexDirection:'row',justifyContent:'space-between',padding:20,backgroundColor:COLORS.blueColor}}>
                            <Text style={{fontFamily:LATO.Bold,fontSize:17}}>Reward Details</Text>
                            <Feather onPress={props.onDismissPopup} name="x-circle" size={24} color="black" />
                        </View>   
                        <View style={[{backgroundColor:COLORS.white,flex:1,},isLargeScreen&&{width:'90%',marginHorizontal:'auto'}]}>
                            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{flex:1}} style={{flexGrow:1}} >
                            <View style={{margin:20,}}>
                                <Image source={props.data.bannerFile.length == 0 ? {uri:IMAGES.defaultBanner}:{uri:props.data.bannerFile}}  style={[styles.image,{opacity:props.redeemed?0.5:1}]} resizeMode='contain'/>
                            <Divider style={{marginTop:20}}/>
                                <View style={{marginTop:20}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                                    <Text style={styles.text}>{props.data.description}</Text>
                                    <View style={{backgroundColor:COLORS.appDividerColor,borderRadius:5}}>
                                        <Text style={[styles.text,{fontSize:12,padding:5,color:COLORS.gray}]}>{props.data.rewardExperationDate}</Text>
                                    </View>
                                </View>
                                <Text style={styles.gameID}>Reward ID : {props.data.rewardID}</Text>
                                <Text style={styles.gameDetail}>{props.data.reward}</Text>
                                </View>
                            </View>
                            
                            <AppButton buttonStyleWeb={{marginTop:40,marginHorizontal:20}} title={'Scan QR'} onPress={props.onRedeem}/>
                            <DIVIDER_TEXT text ={'Or enter the code manually '}/>
                            <View style={{flexDirection:'row',}}>
                                <InputText
                                    placeHolder={'Enter Passcode'}
                                    icons={<MaterialCommunityIcons  name="lock-outline" size={20} color={COLORS.lightGrayColor} />}
                                    onChangeText={(text)=>setPasscode(text)}
                                    value={passcode}
                                    textContainer={styles.textContainer}
                                    inputStyle={{ fontSize: 16,height:55,outline: "none" ,color:COLORS.gray,}}
                                    />
                             <AppButton buttonStyleWeb={{   flex:0.5,margin:0,marginTop:15,marginRight:10}} title={'Redeem the Offer'} onPress={onPress}/>
                             </View>
                            </ScrollView>
                        </View>
                    </View>
                </ImageBackground>
            </Modal>
        </View >
    );
}

export default RewardPopup;

const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        // flexWrap:'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        // padding: (35),
    },

    gifContainer: {
        height: 550,
        flexWrap:'wrap',
        backgroundColor: 'white',
        marginHorizontal:10
        // flex:1

    },

    buttonStyle: {
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonText: {
        color: COLORS.white,
        fontFamily: LATO.Bold,
        fontSize: (14),
        paddingVertical: 15
    },
    btn:{ 
        width: '90%',
        marginTop:15  
    },
    innerView:{ 
        width: '90%', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end',  
    },
    inputStyle1: {
        fontSize: scale(14),
        fontFamily: LATO.Medium,
        color: COLORS.appGrayColor,
        marginLeft: 0,
      },
    inputStyle:{
        textAlign:'center',
        paddingVertical:5 
    },
    textContainer: {
        borderWidth: 1,
        borderColor: "rgba(223, 225, 229, 1)",
        height: 55,
        marginVertical: 15,
        color: COLORS.white,
        flex:0.5
        
        
       
   },
    image:{
        aspectRatio:1000/300
    },
    innerView: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginLeft: 20, // Adjust as needed
        marginRight: 5, // Adjust as needed
      },
      icon: {
        marginBottom: 10,
        marginRight: 10,
        marginTop:10,
        alignSelf:'flex-end'
      },
      titleView:{
        // justifyContent:'space-around',
        flexDirection:'row',
        justifyContent:'space-between',
        backgroundColor:COLORS.blueColor,
        paddingBottom:10,
        
        
      },
      title: {
        fontFamily: LATO.Bold,
        fontSize: (15),
        textAlign: 'center',
        color: COLORS.black,
        flex:1,
       
        
    
      },
      text: {
        fontFamily: LATO.Medium,
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.black,
      },
      gameID:{
        fontFamily: LATO.Medium,
        fontSize: 15,
        color: COLORS.black,
        marginTop:(15)
      },
    
    gameDetail:{
      fontFamily:LATO.Medium,
      color:`rgba(6, 0, 44, 0.5)`,
      fontSize:14,
      marginTop:(15)
    
    }
});