import { FREQUENTQUESTION, PRIVACYPOLICY, TERMCONDITION, WALLET } from '../../screens/containers/dashboardContainers';
import { checkIfUserSubscribed } from '../../controllers/verifyPayments';
import { APP_STRINGS, COLORS, IMAGES, ROUTES } from '../../constants';
import { HEADERLOGO, SUBSCRIBE_BTN } from '../../screens/components';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { changeSubscriptionStatus } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { GroupTopBar, RewardTopBar } from '../topBarNavigation';
import BottomNavigation from '../bottomNavigation';
import CustomDrawer from './customDrawer';
import { LATO } from '../../constants/fonts';
import { Avatar } from '@rneui/base';
import { useEffect } from 'react';
import * as React from 'react';


const Drawer = createDrawerNavigator();

function DrawerNavigation() {
    const isUserSubscribed = useSelector((state)=>state.authReducers.isUserSubscribed)
    const dispatch =useDispatch()
     useEffect(()=>{
        setTimeout(()=>{
            checkSubStatus()
        },2000)
      
     },[])  
     const checkSubStatus = async () => {
        checkIfUserSubscribed().then((res)=>{
                if(res.status){
                    dispatch(changeSubscriptionStatus(true)) 
                }
                else{
                    dispatch(changeSubscriptionStatus(false))
                }
        }).catch((err)=>{
            console.log('err',err);
        })
    }
    return (
        <Drawer.Navigator initialRouteName={ROUTES.HOME}
            screenOptions={{
                headerStyle: { backgroundColor: COLORS.appColor },
                headerTintColor: COLORS.white,
                drawerType: 'front',
                drawerActiveBackgroundColor: false,
                drawerStyle: { backgroundColor: COLORS.white},
                drawerActiveTintColor: COLORS.black,
                headerTitle: (props) => <HEADERLOGO {...props} />,
                headerTitleAlign:'left',
                headerRight: isUserSubscribed == false ?  (props) => <SUBSCRIBE_BTN {...props} /> : isUserSubscribed == undefined ? null : null, // Render subscribe button only if not subscribed
                drawerItemStyle: { borderBottomColor: COLORS.drawerBottom, borderBottomWidth: 1 },
                drawerLabelStyle: { color: COLORS.drawerLabel, fontFamily: LATO.Medium, fontSize: (15).scaleWidth() },
            }}
            drawerContent={(props) => (<CustomDrawer {...props} />)}
        >
            <Drawer.Screen name={ROUTES.HOME} component={BottomNavigation}
                options={{
                    title: APP_STRINGS.myGames,
                    drawerIcon: () => (<Avatar avatarStyle={{ tintColor: COLORS.black ,resizeMode:'contain'}} source={IMAGES.gamesIcon} size={25} />                
                    ),
                }}

            />
             <Drawer.Screen name={ROUTES.REWARD} component={RewardTopBar}
                options={{
                    headerTitle: (props) => <HEADERLOGO {...props} title={'My Rewards'} />,
                    headerRight:null

                }}

            />
            <Drawer.Screen name={ROUTES.FREQUENTQUESTION} component={FREQUENTQUESTION}
                options={{
                    headerTitle: (props) => <HEADERLOGO {...props} title={'FAQs'} />,
                    headerRight:null

                }}

            />
           <Drawer.Screen name={ROUTES.PRIVACYPOLICY} component={PRIVACYPOLICY}
                options={{
                   
                    headerTitle: (props) => <HEADERLOGO {...props} title={'Privacy Policy'} />,
                    headerRight:null
                    
                }}

            />
           <Drawer.Screen name={ROUTES.TERMCONDITION} component={TERMCONDITION}
                options={{
                    headerTitle: (props) => <HEADERLOGO {...props} title={'T&C'}/>,
                    headerRight:null,
                    headerLeftLabelVisible:true
                    
                    
                }}

            />
           <Drawer.Screen name={ROUTES.WALLET} component={WALLET}
                options={{
                   
                    headerTitle: (props) => <HEADERLOGO {...props} title={'My Wallet'} />,
                    headerRight:null
                    
                }}

            />
             <Drawer.Screen name={ROUTES.GROUP} component={GroupTopBar}
                options={{
                   
                    headerTitle: (props) => <HEADERLOGO {...props} title={'Groups'} />,
                    headerRight:null
                    
                }}

            />
        </Drawer.Navigator>
    );
}

export default DrawerNavigation;