import {  Image, View, StyleSheet } from "react-native"
import { COLORS } from "../../../constants"

const LeaderBoardGameDetailsForOthers = (props) => {
    let logo = `https://storage.googleapis.com/iex/api/logos/${props.data.symbol}.png`
    return (    
     <View style={styles.imageView}>
        <Image source={{uri:logo}}resizeMode="contain" style={styles.image}/>
     </View>
    )
}
export default LeaderBoardGameDetailsForOthers;
const styles = StyleSheet.create({
  imageView: {
    backgroundColor: COLORS.white,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: (5),
    borderColor: "lightgrey",
    flex: 1,
    marginTop: (10).scaleHeight(),
    marginHorizontal: (5).scaleWidth(),
  },
  image: { 
    height: (45).scaleHeight(), 
    width: "90%",
    margin: (5).scaleWidth(), 
 },
});