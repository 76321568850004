import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
export const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
        paddingVertical: (12).scaleHeight(),
        paddingRight: (5).scaleWidth(),
        paddingLeft: (8).scaleWidth(),
        alignItems: 'center',
        borderBottomWidth:1,
        borderBottomColor:COLORS.lighterGrey
    },
    textStyle: {
        color: COLORS.appColor,
        fontFamily: LATO.Medium,
        fontSize: (13).scaleWidth(),
        flex: 1,
    },
    innerTextStyle: {
        color: COLORS.appColor,
        fontFamily: LATO.Medium,
        fontSize: (13).scaleWidth(),
        paddingLeft: 5
    }
})