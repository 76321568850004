import { MaterialCommunityIcons, Ionicons, AntDesign } from '@expo/vector-icons'
import {Text, TouchableOpacity, View ,Image, Dimensions} from "react-native"
import { APP_STRINGS, COLORS, IMAGES } from "../../../constants"
import styles from "./index.style"
import PropTypes from 'prop-types'
import { LATO } from '../../../constants/fonts'



const GameComponent = (props) => {
  const width = Dimensions.get('screen').width
  const onPressCell = () => {
    if (props.onPress != undefined) {
      props.onPress(props.data)
    }
  }
 

  return (
    <TouchableOpacity  onPress={onPressCell} activeOpacity={props.redeemed ? 1 :0.5}  key={props.data.gameID} style={[styles.innerView,props.isFeatured&&{width:width,marginHorizontal:0}]}>
      <View style={styles.titleView}>
        <Text style={styles.title}>{props.data.description}</Text>
        <View>
          {(props.cellType == APP_STRINGS.pending || props.cellType == APP_STRINGS.running)
           && 
          <AntDesign name='questioncircleo' size={24} style={styles.markIcon} onPress={props.onPressQuestionMark}/>}
          {props.cellType == APP_STRINGS.cancelled &&
            <View style={styles.cellTypeView}>
              <View style={[styles.circleView, { backgroundColor: COLORS.redCancelColor }]} />
              <Text style={[styles.textCellType, { color: COLORS.redCancelColor }]}>CANCELLED</Text>
              <AntDesign name='questioncircleo' size={24} style={styles.markIcon} onPress={props.onPressQuestionMark}/>

            </View>}
          {props.cellType == APP_STRINGS.completed &&
            <View style={styles.cellTypeView}>
              <View style={[styles.circleView, { backgroundColor: COLORS.green }]} />
              <Text style={[styles.textCellType, { color: COLORS.green }]}>COMPLETED</Text>
              <AntDesign name='questioncircleo' size={24} style={styles.markIcon} onPress={props.onPressQuestionMark}/>
            </View>}

          {props.cellType == APP_STRINGS.challenge &&
            <View style={styles.cellTypeView}>
              <Text style={[styles.textCellType, {fontFamily:LATO.Bold, color: COLORS.redCancelColor, fontSize: (15).scaleWidth(), marginRight:5}]}>{props.data.lineupName.length>10?(props.data.lineupName).substring(0,10)+"...":props.data.lineupName}</Text>
              {/* {props.data.gameType=='Private' && <Text style={[styles.textCellType, {fontFamily:LATO.Bold, color: COLORS.orange, fontSize:14,backgroundColor:'rgba(237, 171, 0, 0.2)',padding:5 }]}>{'PRIVATE'}</Text>} */}
              <AntDesign name='questioncircleo' size={24} style={styles.markIcon} onPress={props.onPressQuestionMark}/>
            </View>}
          

        </View>
      </View>
      <View style={styles.lineView} />
      {/* second Views */}
      <View style={{backgroundColor:props.redeemed?'rgba(0,0,0,0.1)':'',opacity:props.redeemed ?0.5:1}}>
        <Image source={props.data.bannerFile.length == 0 ? {uri:IMAGES.defaultBanner}:{uri:props.data.bannerFile}} style={[styles.image,{opacity:props.redeemed?0.5:1,borderBottomLeftRadius:props.isInfoPost ? 8: 0,borderBottomRightRadius:props.isInfoPost ? 8: 0,}]} resizeMode='contain'/>
       {!props.isInfoPost && !props.reward && 
       <View style={styles.gameIDView}>
          <Text style={[styles.gameID,]}>Game ID : {props.data.gameID}</Text>
          <Text style={[styles.gameID,{marginRight:(30).scaleWidth()}]}> {parseFloat(props.data.registrationFee).toFixed(2)== 0?'Free Game':`$${parseFloat(props.data.registrationFee).toFixed(2)}`}</Text>
          <Text style={styles.gameID}>{props.data.numRegistered}/{props.data.maxPlayers}</Text>
        </View>
        }
      </View>
      {/* Third Views */}
      {!props.isInfoPost && !props.reward && <View style={styles.thirdView}>
        <View style={styles.imageView}>
          <MaterialCommunityIcons name="calendar-clock-outline" color={COLORS.iconAppColor} size={16} />
          <Text style={styles.dateText}> {props.data.eventDateRange} {'(ET)'}</Text>
        </View>
        <View style={styles.hoursView}>
          <View style={styles.iconTextView}>
            <Ionicons name="game-controller-outline" size={18} color={COLORS.white} />
          </View>
          <Text style={styles.hour}>{props.data.duration}</Text>
        </View>
      </View>}
      {
        props.reward && (
        <View>
            <View style={styles.gameIDView}>
              <Text style={styles.gameDetail}>{props.gameDetail?props.gameDetail:''}</Text>
            </View>
            <View style={[styles.thirdView,{paddingVertical:10}]}>
              <View style={styles.imageView}>
                <Text style={[styles.rewardGameId,{color:props.redeemed?COLORS.gray:COLORS.black}]}>Reward ID: {props.data.rewardID}</Text>          
              </View>
              <View>
                <Text style={styles.dateText}>{props.data.status.trim()=='EXPIRED' ?`EXPIRED` : props.data.status.trim()=='REDEEMED' ? `${props.data.redeemedDateTime}` :`Expires: ${props.data.rewardExperationDate}` }</Text>
              </View>
            </View>
        </View>
        )
      }

    </TouchableOpacity>


  )
}
export default GameComponent;



GameComponent.propTypes = {
  cellType: PropTypes.oneOf([APP_STRINGS.pending, APP_STRINGS.running, APP_STRINGS.completed, APP_STRINGS.cancelled, APP_STRINGS.challenge,'reward',]).isRequired,
}
