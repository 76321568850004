import { StyleSheet } from 'react-native';
import { moderateScale, scale, verticalScale } from 'react-native-size-matters';
import { COLORS } from '../../constants'
import { LATO, OVERPASS } from '../../constants/fonts';


export default StyleSheet.create({
    mainContainer:{
        flex:1
    },

    bodyContainer: {
        flex: 1,
        backgroundColor: COLORS.appColor
    },
    contentContainerStyle:{ 
        flexGrow: 1 
    },

    buttonStyle: {
        margin: scale(15),
    },

    buttontextStyle: {
        fontSize: scale(13),
        fontFamily: LATO.Bold,
        color: COLORS.white,
        textAlign: 'center'
    },

    gradientStyle: {
        height: verticalScale(38),
        justifyContent: 'center',
        borderRadius: 5
    },
   
    buttontextStyleWeb: {
        fontSize: 15,
        fontFamily: LATO.Bold,
        color: COLORS.white,
        textAlign: 'center'
    },

    gradientStyleWeb: {
        height: 55,
        justifyContent: 'center',
        borderRadius: 5
    },
    authContainer: {
        flex: 1,
        backgroundColor: COLORS.white,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25
    },
    authTitleStyle: {
        marginTop: verticalScale(40),
        textAlign: 'center',
        fontSize: scale(22),
        color: COLORS.appColor,
        fontFamily: OVERPASS.Bold,
        marginBottom: verticalScale(10),

    },
    headerView:{ 
        flexDirection: 'row', 
        alignItems: 'center' 
    },
    logoTitle:{ 
        marginLeft: scale(20) 
    },
    tabBarStyle:{
        backgroundColor: COLORS.appColor,
        borderRadius: verticalScale(50),
        marginHorizontal: moderateScale(12),
        paddingTop: moderateScale(5),
        paddingRight:moderateScale(8),
        paddingBottom:  moderateScale(8),
        position: 'absolute',
        height: verticalScale(56)

    },
    headerStyle:{ 
        backgroundColor: COLORS.appColor, 
    },




})