import { getUserGroups, removeUserFromGroup } from '../../../../../controllers/groupsController';
import { View, Text, StyleSheet, TouchableOpacity, Alert } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Avatar, SpeedDial } from '@rneui/base';
import { APP_STRINGS, COLORS, IMAGES, ROUTES, STORAGE_KEYS } from '../../../../../constants';
import { LATO } from '../../../../../constants/fonts';
import { FacebookLoader } from 'react-native-easy-content-loader';
import TableFooterView from '../../../../components/tableFooterView';
import Loader from '../../../../components/loader';
import { TOAST_MESSAGE } from '../../../../../utils';
import { _scale } from '../../../../../utils/appHelper';
import { LOCAL_STORAGE } from '../../../../../services';


export default function MyGroups({navigation}) {

  const [groups, setGroups] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
  const [isLoader,setIsLoader] = useState(false)
  const isFocused = useIsFocused(false)


  const getGroups = () => {
    getUserGroups().then((res)=>{
      setGroups(res)
    }).catch((err)=>{
      console.log(err,'Error in  getting My groups');
    }).finally(()=>{
      setIsLoading(false)
    })
  }

  useEffect(()=>{
    if(isFocused){
      setIsLoading(true)
      getGroups()
    }
  },[isFocused])


 const leaveGroup = (groupID) => {
    setIsLoader(true)
    let requestParameter = {
        "UserId": LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
        "GroupId": groupID,
    }
    removeUserFromGroup(requestParameter).then((res)=>{
      if(res[0].Status==200) TOAST_MESSAGE(res[0].Message)
        else TOAST_MESSAGE(res[0].Message,'1')
      getGroups()
     
    }).catch((err)=>{
      console.log(err,'error in deleting user from group');
    }).finally(()=>{
      setIsLoader(false)
    })
 }
  const handleDeleteGroup = (groupId) => {
    Alert.alert(APP_STRINGS.appName,'Do you want to leave this group?',[
      {
          text: APP_STRINGS.no,
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
      },
      { text: 'Yes', onPress: () => leaveGroup(groupId) },
  ])
    setGroups(groups.filter(group => group.id !== groupId));
  } 
  const renderShimmer = () => {
    return(
      <View style={{ flex: 1,}}>
      {Array(12).fill(0).map((i) => {
        return (
          <FacebookLoader active={true}  avatarStyles={styles.avatarStyles} pRows={0}/>
        )
      })}
    </View>
    )
   }
  const renderItem = ({ item }) => {
    console.log(item);
  return  (
  <TouchableOpacity onPress={()=>{navigation.navigate(ROUTES.GROUP_GAMES,{screen:'myGroup',groupID:item.groupID,groupName:item.groupName})}} activeOpacity={0.8} style={styles.rowFront}>
       {item.groupType=='Private'&&<Avatar source={IMAGES.lock} size={24}/>}
      <Text style={styles.groupName}>{item.groupName}</Text>
    </TouchableOpacity>
    )
  }

  const renderHiddenItem = (data, rowMap) => (
    <View style={styles.rowBack}>
      <TouchableOpacity style={styles.backRightBtn} onPress={() => handleDeleteGroup(data.item.groupID)}>
        <Avatar source={IMAGES.deleteIcon} size={20}  />
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={{ flex: 1, backgroundColor: 'rgba(249, 249, 249, 1)',marginBottom:20 }}>
    {isLoading? renderShimmer() :  
    <SwipeListView
        data={groups}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        leftOpenValue={0}
        stopLeftSwipe={1}
        rightOpenValue={-75}
        keyExtractor={(item) => item.groupID.toString()}
        ListEmptyComponent={<TableFooterView message={'No Groups Found'}/>}
      />}
        <SpeedDial 
          overlayColor="transparent"
          color={COLORS.appColor}
          onOpen={()=>navigation.navigate(ROUTES.AVAILABLE_GROUP)}
          icon={{ name: "add", color: COLORS.white }}>
        </SpeedDial>
        {isLoader&&<Loader/>}
    </View>
  );
}

const styles = StyleSheet.create({
  rowFront: {
    backgroundColor: '#fff',
    borderBottomColor: '#ccc',
    alignItems: 'center',
    justifyContent:'space-between',
    padding: (20).scaleWidth(),
    marginTop: 10,
    marginHorizontal: 10,
    borderRadius: 15,
    flexDirection:'row'
  },
  rowBack: {
    alignItems: 'center',
    // backgroundColor: 'red',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 15,
    marginTop: 10,
    marginHorizontal: 10,
    borderRadius: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
    right: 0,
    borderRadius: 15,
  },
  groupName: {
    fontSize: 16,
    fontFamily: LATO.Medium,
  },
  avatarStyles:{ 
    width: '100%', 
    height: _scale(55), 
    borderRadius: _scale(10) 
  },
  avatarContainer:{
    borderRadius:(14).scaleWidth(),
    marginVertical:0
  },
});
