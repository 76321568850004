export default {
    emailBlank: 'Please enter email',
    InvalidEmail: 'Please enter a valid email',
    userNameBlank: 'Please enter username',
    passwordBlank: 'Please enter password',
    otpBlank: 'Please enter 6 digit OTP',
    blankAccName:'Please enter account holder name',
    blankAmt:'Please enter amount',
    blankAccNum:'Please enter account number',
    blankRouting:'Please enter routing number',
    invalidRoutingNum:'Please enter valid routing number',
    currentPassword:"Please enter current password",
    passwordPattern:"Passwords must have at least one non alphanumeric character.\nPasswords must have at least one digit ('0'-'9').\nPasswords must have at least one uppercase ('A'-'Z')",
    confirmPassword:"Password and confirm password did not match.",
    blankFname:"Please enter first name",
    blankLname:"Please enter last name",
    blankAddress:"Please enter address",
    blankCity:"Please enter city",
    blankState:"Please enter state",
    blankZip:"Please enter zip code",
    validZip:'Zip code should be 5 to 6 digits long.',
    blankPhone:"Please enter phone",
    validPhone:'Please enter valid phone',
    acceptPrivacy:"Please accept Terms and Privacy Policy",
    invalidAmt:'Please enter a valid amount',
    insufficient:'Insufficient Balance',
    negativeAmt: 'Please enter valid amount',
    noInternet:'Internet is not connected',
    invalidRange:'Error message for when the entered amount is outside the specified' ,
    blankPayPalId:"Please enter Paypal Id",
    invalidPayPalId:"Please invalid Paypal Id",
    receiptFailed:'Reciept verification failed!',
    errorCodeOne:'Failed to get location. Please grant permission to access your location.',
    errorCodeTwo: 'Failed to get location. Please go to settings & enable location services',
    errorCodeThree:'Failed to get location.',
};
