import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, Alert } from 'react-native';
import { LATO } from '../../../../../constants/fonts';
import { COLORS, IMAGES, ROUTES, STORAGE_KEYS } from '../../../../../constants';
import { Avatar } from '@rneui/base';
import { FlatList } from 'react-native';
import RBSheet from 'react-native-raw-bottom-sheet';
import PasscodePopup from '../../../../components/passcodeComponent'
import { addUserToGroup, getAvailableGroups } from '../../../../../controllers/groupsController';
import { useIsFocused } from '@react-navigation/native';
import { FacebookLoader } from 'react-native-easy-content-loader';
import { TOAST_MESSAGE } from '../../../../../utils';
import { useDispatch } from 'react-redux';
import { LOCAL_STORAGE } from '../../../../../services';
import { _scale } from '../../../../../utils/appHelper';
import Loader from '../../../../components/loader';

export default function AvailableGroups({navigation}) {
  const [groups, setGroups] = useState([]);
  const [selectedGame, setSelectedGame] = useState(false)
  const [passcode, setPasscode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const sheetRef = useRef()
  const isFocused = useIsFocused()
  useEffect(()=>{
    if(isFocused){
      setIsLoading(true)
      getAvailableGroups().then((res)=>{
        setGroups(res)
       }).catch((er)=>{
        console.log(er);
       }).finally(()=>{
        setIsLoading(false)
       })
    }
    
  },[isFocused])
  const dispatch = useDispatch()
  const handleDeleteGroup = (groupId) => {
    setGroups(groups.filter(group => group.id !== groupId));
    
  };
  const onJoinGame = () => {
    setIsLoader(true)
    if(selectedGame.groupType=='Private'){
      if(passcode.trim().length==0){
        TOAST_MESSAGE('Please enter passcode','1')
        setIsLoader(false)
       }
       else{ 
        sheetRef.current.close()
        let req={
            "UserId": LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
            "GroupId": selectedGame.groupID,
            "PassCode":passcode    
        }
       
        addUserToGroup(req).then((res)=>{
            if(res[0].Status==200){
              TOAST_MESSAGE(res[0]['Message'])
              navigation.navigate(ROUTES.MY_GROUP)
            }
            else{
              TOAST_MESSAGE(res[0]['Message'],'1')
            }
            setIsLoader(false)
        }).catch(()=>{
          setIsLoader(false)
        })
       }
    }
    else{
      let req={
        "UserId": LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
        "GroupId": selectedGame.groupID,
        "PassCode":passcode    
    }
    setIsLoader(true)
    addUserToGroup(req).then((res)=>{
        if(res[0].Status==200){
          TOAST_MESSAGE(res[0]['Message'])
          navigation.navigate(ROUTES.MY_GROUP)
        }
        else{
          TOAST_MESSAGE(res[0]['Message'],'1')
        }
        
       
    }).catch(()=>{
        

    }).finally
    (()=>{
      setIsLoader(false)
    })
 
      sheetRef.current.close()
    }
     
  }
  const onClickGroup = (item) => {
    setSelectedGame(item)
    sheetRef.current.open()
  }
  
  const renderShimmer = () => {
   return(
    <View style={{ flex: 1,}}>
    {Array(15).fill(0).map((i) => {
      return (
        <FacebookLoader active={true}  avatarStyles={styles.avatarStyles} pRows={0}/>
      )
    })}
  </View>
   )
  }
  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={()=>onClickGroup(item)} activeOpacity={0.8} style={styles.rowFront}>
      <Text style={styles.groupName}>{item.groupName}</Text>
      {item.groupType=='Private'&&<Avatar source={IMAGES.lock} size={24}/>}
    </TouchableOpacity>
  );


  return (
    <View style={styles.mainView}>
      {isLoading? renderShimmer():
        <FlatList
          data={groups}
          renderItem={renderItem}
          keyExtractor={(item) => item.groupID.toString()}
        />
      }
     
       <RBSheet 
          height={Dimensions.get("screen").height * 0.38} 
          ref={sheetRef} 
          openDuration={100}
          customStyles={{
              wrapper: {
              backgroundColor: 'rgba(0,0,0,0.6)',
              },
              draggableIcon: {
              backgroundColor: COLORS.white,
              },
              container: {
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              backgroundColor: COLORS.white,
          
              },
          }}
        >
            <PasscodePopup   onJoinGame={onJoinGame} selectedGame={selectedGame} onChangeText={(text)=>setPasscode(text)} onDismissPopup={()=>sheetRef.current.close()} />
        </RBSheet>
        {isLoader && <Loader/>}
      
    </View>
  );
}

const styles = StyleSheet.create({
  mainView:{ 
    flex: 1, 
    backgroundColor: 'rgba(249, 249, 249, 1)' 
  },
  rowFront: {
    backgroundColor: '#fff',
    borderBottomColor: '#ccc',
    alignItems: 'center',
    justifyContent:'space-between',
    padding: (20).scaleWidth(),
    marginTop: 10,
    marginHorizontal: 10,
    borderRadius: 15,
    flexDirection:'row'
  },
  rowBack: {
    alignItems: 'center',
    // backgroundColor: 'red',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 15,
    marginTop: 10,
    marginHorizontal: 10,
    borderRadius: 15,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
    // backgroundColor: 'red',
    right: 0,
    borderRadius: 15,
  },
  groupName: {
    fontSize: 16,
    fontFamily: LATO.Medium,
  },
  avatarStyles:{ 
    width: '100%', 
    height: _scale(55), 
    borderRadius: _scale(10)  
  },
  avatarContainer:{
    borderRadius:(14).scaleWidth(),
    marginVertical:0
  },
});
