import ApiManager from '../../services/apiManager'
import RewardModel from '../../models/rewardModel'
import { LOCAL_STORAGE } from '../../services';
import { STORAGE_KEYS } from '../../constants';

let apiManager = ApiManager.getInstance();

//----------------Get pending games for the current user---------------------//

export const getRewards = async () => new Promise((resolve, reject) => {
    let requestParameter = {
        id:LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi('Player/GetRewards', requestParameter).then((responseData) => {
        let json = responseData.json
        let tableRecord = json["Table"]
        let pendingGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                pendingGamesRecord.push(new RewardModel().initWithObj(val))
            });
        }
        resolve(pendingGamesRecord)

    }).catch((error) => {
        console.log("get Rewardss GamesAPI Error:", error);
        reject(error)
    })
})

export const validatePasscode = async (requestParameter) => new Promise((resolve, reject) => {
   
    apiManager.onPostApi('Global/ValidateReward', requestParameter).then((responseData) => {
        resolve(responseData.json)

    }).catch((error) => {
        console.log("validation Error:", error);
        reject(error)
    })
})
