import { Image, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { LATO } from '../../../../constants/fonts'
import { APP_STRINGS, COLORS, IMAGES, ROUTES } from '../../../../constants'
import AppButton from '../../../components/appButton'
import { transactionStatus } from '../../../../controllers/paymentController'
import { TOAST_MESSAGE } from '../../../../utils'
import Loader from '../../../components/loader'



export default function StatusScreen({navigation}) {
  const [ isLoading ,setIsLoading ] = useState(false)
  const [ status ,setStatus ] = useState('UNDEFINED')
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setTimeout(()=>{
        transactionStatus().then((res)=>{
         setIsLoading(false);
         console.log(res, 'RESSSS');
         setStatus(res.transactionStatus);
          if (res.transactionStatus == 'COMPLETED') {
            TOAST_MESSAGE(APP_STRINGS.amtAdded);
          } 
          else if (res.transactionStatus == 'FAILED') {
            TOAST_MESSAGE(APP_STRINGS.transactionFailed);
          } 
          else if (res.transactionStatus == 'PENDING') {
            TOAST_MESSAGE(APP_STRINGS.transactionFailed);
          }
        })
      },5000)
    };

    fetchData()
  }, []);
  return (
    <SafeAreaView style={{flex:1}}>
        <View style={styles.container}>
          {status == 'COMPLETED' &&
            <View style={styles.checkIconView}>
              <Image source={IMAGES.successIcon}/>
            </View>
          }
          {status == 'FAILED' &&
           <View style={{alignItems:'center',justifyContent:'center'}}>
            <Image style={{height:50,width:50}} source={IMAGES.depositFail}/> 
            <Text style={styles.text}>Transaction Failed</Text>
          </View>
          }
          {status == 'PENDING' &&
          <View style={{alignItems:'center',justifyContent:'center'}}>
            <Image style={{height:50,width:50}} source={IMAGES.depositPending}/> 
            <Text style={styles.text}>Transaction Pending!</Text>
          </View>
          }
          
          {status == 'COMPLETED' && 
          <>        
            <Text style={styles.text}>Transaction Successful!</Text>
            <Text style={styles.subText}>Payment added to your wallet</Text>
          </>  
          }
          {status.length == 0 &&
            <View style={{alignItems:'center',justifyContent:'center'}}>
              <Image style={{height:50,width:50}} source={IMAGES.depositPending}/> 
              <Text style={styles.text}>Transaction Pending!</Text>
            </View>
          }
        </View>
        {isLoading&&<Loader/>}
        <AppButton title={APP_STRINGS.goToWallet} onPress={()=>navigation.navigate(ROUTES.WALLET)}/>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: LATO.Bold,
    fontSize: (24).scaleWidth(),
    marginTop: (15).scaleWidth(),
  },
  subText: {
    fontFamily: LATO.Medium,
    fontSize: (14).scaleWidth(),
    margin: (10).scaleWidth(),
    color: "grey",
  },
  checkIconView: {
    padding: (20).scaleWidth(),
    backgroundColor: COLORS.green,
    borderRadius: (100).scaleWidth(),
    shadowColor: COLORS.green,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: (10).scaleWidth(),
    shadowOpacity: 1,
    elevation: 10,
  },
});