import { AUTHHEADER, CHALLENGEREGISTERCOMPONENT } from "../../../components";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STRINGS, ROUTES } from "../../../../constants";
import { APP_STYLES } from "../../../../utils";

function LineupListToChallenge({ navigation, route }) {

    const { gameDetails } = route.params

    return (
        <SafeAreaView edges={['left', 'right', 'top']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title={APP_STRINGS.my_line} />
            <CHALLENGEREGISTERCOMPONENT navigation={navigation} route={route} gameDetails={gameDetails} screen={ROUTES.LINEUP_LIST_TO_CHALLANGES} />
        </SafeAreaView>
    )
}
export default LineupListToChallenge;