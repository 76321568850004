import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({

    container: {
        flex: 1,
        backgroundColor: COLORS.lighterBlue,
        paddingHorizontal: (10).scaleWidth(),
        paddingTop: (10).scaleHeight()
    },
    innerView:{ 
        alignItems: 'center' 
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    gameName:{ 
        fontFamily: LATO.Bold, 
        fontSize: (15).scaleWidth() 
    },
    subText: {
        flex: 1,
        fontSize: (11).scaleWidth(),
        paddingRight: 5,
        fontFamily: LATO.Medium,
        color: COLORS.darkGrayColor,
    },

    buttonContainer: {
        width: (80).scaleWidth()
    },

    buttonStyle: {
        height: (28).scaleHeight(),
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center'
    },

    buttonText: {
        color: COLORS.white,
        fontFamily: LATO.Heavy,
        fontSize: (12).scaleWidth()
    },

    headerContainer: {
        justifyContent:'space-between',
        backgroundColor: COLORS.appColor,
        flexDirection: 'row',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingVertical: (12).scaleHeight(),
        paddingLeft: (8).scaleWidth(),
        paddingRight: (30).scaleWidth(),
        

    },

    headerInnerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    headerText: {
        color: COLORS.white,
        fontFamily: LATO.Bold,
        fontSize: (13).scaleWidth()
    },
    speedDial:{
        position: 'absolute', 
        right: scale(5), 
        width: scale(60), 
        height: scale(60),
    },
    headerInnerText: {

        color: COLORS.white,
        fontFamily: LATO.Semibold,
        fontSize: (13).scaleWidth(),
        paddingLeft: 5
    },

    flatListContainer: {
        height: (5).scaleHeight(),
        backgroundColor: COLORS.white,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },

    shadowView: {
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 3 },
        shadowRadius: 10,
        marginTop: (15).scaleHeight(),
        
    },
    avatarStyles:{ 
        width: '100%', 
        height: (40).scaleHeight(), 
        borderRadius: 10 
    },
    lineupView:{ 
        alignItems: 'center' ,
        justifyContent:'center',
        height:(320).scaleHeight(),
    },
    lineupNotFound:{
        fontFamily:LATO.Bold,
        color:COLORS.black,
        fontSize: (16).scaleHeight()
    }
});