import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderBottomWidth:1,
    borderColor:COLORS.lighterGrey
  },
  innerContainer: {
    flexDirection: 'row',
    paddingVertical: (10).scaleWidth(),
    paddingHorizontal: (10).scaleWidth(),
    alignItems: 'flex-start',
  },
  userNameTextStyle: {
    fontSize: (13).scaleWidth(),
    fontFamily: LATO.Medium,
    flex: 0.3,
    paddingHorizontal: (10).scaleWidth(),
  },
  earningTextStyle: {
    fontSize: (13).scaleWidth(),
    fontFamily: LATO.Medium,
    flex: 0.7,
    marginTop:(4).scaleHeight(),
    paddingHorizontal: (10).scaleWidth(),
  },
  rankView: {
    // paddingHorizontal: (8).scaleWidth(),
    borderWidth: 1,
    paddingVertical: (5).scaleHeight(),
    borderColor: COLORS.white,
    borderRadius: (5).scaleWidth(),
    backgroundColor: COLORS.skyBlue,
    width: (35).scaleWidth(),
    maxWidth:(45).scaleWidth()

  },
  rankTextStyle: {
    fontSize: (13).scaleWidth(),
    fontFamily: LATO.Medium,
    flex: 1,    
    textAlign:'center'
  },
});
