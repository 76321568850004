import { Image,View,Text,Platform } from "react-native";
import { IMAGES,COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
//----------------------Header Component-----------------------//
function LogoTitle(props) {
    return (
        <>
        {props.title ?
         <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
          <Image source={IMAGES.header} style={{ bottom:Platform.OS=='android'?0:8, ...props.logoStyle }} />
           <Text style={{color:COLORS.white,fontSize:16,textAlign:'center',marginLeft:5,fontFamily:LATO.Bold}}>{props.title}</Text>
        </View>:
         <Image source={IMAGES.header} style={{ bottom:Platform.OS=='android'?0:8, ...props.logoStyle }} />
         }
  
          </>
      )
  
    
}
export default LogoTitle;