import { ADD_FUND, COMPLETED_GAME_DETAILS, CONFIGLINEUP, LEADER_BOARD_LIST, LINEUP, LINEUP_LIST_TO_CHALLANGES, PAYMENT, PRIVACYPOLICY,QR_SCANNER, REWARD_DETAIL, STATUS_SCREEN, TERMCONDITION, TRANSACTION_HISTORY, WALLET, WEBVIEW_SCREEN, WITHDRAW_FUND,GROUP_GAMES, ACTIVE_REWARD, REDEEMED_REWARD } from '../../screens/containers/dashboardContainers'
import { CONFIRMPASSWORD, FORGOT, LOGIN, OTP, PROFILE, SIGNUP } from '../../screens/containers/authContainers'
import SetNewPassword from '../../screens/containers/authContainers/setNewPassword'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NavigationContainer } from '@react-navigation/native'
import { changeLoginStatus } from '../../redux/reducers'
import { useDispatch, useSelector } from  "react-redux"
import { APP_STRINGS, COLORS, IMAGES, ROUTES, STORAGE_KEYS } from '../../constants'
import Loader from '../../screens/components/loader'
import DrawerNavigation from '../drawerNavigation'
import { useEffect } from "react"
import * as React from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { StyleSheet,View,Image } from 'react-native'
import { Avatar } from '@rneui/base'
import { Text } from 'react-native'
import { LOCAL_STORAGE } from '../../services'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'



const Stack = createNativeStackNavigator()
const TopBar = createMaterialTopTabNavigator()
function AuthNavigation() {
//------------Check if the user is logged in using Redux state------------------//

    const isUserLoggedIN = useSelector((state) => state.authReducers.isUserLogin)
    const dispatch = useDispatch()
    
//----------Use effect to check login status on component mount-------------------//
    useEffect(() => {
        checkLoginStatus()
    }, [])

//---------------Function to check the login status using AsyncStorage--------------//

    const checkLoginStatus = () => {
        AsyncStorage.getItem(STORAGE_KEYS.isUserLoggedIn).then((status) => {
            if (status == null || status == undefined) {
                dispatch(changeLoginStatus(false))
            } else {
                if (status == '1') {
                    dispatch(changeLoginStatus(true))
                } else {
                    dispatch(changeLoginStatus(false))
                }
            }
            
        }).catch(() => {

        })
        

    }
    
//------------------ Navigation stack for authentication screens------------------//
    const authStack = () => {
        return (
            <Stack.Navigator initialRouteName={ROUTES.LOGIN}>
                <Stack.Screen name={ROUTES.LOGIN} component={LOGIN} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.SIGNUP} component={SIGNUP} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.FORGOT} component={FORGOT} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.OTP} component={OTP} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.SET_NEW_PASSWORD} component={SetNewPassword} options={{ headerShown: false }} />

            </Stack.Navigator>
        )
    }
//---------------------------Navigation stack for dashboard screens---------------------//
    const dashboardStack = () => {
 
        return (
            <Stack.Navigator initialRouteName={ROUTES.DRAWER}>
                <Stack.Screen name={ROUTES.DRAWER} component={DrawerNavigation} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.PROFILE} component={PROFILE} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.CONFIGLINEUP} component={CONFIGLINEUP} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.PAYMENT} component={PAYMENT} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.LINEUP_LIST_TO_CHALLANGES} component={LINEUP_LIST_TO_CHALLANGES} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.LINEUP} component={LINEUP} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.COMPLETED_GAME_DETAILS} component={COMPLETED_GAME_DETAILS} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.LEADER_BOARD_LIST} component={LEADER_BOARD_LIST} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.CONFIRMPASSWORD} component={CONFIRMPASSWORD} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.ADD_FUND} component={ADD_FUND} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.WITHDRAW_FUND} component={WITHDRAW_FUND} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.TRANSACTION_HISTORY} component={TRANSACTION_HISTORY} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.WEBVIEW} component={WEBVIEW_SCREEN} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.TRANSACTION_STATUS} component={STATUS_SCREEN} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.QR_CODE} component={QR_SCANNER} options={{ headerShown: false }}/>
                <Stack.Screen name={ROUTES.REWARD_DETAIL} component={REWARD_DETAIL} options={{ headerShown: false }} />
                <Stack.Screen name={ROUTES.GROUP_GAMES} component={GROUP_GAMES} options={{ headerShown: false }} />

            </Stack.Navigator>
        )
    }
    if (Platform.OS === 'web') {
        const config = {
            screens: {
                Login: 'login',
                Forgot: 'forgot-password',
                SetNewPassword: 'set-new-password',
                Dashboard: {
                    path: '',
                    screens: {
                        ActiveRewards: 'active-rewards',
                        RedeemedRewards: 'redeemed-rewards',
                        QRScanner: 'qr-scanner'
                    }
                }
            }
        };
        const linking = { config };
        return (
            <NavigationContainer linking={linking}>
                {isUserLoggedIN === undefined ? <Loader /> : !isUserLoggedIN ? authWebStack() : dashboardWebStack()}
            </NavigationContainer>
        );
    } else {
        return (
            <NavigationContainer>
                {isUserLoggedIN == undefined ? <Loader /> : !isUserLoggedIN ? authStack() : dashboardStack()}
            </NavigationContainer >
        );
    }
   
}
const authWebStack = () => {
    return (
        <Stack.Navigator initialRouteName={ROUTES.LOGIN}>
            <Stack.Screen name={ROUTES.LOGIN} component={LOGIN} options={{ headerShown: false }} />
            <Stack.Screen name={ROUTES.FORGOT} component={FORGOT} options={{ headerShown: false }} />
            <Stack.Screen name={ROUTES.SET_NEW_PASSWORD} component={SetNewPassword} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
};

const dashboardWebStack = () => {
    return (
        <Stack.Navigator initialRouteName={ROUTES.REWARD}>
            <Stack.Screen name={ROUTES.REWARD} component={RewardTopBarWeb} options={{ headerShown: false }} />
            <Stack.Screen name={ROUTES.QR_CODE} component={QR_SCANNER} options={{ headerShown: false }} />
        </Stack.Navigator>
    );
};


const RewardTopBarWeb = () => {
    const dispatch = useDispatch();
    const { height, width } = useWindowDimensions();
    const isLargeScreen = width>= 768;

    const onLogoutOptionClicked = () => {
        let reminder = LOCAL_STORAGE.getItem(STORAGE_KEYS.kRemember);
        let userName = LOCAL_STORAGE.getItem(STORAGE_KEYS.kUserNameRemember);
        let password = LOCAL_STORAGE.getItem(STORAGE_KEYS.kPasswordRemember);
        LOCAL_STORAGE.clear();
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kRemember, reminder);
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kUserNameRemember, userName);
        LOCAL_STORAGE.setItem(STORAGE_KEYS.kPasswordRemember, password);
        dispatch(changeLoginStatus(false));
    };

    return (
        <View style={[styles.container]}>
            <Header onLogout={onLogoutOptionClicked} />
            <TopBar.Navigator
                screenOptions={{
                    tabBarIndicatorStyle: { backgroundColor: COLORS.topBarLabel, alignSelf: 'center' },
                    tabBarStyle:isLargeScreen? { alignItems: 'center',width:'30%' ,marginHorizontal:'auto'}:null,
                    tabBarActiveTintColor: COLORS.topBarLabel,
                    tabBarInactiveTintColor: COLORS.appColor,
                    tabBarLabelStyle: { textTransform: 'none', fontSize: 14, fontFamily: 'LATO-Medium', alignItems: 'center', textAlign:'center'},
                }}
            >
                <TopBar.Screen
                    name={ROUTES.ACTIVE_REWARD}
                    component={ACTIVE_REWARD}
                    options={{ title: APP_STRINGS.activeRewards }}
                />
                <TopBar.Screen
                    name={ROUTES.REDEEM_REWARD}
                    component={REDEEMED_REWARD}
                    options={{ title: APP_STRINGS.redeemedRewards }}
                />
            </TopBar.Navigator>
        </View>
    );
};

const Header = ({ onLogout }) => {
    return (
        <View style={styles.headerContainer}>
            <Image style={styles.logo} source={IMAGES.header} />
            <Text style={styles.title}>My Rewards</Text>
            <Avatar
                containerStyle={styles.avatarContainer}
                avatarStyle={styles.avatar}
                source={IMAGES.logout}
                size={25}
                onPress={onLogout}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        height: 70,
        backgroundColor: COLORS.appColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 25,
    },
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        
    },
    logo: {
        height: 33,
        width: 50,
    },
    title: {
        color: COLORS.white,
        fontFamily: 'LATO-Bold',
        fontSize: 18,
    },
    avatarContainer: {
        // marginRight: 20,
    },
    avatar: {
        tintColor: COLORS.white,
    },
});




export default AuthNavigation