
class FaqItem {
    constructor(resObj) {
        this.question = resObj['Question'] ;
        this.answer = resObj['Answer'];
        this.sequence = resObj['Sequencek'];
    }
}

export default FaqItem;
