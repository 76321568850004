import { EventRegister } from 'react-native-event-listeners'
import NetInfo from '@react-native-community/netinfo'
import { STORAGE_KEYS, URLS } from '../../constants'
import DeviceInfo from 'react-native-device-info'
import { TOAST_MESSAGE } from '../../utils'
import { Platform } from 'react-native'
import { LOCAL_STORAGE } from '..'
import React from 'react'

const WSErrorType = {
    SERVER: 1,
    API: 2,
    NETWORK: 3,
}

const ErrorMsg = {
    msgNoInternet: "Internet is not connected",
    msgConnectionLost: "Connection lost. Please check your network connectivity",
    msgServerError: "Something went wrong, please try again",
    msgRequestTimeOut: "Request time out, please try again",
    msgBadRequest: "Bad request. Please check your request parameters.",
    msgUnauthorized: "Unauthorized access. Please log in again.",
    msgNotFound: "Resource not found. Please log in again",
    msgInternalServerError: "Internal server error. Please log in again",
    msgServiceUnavailable: "Service temporarily unavailable. Please log in again",
    msgGatewayTimeout: "Gateway timeout. Please log in again",
    msgDefaultError: "An unexpected error occurred. Please log in again"
}

export default class ApiManager extends React.Component {

    static myInstance = null

    static getInstance() {

        if (this.myInstance === null) {
            this.myInstance = new ApiManager()
        }
        return this.myInstance
    }

    getHeader() {
        return {

            'Content-Type': 'application/json',
            'cache-control': 'no-cache',
            'Authorization': "Bearer " + LOCAL_STORAGE.getItem(STORAGE_KEYS.kAuthToken),
            'AppDevice':Platform.OS =='ios'?'ios':'android',
            'AppVersion':'1.2'

            // "device_token": LOCAL_STORAGE.getItem(STORAGE_KEYS.kDeviceToken),

        }
    }

    queryString = (obj) => {

        if (Object.keys(obj).length === 0) {
            return ''
        }

        let str = '?' + Object.keys(obj).reduce(function (a, k) {
            a.push(k + '=' + encodeURIComponent(obj[k]))
            return a
        }, []).join('&')
        return str
    }

    getMultiPartHeader() {

        return {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Authorization": "Bearer " + LOCAL_STORAGE.getItem(STORAGE_KEYS.kAuthToken),
            'AppDevice':Platform.OS =='ios'?'ios':'android',
            'AppVersion':'1.2'
        }
    }

    onGetApi = async (endPoint, request = {}, methodType = 'GET',) => new Promise((resolve, reject) => {

        NetInfo.fetch().then(state => {
            if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {
                let queryString = this.queryString(request)
                let baseUrl = URLS.BASE_URL
                console.log(baseUrl);
                
                let apiUrl = baseUrl + endPoint + queryString
                let header = this.getHeader()
                fetch(apiUrl, {
                    method: methodType,
                    headers: header
                }).then((response) => {
                    if (!response.ok) {
                        switch (response.status) {
                            case 400:
                                TOAST_MESSAGE(ErrorMsg.msgBadRequest,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgBadRequest, response.status, WSErrorType.SERVER))
                            case 401:
                                TOAST_MESSAGE(ErrorMsg.msgUnauthorized,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgUnauthorized, response.status, WSErrorType.SERVER))
                            case 404:
                                TOAST_MESSAGE(ErrorMsg.msgNotFound,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgNotFound, response.status, WSErrorType.SERVER))
                            case 500:
                                console.log('errir ',response);
                                TOAST_MESSAGE(ErrorMsg.msgInternalServerError,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgInternalServerError, response.status, WSErrorType.SERVER))
                            case 503:
                                TOAST_MESSAGE(ErrorMsg.msgServiceUnavailable,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgServiceUnavailable, response.status, WSErrorType.SERVER))
                            case 504:
                                TOAST_MESSAGE(ErrorMsg.msgGatewayTimeout,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgGatewayTimeout, response.status, WSErrorType.SERVER))
                            default:
                                TOAST_MESSAGE(ErrorMsg.msgDefaultError,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgDefaultError, response.status, WSErrorType.SERVER))
                        }
                    }
                    response.json().then((json) => {
                        return resolve(new WSResult(response, json, 1))
                    }).catch((error) => {

                        console.log('ERROR:IN JSON----', error, error.code)
                        // TOAST_MESSAGE(ErrorMsg.msgServerError,'1')
                        return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
                    })

                }).catch((error) => {
                    console.log('ERROR:IN APIIII----', error)
                    // TOAST_MESSAGE(ErrorMsg.msgServerError,'1')
                    return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.SERVER))
                })

            } else {
                TOAST_MESSAGE(ErrorMsg.msgNoInternet,'1')
                return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API))

            }
        })
    })

    onPostApi = async (endPoint, requestData = {}, methodType = 'POST', isMultipart = false) => new Promise((resolve, reject) => {

        NetInfo.fetch().then(state => {
            if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

                var baseUrl = URLS.BASE_URL

                let newHeader = this.getMultiPartHeader()
                let jsonData = JSON.stringify(requestData)
                fetch(baseUrl + endPoint, {
                    method: methodType,
                    headers: newHeader,
                    body: jsonData,

                }).then((response) => {
                    if (!response.ok) {
                        switch (response.status) {
                            case 400:
                                TOAST_MESSAGE(ErrorMsg.changeLoginStatus,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgBadRequest, response.status, WSErrorType.SERVER))
                            case 401:
                                TOAST_MESSAGE(ErrorMsg.msgUnauthorized,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgUnauthorized, response.status, WSErrorType.SERVER))
                            case 404:
                                TOAST_MESSAGE(ErrorMsg.msgNotFound,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgNotFound, response.status, WSErrorType.SERVER))
                            case 500:
                                console.log('errir ',response);
                                TOAST_MESSAGE(ErrorMsg.msgInternalServerError,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgInternalServerError, response.status, WSErrorType.SERVER))
                            case 503:
                                TOAST_MESSAGE(ErrorMsg.msgServiceUnavailable,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgServiceUnavailable, response.status, WSErrorType.SERVER))
                            case 504:
                                TOAST_MESSAGE(ErrorMsg.msgGatewayTimeout,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgGatewayTimeout, response.status, WSErrorType.SERVER))
                            default:
                                TOAST_MESSAGE(ErrorMsg.msgDefaultError,'1')
                                EventRegister.emit('unauthorized', '')
                                return reject(new WSError(ErrorMsg.msgDefaultError, response.status, WSErrorType.SERVER))
                        }
                    }

                    response.json().then((json) => {
                        return resolve(new WSResult(response, json, 1))
                    }).catch((error) => {
                        console.log('ERROR:IN JSON----', error, error.code)
                        // TOAST_MESSAGE(ErrorMsg.msgServerError,'1')
                        return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
                    })

                }).catch((error) => {
                    console.log('ERROR IN API ', error)

                    // TOAST_MESSAGE(ErrorMsg.msgServerError,'1')
                    return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
                })
            } else {
                TOAST_MESSAGE(ErrorMsg.msgNoInternet,'1')
                return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API))
            }
        })
    })

}

class WSError {

    constructor(
        msg = '',
        code = 0,
        type = WSErrorType.API
    ) {
        this.msg = msg
        this.code = code
        this.type = type
    }
}

class WSResult {

    constructor(
        responce = Response,
        json = JSON,
        code = 0,
    ) {
        this.responce = responce
        this.json = json
        this.code = code
    }

}