import { Text, TouchableOpacity, View } from "react-native"
import styles from "../../components/gameComponent/index.style"
import { COLORS } from "../../../constants"

const LeaderBoardGameDetailsCell = (props) => {


    const onPressCell = () => {
        if (props.onPress != undefined) {
            props.onPress(props.data)
        }
    }
//--------------------Update Position color----------------//
    const getPositionColor = (position) => {

        if (position == 'Up') {
            return COLORS.green
        }
        return COLORS.redCancelColor

    }

    return (
        
        <TouchableOpacity activeOpacity={1} onPress={onPressCell} key={props.data.symbol} style={styles.innerView}>
            <View style={styles.titleView}>
                <Text style={styles.title}>{props.data.symbol}</Text>
            </View>
            <View style={styles.lineView} />

            {/* second Views */}
            <View style={styles.secondView}>

                {/* Second Views TITLE   */}
                <View style={{ flexDirection: 'row', }}>
                    <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                        <Text style={styles.innerHeadings}>Spend</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.innerHeadings}>Open</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text style={styles.innerHeadings}>Position</Text>
                    </View>
                </View>

                {/* Second Views Values   */}
                <View style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                        <Text style={styles.entryText}>${props.data.spend}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.entryText}>{parseFloat(props.data.open).toFixed(2)}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text style={[styles.entryText, { color: getPositionColor(props.data.position) }]}>{props.data.position}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.secondView}>

                {/* Second Views TITLE   */}
                <View style={{ flexDirection: 'row', }}>
                    <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                        <Text style={styles.innerHeadings}>Current</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.innerHeadings}>Delta</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text style={styles.innerHeadings}>Total</Text>
                    </View>
                </View>

                {/* Second Views Values   */}
                <View style={{ flexDirection: 'row', paddingTop: 10 }}>
                    <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                        <Text style={styles.entryText}>${parseFloat(props.data.current).toFixed(2)}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'center' }}>
                        <Text style={styles.entryText}>{props.data.delta}</Text>
                    </View>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text style={styles.entryText}>{parseFloat(props.data.total).toFixed(2)}</Text>
                    </View>
                </View>
            </View>
        </TouchableOpacity>

    )
}
export default LeaderBoardGameDetailsCell;