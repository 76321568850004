import { View } from "react-native";
import { WebView } from 'react-native-webview'
import urls from "../../../../constants/urls";
import { LinearProgress } from '@rneui/themed'
import { useState } from "react";
import { APP_STYLES } from "../../../../utils";


function PrivacyPolicy({ navigation }) {
    const [progressValue, setProgressValue] = useState(0)
    return (
        <View style={APP_STYLES.mainContainer}>
            {progressValue < 1 && <LinearProgress value={progressValue} />}
            <WebView source={{ uri: urls.PRIVACY_POLICY }} style={APP_STYLES.mainContainer}
                onLoadProgress={({ nativeEvent }) => {
                    setProgressValue(nativeEvent.progress)
                }} />
        </View>
    )
}
export default PrivacyPolicy;