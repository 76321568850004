import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
    isUserLogin: undefined,
    isUserSubscribed:undefined
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeLoginStatus(state, payload) {
            state.isUserLogin = payload.payload
        },
        changeSubscriptionStatus(state, payload) {
            state.isUserSubscribed = payload.payload
        },
        
    },
})

export const { changeLoginStatus ,changeSubscriptionStatus } = authSlice.actions

export default authSlice.reducer
