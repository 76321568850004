import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { moderateScale, scale } from "react-native-size-matters";
export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.lighterBlue,
        paddingTop: 15,
        // paddingBottom: 20
    },
    innerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        alignItems:'center',
    },
    subView:{
        flex:1,
       
    },
    searchBarContainer: {
        borderRadius: 8,
        height: (30).scaleHeight(),
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
    },
    searchBarInput: {
        backgroundColor: COLORS.white,
        borderRadius: 8,
    },
    inputStyle: {
        fontSize: (10).scaleWidth(),
        fontFamily: LATO.Medium,
        color: COLORS.appGrayColor,
        marginLeft: 0,
    },
    buttonContainer: {
        flex: 1,      
        marginLeft:(20).scaleWidth(),       
        marginRight:6
    },
    buttonStyle: {
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        color: COLORS.white,
        fontFamily: LATO.Bold,
        fontSize: (14).scaleWidth(),
        paddingVertical:(10).scaleWidth(),
    },
    flatlist:{ 
        flex: 1, 
        marginBottom: (40).scaleHeight() 
    },
    contentContainerStyle:{ 
        flexGrow: 1 
    },
    titleStyle: {
        color: COLORS.appColor,
        marginVertical: (10).scaleHeight(),
        marginLeft: 16,
        fontSize: (18).scaleWidth(),
        fontFamily: LATO.Heavy
    },
    titleContainer:{
        flexDirection:'row',
        alignItems:'center',
       
    },
    lengthView: {
        borderRadius:(50).scaleWidth(),
        backgroundColor: COLORS.skyBlue,
        width: moderateScale(22),
        height:moderateScale(22),
        marginLeft: 2,
        alignItems:'center',
        justifyContent:'center',
    },
    lengthStyle: {
        color: COLORS.white,
        fontSize: moderateScale(14),
        
        fontFamily: LATO.Heavy,
        textAlign:'center'
    }
})