import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS } from '../../../../constants'
import { LATO } from '../../../../constants/fonts'
import { AUTHHEADER } from '../../../components'
import { CHALLENGE } from '..'
export default function GroupGames({route,navigation}) {
    
  return (
    <SafeAreaView edges={['top','left','right']} style={styles.mainView}>
        <AUTHHEADER mainView={styles.authMainView} isBack={true} navigation={navigation} title={'Groups'} />
        <View style={{flex:1,backgroundColor:COLORS.white}}>
            <Text style={styles.title}>{route.params.groupName}</Text>
            <CHALLENGE screen={'myGroup'} groupID={route.params.groupID}/>
        </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
    mainView:{
        flex:1,
        backgroundColor:COLORS.appColor
    },
    authMainView:{
        backgroundColor:COLORS.appColor,
        alignItems:'center'
    },
    title:{
        fontFamily:LATO.Black,
        fontSize:(15).scaleWidth(),
        padding:(10).scaleWidth()
    },
    reward:{
        fontFamily:LATO.Semibold,
        fontSize:(12).scaleWidth(),
        color:COLORS._60PerGrey,
        paddingTop:10
    }
})