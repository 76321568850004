import React, { useRef } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import WebView from 'react-native-webview'
import { ROUTES } from '../../../../constants'
import { APP_STYLES, TOAST_MESSAGE } from '../../../../utils'


export default function WebViewScreen({route,navigation}) {
    const webviewRef = useRef()


    const onNavigationStateChange = (webViewState) => {
      console.log(webViewState,'webViewState');
     if (webViewState.url.includes("https://dtc-payment-capture.azurewebsites.net/PaymentSuccess.aspx")) {
        if(webViewState.loading==false) navigation.navigate(ROUTES.TRANSACTION_STATUS)
      }
      if(webViewState.url.includes('https://dtc-payment-capture.azurewebsites.net/cancelUrl')){
        TOAST_MESSAGE('Transaction Cancelled','1')
        navigation.navigate(ROUTES.ADD_FUND)
      }
    }
    
    return (
      <SafeAreaView style={APP_STYLES.mainContainer}>
        <WebView
          ref={webviewRef}
          scalesPageToFit={false}
          mixedContentMode="compatibility"
          onNavigationStateChange={onNavigationStateChange}
          style={APP_STYLES.mainContainer}
          source={{
            uri: route.params.url,
          }}
        />
     
      </SafeAreaView>
    );
}

