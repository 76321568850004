
export default {

    Black: 'Overpass-Black',
    BlackItalic: 'Overpass-BlackItalic',
    Bold: 'Overpass-Bold',
    BoldItalic: 'Overpass-BoldItalic',
    Hairline: 'Overpass-Hairline',
    HairlineItalic: 'Overpass-HairlineItalic',
    Heavy: 'Overpass-ExtraBold',
    HeavyItalic: 'Overpass-ExtraBoldItalic',
    Italic: 'Overpass-Italic',
    Light: 'Overpass-Light',
    LightItalic: 'Overpass-LightItalic',
    Medium: 'Overpass-Medium',
    MediumItalic: 'Overpass-MediumItalic',
    Regular: 'Overpass-Regular',
    Semibold: 'Overpass-Semibold',
    SemiboldItalic: 'Overpass-SemiboldItalic',
    Thin: 'Overpass-Thin',
    ThinItalic: 'Overpass-ThinItalic',
};