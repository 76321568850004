import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export default StyleSheet.create({

    innerView: {
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 6,
        shadowOpacity: 0.1,
        shadowColor:COLORS.shadowColor,
        elevation: 4,
        borderRadius: (10).scaleWidth(),
        marginHorizontal: (12).scaleWidth(),
        marginBottom: (5).scaleHeight(),
        backgroundColor: COLORS.white,
        marginTop:(5).scaleHeight(),
       
    },
    cellTypeView:{ 
        flexDirection: 'row', 
        alignItems: 'center' 
    },
    titleView: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-between",
        paddingHorizontal: (10).scaleWidth(),
        paddingVertical: (8).scaleHeight(),
    },

    secondView: {

        paddingHorizontal: (10).scaleWidth(),
        paddingTop: (6).scaleHeight(),
        paddingVertical: (10).scaleHeight(),
    },
    secondValueView:{ 
        flexDirection: 'row', 
        paddingTop: 10 
    },
    gameIDView:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        paddingHorizontal: (10).scaleWidth(),
        paddingVertical: (10).scaleHeight(),
        flex:1
    },
    image:{
        width:'100%',
        // height:(100).scaleHeight(),
        aspectRatio:103/30
    },
    secInnerView:{ 
        flex: 1, 
        justifyContent: 'flex-start' 
    },
    totalPriceView:{ 
        flex: 1, 
        justifyContent: 'flex-start' 
    },
    thirdView: {

        flex: 1,
        backgroundColor: COLORS.lightBlueColor,
        flexDirection: 'row',
        borderBottomLeftRadius: (8).scaleWidth(),
        borderBottomRightRadius: (8).scaleWidth(),
        justifyContent: "space-between",
        paddingHorizontal: (10).scaleWidth(),
        borderColor: COLORS.skyBlue,
        paddingVertical: (5).scaleHeight(),
        borderBottomColor: COLORS.lightBlueColor,
        borderBottomWidth: (4).scaleHeight(),

    },

    title: {
        fontFamily: LATO.Bold,
        fontSize: (16).scaleWidth(),
        color: COLORS.appColor,
        flex:1

    },

    prizeText: {
        fontFamily: LATO.Bold,
        fontSize: (19).scaleWidth(),
        color: COLORS.appColor,
        marginTop: (5).scaleWidth(),

    },
    innerHeadingsView:{ 
        flex: 1, 
        alignItems: 'flex-end' 
    },
    innerHeadings: {
        color: COLORS.lightGrayColor,
        fontSize: (13).scaleWidth(),
        fontFamily: LATO.Regular,

    },
    markIcon:{
        paddingLeft:(10).scaleWidth()
        // paddingHorizontal:(10).scaleWidth()
    },

    entryText: {
        marginTop: (5).scaleWidth(),
        fontFamily: LATO.Bold,
        fontSize: (14).scaleHeight(),
        color: COLORS.appColor,
    },
    entriesView:{
        flex:1,
        alignItems:'center',
    },

    entryView: {
        paddingHorizontal: (16).scaleWidth(),
        paddingVertical: (6).scaleHeight(),
        borderRadius: 5,
    },

    entry: {
        textAlign: 'center',
        fontFamily: LATO.Bold,
        fontSize: (14).scaleWidth()

    },
    gameID:{
        fontFamily:LATO.Bold,
        color:COLORS.black,
        fontSize:(13).scaleWidth(),        
    },
    lineView: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.appDividerColor,
    },
    dateText: {
        color: COLORS.dateColor,
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Semibold,
        flex:1

    },
    hoursView: {
        justifyContent: "flex-end",
        flexDirection: 'row',
        borderRadius: 4,
        borderColor: "rgba(40, 126, 173, 0.3)",
        borderWidth: 1,
        backgroundColor: COLORS.white,
        alignItems: 'center',
        maxHeight:(30).scaleHeight()

    },


    iconTextView: {
        backgroundColor: COLORS.topBarLabel,
        borderRadius: 3,
        paddingHorizontal: (5).scaleWidth(),
        paddingVertical: (2).scaleHeight(),
        
    },


    hour: {
        backgroundColor: COLORS.white,
        color: COLORS.dateText,
        fontSize: (10).scaleWidth(),
        fontFamily: LATO.Semibold,
        paddingHorizontal: (5).scaleWidth()
    },

    imageView: {
        flexDirection: 'row',
        paddingHorizontal: (4).scaleWidth(),
        alignItems: 'center',
        flex:1
    },

    circleView: {
        width: (10).scaleWidth(),
        height: (10).scaleWidth(),
        borderRadius: (5).scaleWidth(),
        marginRight: (5).scaleWidth(),
    },
    textCellType: {
        fontSize: (11).scaleWidth(),
        fontFamily: LATO.Semibold,

    },
    rewardGameId:{
        fontFamily:LATO.Bold,
        fontSize:(12).scaleWidth(),
        color:COLORS.black
    },
    gameDetail:{
        fontFamily:LATO.Medium,
        color:`rgba(6, 0, 44, 0.5)`,
        fontSize:(13).scaleWidth(),

    }

})