import { Text, View } from "react-native";
import { styles } from "./index.style";
import { useSelector } from "react-redux";
import { TOAST_MESSAGE } from "../../../utils";
function LineUpHeader() {

    const selectedStockData = useSelector((state) => state.lineupReducers.selectedStockData);
//-------------------Method to Calculate the Monet Added----------------//
    const calculateAddedMoney = () => {
        
        return selectedStockData.reduce((acc, next) => {

            let money = 0
            if (next.money) {
                money = next.money.replace('$', '')
                money = money.replace('-', '');
                money = String(money).replace(/,/g, "");
            }
          
            return acc + +money
        }, 0)

    }

    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <Text style={styles.textStyle}>Max. Spend/Stocks</Text>
                <Text style={styles.textStyle}>Stocks</Text>
                <Text style={styles.textStyle}>Remaining Bankroll</Text>
            </View>
            <View style={styles.innerContainer}>
                <Text style={styles.innerTextStyle}>$150,000</Text>
                <View style={[styles.stocksContainer, { marginLeft: 42, }]}>
                    <Text style={[styles.innerTextStyle, { marginLeft: 5 }]}>{selectedStockData.length}/8</Text>
                </View>
                <View style={styles.remainingContainer}>
                    <Text style={styles.innerTextStyle}>${(1000000 - calculateAddedMoney()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                </View>
            </View>
        </View>
    )
}
export default LineUpHeader;