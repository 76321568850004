import { View, Text, TouchableOpacity, FlatList, Image, Dimensions, AppState } from "react-native"
import { getActiveGamesAPI, getLeaderBoard } from "../../../controllers/gamesController"
import { styles } from "../../containers/dashboardContainers/leaderboard/index.style"
import { updateGameID,updateIndex } from "../../../redux/reducers/lineupReducer"
import {getLeaderboardBannerAPI} from '../../../controllers/leaderboardBanner'
import { COLORS, ERROR_STRING, ROUTES, URLS } from "../../../constants"
import { FacebookLoader } from "react-native-easy-content-loader"
import LeaderBoardModel from "../../../models/leaderboardModel"
import TableFooterView from "../../components/tableFooterView"
import AppBottomSheet from "../../components/appBottomSheet"
import React, { useEffect, useRef, useState } from "react"
import { moderateScale } from "react-native-size-matters"
import { LEADERBOARDCOMPONENT } from '../../components'
import { useIsFocused } from "@react-navigation/native"
import { useDispatch, useSelector } from "react-redux"
import NetInfo from '@react-native-community/netinfo'
import { FontAwesome } from "@expo/vector-icons"
import GameDetailPopUp from "../gameDetailPopUp"
import { TOAST_MESSAGE } from "../../../utils"
const signalR = require("@microsoft/signalr")



const connection = new signalR.HubConnectionBuilder()
.withUrl(URLS.SIGNAL_R)
.configureLogging(signalR.LogLevel.Information)
.withAutomaticReconnect()
.build();

function LeaderBoardList(props) {
    const { navigation, screenName, } = props
    const [isVisible, setVisible] = useState()
    const [activeGames, setActiveGames] = useState([])
    const [leaderBoardList, setLeaderBoardList] = useState([])
    const [isShimmer, setShimmer] = useState(false)
    const [showPopup, setShowPopup]=useState(false)
    const [gameTitle,setGameTitle] = useState('')
    const [gameDescription,setGameDescription] = useState()
    const [bannerImage,setBannerImage] = useState('')
    const [selectedGame,setSelectedGame] = useState('')

    //---------------Global States used in the component--------------------//
    const gameID = useSelector((state) => state.lineupReducers.gameID)
    const indexx = useSelector((state) => state.lineupReducers.index)
  
    //-----------------Hook to check if the screen is in focus--------//
    const isFocused = useIsFocused()

    //-----------------Hook to dispatch redux actions-----------------//
    const dispatch = useDispatch()
    //-----------------State to manage the app state (background/foreground)-----------------//
    const appState = useRef(AppState.currentState);

    const { width } = Dimensions.get('screen')
    //-----------------Handle the app State when the screen comes in foreground-----------------//
    useEffect(() => {
        const subscription = AppState.addEventListener('change', nextAppState => {  
            if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
                console.log(connection.state,'connection.state');
                // Alert.alert(connection.state)
                if(connection.state === signalR.HubConnectionState.Disconnected){
                    getRunningGamesWithoutConnection()
                    reconnect();
                    console.log('App has come to the foreground! from background');
                    console.log('Connection not working')
                }
                else if(connection.state === signalR.HubConnectionState.Reconnecting){
                    console.log('Connection Reconnecting');
                    connection.off('updateleaderboard')     
                    getRunningGamesWithoutConnection()
                    connection.stop().then(() => {
                        reconnect();
                    });    
                }
            }
            appState.current = nextAppState;
            console.log(isFocused,appState); 
            console.log('AppState', appState.current);
        })
        return () => {
            subscription.remove();
        }
    }, [])
    //-----------------Handle the reconnect function on connection lost-----------------//

    const reconnect = () => {
        console.log('Attempting to reconnect to SignalR hub...');  
        NetInfo.fetch().then((state)=>{
            console.log(state.isConnected,'Inetrnet state');
            if(state.isConnected){       
                if (connection.state === signalR.HubConnectionState.Disconnected) {
                    console.log('Connection lost');
                    connection.start().then(() => {
                        connection.off('updateLeaderboard');
                        console.log('Connection to SignalR hub established.')
                        getRunningGames()       
                    })
                    .catch((error) => {
                        setShimmer(false)
                        setTimeout(reconnect, 2000)
                        console.error('Error establishing connection to SignalR hub:', error);
                    });
                }   
                else if (connection.state === signalR.HubConnectionState.Reconnecting) {
                    console.log('Connection Reconnecting')
                    getRunningGamesWithoutConnection()
                    connection.off('updateLeaderboard');
                    connection.stop().then(() => {
                        reconnect()
                    })
                }
                else{
                    console.log('Connection working....');
                }
            }
            else{
                setShimmer(false)
                connection.off('updateLeaderboard');
                TOAST_MESSAGE(ERROR_STRING.noInternet,'1')
                // setTimeout(reconnect,8000)
            }}).catch((err)=>{
                console.log('Error in fetching',err);
                setShimmer(false)
            })
        
    }
    //-------------------Api Calls according to the screens-------------//
    useEffect(() => {
          if (isFocused) {         
                setShimmer(true) 
                getRunningGamesWithoutConnection()
                NetInfo.fetch().then((state)=>{
                    console.log(state.isConnected,'Inetrnet state');
                    if(state.isConnected){
                        if (connection.state === signalR.HubConnectionState.Disconnected) {
                            console.log('Connectioning foe first time');
                            connection.off('updateLeaderboard');
                            connection.start().then(() => {
                                console.log('Connection to SignalR hub established.')
                                // setShimmer(true)
                                getRunningGames()       
                            })
                            .catch((error) => {
                                setShimmer(false)
                                setTimeout(reconnect, 2000)
                                console.error('Error establishing connection to SignalR hub:', error);
                            });
                        }
                    }
                    else{
                        setShimmer(false)
                        connection.off('updateLeaderboard');
                        TOAST_MESSAGE(ERROR_STRING.noInternet,'1')
                        // setTimeout(reconnect,8000)
                }}).catch((err)=>{
                        console.log('Error in fetching',err);
                        setShimmer(false)
                })                  
        } 
        else{
            console.log('Disconnecting from SignalR hub.');
            connection.off('updateLeaderboard');
        }
      
      // Clean up: Stop the connection when component unmounts
      return () => {
        console.log('Disconnecting from SignalR hub.');
        connection.off('updateLeaderboard');
        connection.stop();
      };
       
      }, [isFocused])

      const getLoaderBoardListWithoutConnection = (gameID) => {
          let requestParameter = {
              GameID: gameID
          }
          console.log(gameID,'gameID in getLoaderBoardListWithoutConnection ');
          getLeaderBoard(requestParameter).then((leaderBoard) => {
            console.log(leaderBoard,'leaderBoard');
              setLeaderBoardList(leaderBoard)
          }).catch((error) => {
              
          }).finally(() => {     
              setShimmer(false)
          })
      }
      const getRunningGamesWithoutConnection = () => {
        setShimmer(true)
        getActiveGamesAPI().then((response) => { 
            setActiveGames(response)
            if (response.length > 0) {
                console.log(indexx,'indexxindexx');
                getLoaderBoardListWithoutConnection(response[indexx].gameID)
                getLeaderboardBanner(response[indexx].gameID)
                setSelectedGame(response[indexx].description)
                dispatch(updateGameID({id:response[indexx].gameID}))
                dispatch(updateIndex({index:indexx}))
            } else {
                setLeaderBoardList([])
                setShimmer(false)
            }

        }).catch((error) => {
            setShimmer(false)

        }).finally(() => {
        })
    }
//------------------Get Active Games API-------------------------// 

    const getRunningGames = () => {
       
        getActiveGamesAPI().then((response) => {
            setActiveGames(response)
            if (response.length > 0) {
                setSelectedGame(response[indexx].description)
                getLoaderBoardList(response[indexx].gameID)
                getLeaderboardBanner(response[indexx].gameID)
            } else {
                setLeaderBoardList([])
                setShimmer(false)
            }
        }).catch((error) => {
            console.log(error,'error in active games');
            setShimmer(false)
        }).finally(() => {
          
        })
    }
    //------------------Leaderboard List Api-------------------//
    const getLoaderBoardList = async (id) => {
        if (connection.state === signalR.HubConnectionState.Connected) {
            await connection.invoke("SendGameId", id)
                .then(() => {
                  
                    console.log('Invoked SendGameId');
                })
                .catch((err) => {
                    console.log('Error invoking SendGameId:', err);
                });
                connection.off('updateLeaderboard')
                connection.on('updateLeaderboard', (data) => {
                console.log(data.d.length);
                if (data.d.length == 0) {
                    setLeaderBoardList([]);
                    setShimmer(false);
                } else {
                    console.log('Leaderboard updated:', data, id);
                    let json = JSON.parse(data.d);
                    let tableRecord = json["Table"];
                    let leaderBoardRecord = [];
                    if (tableRecord != undefined) {
                        tableRecord.map((val) => {
                            leaderBoardRecord.push(new LeaderBoardModel().initWithLeaderBoardRes(val));
                        });
                        setLeaderBoardList(leaderBoardRecord);
                        setShimmer(false);
                    }
                }
            })
        } else {
            console.log('Connection lost.');
            reconnect();
        }
    };
     //------------------Leaderboard Banner API CALL-------------------//
    const getLeaderboardBanner = (id) => {
        getLeaderboardBannerAPI(id).then((res)=>{
            if(res.length > 0){
                let parts =  res[0].gameDetail.length==0?'':res[0].gameDetail.split(':')
                let title = res[0].gameDetail.length==0?'':parts[0].trim()
                let description =  res[0].gameDetail.length==0?'':parts.slice(1).join(':').trim()
                setGameTitle(title)
                setGameDescription(description)
                setBannerImage(res[0].leaderboardBanner)   
            }
        }).catch((err)=>{
            console.log(err,'Error');
        }).finally(()=>{

        })
      }

//----------Navigate to game detail-------------------//
    const didClickUser = (item) => {
        let temp =  gameID
        if (screenName !== 'Completed') {
            temp = activeGames[indexx].gameID
        }
        navigation.navigate(ROUTES.COMPLETED_GAME_DETAILS, { gameID: temp, playerId: item.playerID })
    }
//-----------------------------On Click of Banner file show the game details-----------------//
    const onPressImage = () => {
        
        if(gameTitle.length>0||gameDescription.length>0){ 
            setShowPopup(true)
        }
    }
//------------------Render LEADERBOARD COMPONENT-------------------//
    const renderItem = ({ item ,index}) => {
        return (
            <LEADERBOARDCOMPONENT onPress={didClickUser} itemData={item} length={leaderBoardList.length}index={index}/>
        )
    }
//------------------On Change of the active game -------------------//

    const onChangeActiveGame = async(item, index) => {
        setVisible(false)
        setShimmer(true)
        setBannerImage('')
        connection.off('updateleaderboard')           
        dispatch(updateGameID({id:item.gameID}))
        dispatch(updateIndex({index:index}))
        setSelectedGame(item.description)
        getLoaderBoardListWithoutConnection(item.gameID)
        getLoaderBoardList(item.gameID)
        getLeaderboardBanner(item.gameID)

    }
//---------------Render Shimmer----------------------//
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {Array(10).fill(0).map((i) => {
                    return <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                })}
            </View>
        )
    }
//-----------------Component Rendering-------------------------//
    return (
        <View style={styles.mainView}>
                <View style={styles.upperView}>
                    <View style={{flex:1}}>
                        <Text style={[styles.titleText,{flex:1}]}>Leaderboard</Text>
                    </View>
                {leaderBoardList.length > 0 && activeGames.length > 0  &&
                    <TouchableOpacity onPress={() => isVisible ? setVisible(false) : setVisible(true)} style={styles.filterContainer}>
                        <Text style={styles.filterText}>{activeGames.length > 0 ? selectedGame.length > 30 ? selectedGame.substring(0,15)+'...':  selectedGame: ''}</Text>
                        <FontAwesome name={isVisible ? "angle-down" : "angle-up"} size={24} color={COLORS.lightGrayColor} />
                    </TouchableOpacity>}
                </View>
                {bannerImage && 
                    <TouchableOpacity onPress={onPressImage}>
                        <Image source={{uri:bannerImage}} style={{ width: width,height:(45).scaleHeight() }} resizeMode='contain' />
                    </TouchableOpacity>
                }
                {leaderBoardList.length > 0 &&
                    < View style={styles.subTitleContainer}>
                        <Text style={[styles.subTitleText]}>RANK</Text>
                        <Text style={[styles.subTitleText1]}>USER NAME</Text>
                        <Text style={styles.subTitleText}>SCORE</Text>
                    </View>
                }
            {
                isShimmer ? renderShimmer() :
                    <FlatList
                        data={leaderBoardList}
                        keyExtractor={(item) => item.id}
                        renderItem={renderItem}
                        style={{marginBottom:moderateScale(55),flex:1}}
                        ListEmptyComponent={<TableFooterView message={'Leaderboard not available'} />}
                    />
                    
            }

            {isVisible && <AppBottomSheet onChooseOption={onChangeActiveGame} optionList={activeGames} onClose={() => setVisible(false)} />}
            {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
              />
            )}

        </View >
    )
}

export default LeaderBoardList