export default class TransactionModel {
    constructor(
      amount = '',
      batchStatus = '',
      grossAmount = '',
      netAmount = '',
      paypalFee = '',
      transactionDateTime = "",
      transactionId = "",
      transactionStatus = "",
      data = '',
      message = '',
      status = ''
    ) {
      this.amount = amount;
      this.batchStatus = batchStatus;
      this.grossAmount = grossAmount;
      this.netAmount = netAmount;
      this.paypalFee = paypalFee;
      this.transactionDateTime = transactionDateTime;
      this.transactionId = transactionId;
      this.transactionStatus = transactionStatus;
      this.data = data;
      this.message = message;
      this.status = status;
    }
  
    initWithJSON(json) {
      this.amount = json["Amount"] || "";
      this.batchStatus = json["BatchStatus"] || "";
      this.grossAmount = json["GrossAmount"] || "";
      this.netAmount = json["NetAmount"] || "";
      this.paypalFee = json["PaypalFee"] || "";
      this.transactionDateTime = json["TransactionDateTime"] || "";
      this.transactionId = json["TransactionId"] || "";
      this.transactionStatus = json["TransactionStatus"] || "";
      this.data = json['Data']||""
      this.message = json["Message"] || "";
      this.status = json["Status"] || "";
      return this;
    }
  }
  