export default {
    LOGIN: 'Login',
    SIGNUP: 'SignUp',
    FORGOT: 'Forgot',
    CONFIRMPASSWORD: 'ConfirmPassword',
    HOME: 'Home',
    DRAWER: 'DrawerNavigation',
    PROFILE: 'Profile',
    WALLET: 'Wallet',
    CHALLENGE: 'Challenge',
    LINEUP: 'LineUp',
    LEADERBOARD: 'LeaderBorad',
    FREQUENTQUESTION: 'FAQs',
    PRIVACYPOLICY: 'Privacy Policy',
    TERMCONDITION: 'Terms & Conditions',
    PENDINGGAME: 'PendingGame',
    RUNNINGGAME: 'RunningGame',
    COMPLETEDGAME: 'CompletedGame',
    CANCELLEDGAME: 'CancelledGame',
    CONFIGLINEUP: 'ConfigLineup',
    CHALLENGEREGISTER: 'ChallengeRegister',
    GROUP:'Group',
    GROUP_GAMES:'GROUP_GAMES',
    OTP: 'OTP',
    PAYMENT: 'Payment',
    CARDDETAILS: 'CardDetails',
    SET_NEW_PASSWORD: 'SetNewPassword',
    LINEUP_LIST_TO_CHALLANGES: 'LINEUP_LIST_TO_CHALLANGES',
    COMPLETED_GAME_DETAILS: 'COMPLETED_GAME_DETAILS',
    LEADER_BOARD_LIST: 'LEADER_BOARD_LIST',
    ADD_FUND: 'ADD_FUND',
    WITHDRAW_FUND:'WITHDRAW_FUND',
    WEBVIEW:'WEBVIEW',
    TRANSACTION_STATUS:'Status',
    TRANSACTION_HISTORY:'TRANSACTION_HISTORY',
    HOME_STACK: 'home_stack',
    LINEUP_STACK: 'lineup_stack',
    CHALLENGE_STACK: 'challenge_stack',
    LEADER_BOARD_STACK: 'leader_board_stack',
    CUSTOM_HOME_STACK:'homeStack',
    CUSTOM_LEADERBOARD_STACK:'leaderboardStack',
    CUSTOM_CHALLENGE_STACK:'challengeStack',
    CUSTOM_LINEUP_STACK:'lineupStack',
    ACTIVE_REWARD : 'activeReward',
    REWARD : 'reward',
    REDEEM_REWARD:'redeemedReward',
    QR_CODE:'QRCode',
    REWARD_DETAIL:'REWARD_DETAIL',
    MY_GROUP:'MyGroups',
    AVAILABLE_GROUP:'AvailableGroups'

};