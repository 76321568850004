import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export const styles = StyleSheet.create({
    container:{
        paddingHorizontal:15,
        paddingVertical:10,
        borderTopColor:'rgba(255, 255, 255, 0.2)',
        borderTopWidth:1
    },
    innerContainer:{
        flexDirection:'row',
        justifyContent:'space-between',
        paddingTop:5
    },
    textStyle:{
        color:COLORS.lightWhite,
        fontFamily:LATO.Medium,
        fontSize:(13).scaleWidth(),
        flexShrink:1
    },
    innerTextStyle:{
        color:COLORS.white,
        fontFamily:LATO.Heavy,
        fontSize:(16).scaleWidth()
    },
    stocksContainer: {
        width: 70,
        alignItems: 'center',
        
      },
      remainingContainer: {
        minWidth: 130,
        alignItems: 'flex-end',
      },
})