import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: "#F9F9F9",
    paddingBottom: (10).scaleHeight(),
    
  },
  view: {
    flex: 1,
  },
  scrollView:{
    flex:1,

  },
  contentContainerStyle: { 
    flexGrow: 1 
  },
  innerView: {
    flex: 1,
    backgroundColor: "#F9F9F9",
  },
  balanceView: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: scale(8),
    justifyContent: "space-between",
    backgroundColor: COLORS.blueColor,
    padding: (18).scaleWidth(),
  },
  innerSubViews: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(10),
    paddingHorizontal: scale(8),
  },
  enterAmt: {
    fontFamily: LATO.Semibold,
    fontSize: (16).scaleWidth(),
    marginTop: (20).scaleWidth(),
    marginHorizontal: (12).scaleWidth(),
  },
  inputView: {},
  dateTextStyle: {
    marginLeft: 10,
    fontSize: (14).scaleWidth(),
    fontFamily: LATO.Medium,
    color: COLORS.black,
  },
  radioBtn: {
    padding: 10,
    backgroundColor: COLORS.orange,
    marginHorizontal:30
  },
  text:{
    fontFamily:LATO.Medium,
    fontSize:(14).scaleWidth(),
    color:COLORS.gray,
    margin:10
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionContainer: {
      borderRadius: 50,
      borderWidth: 1,
      padding: 3,
  },
  optionIndicator: {
      height: 15,
      width: 15,
      borderWidth: 1,
      borderRadius: 50,
  },
});

