import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "./index.style";
import { AntDesign } from '@expo/vector-icons';
import moment from "moment";



function ChallengeRegisterList(props) {

        let itemData = props.itemData
        let utcTimeStr = props.itemData.dateTimeStampCreated
        let utcMoment = moment.utc(utcTimeStr)
        let localMoment = utcMoment.local();
        let createdDate = localMoment.format('MM/DD/YYYY hh:mm A')


    return (
            <View style={[styles.container, props.backgroundColor]} >
                <TouchableOpacity onPress={props.onPress} style={{ flexDirection: 'row', flex: 1, alignItems: 'center' ,justifyContent:'space-between'}}>
                    <Text style={[styles.textStyle, {flex:0.8,marginRight:7}]}>{itemData.lineupName}</Text>
                    <Text style={[styles.textStyle, { flex: 1.8}]}>{createdDate}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => props.onClickCancel(itemData)} style={{ width: (25).scaleWidth(), justifyContent: 'center', alignItems: 'center' }}>
                    <AntDesign name="closecircleo" size={15} color="rgba(6, 0, 44, 0.5)" />
                </TouchableOpacity>
            </View>
          
    )
}
export default ChallengeRegisterList;