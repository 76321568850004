import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";

export const styles = StyleSheet.create({
    container:{
        backgroundColor:COLORS.white,
    },
    innerContainer:{
        flexDirection:'row',
        alignItems:'center',
        borderBottomColor:'#E6E6E6',
        borderBottomWidth:1,
        marginHorizontal:15,
        paddingVertical:20
    },
    cardNameStyle:{
        color:COLORS.black,
        fontFamily:LATO.Medium,
        fontSize:(14).scaleWidth()
    },
    cardNumberStyle:{
        marginTop:5,
        color:COLORS.lightGrayColor,
        fontFamily:LATO.Medium,
        fontSize:(12).scaleWidth()
    }
})