
import { STORAGE_KEYS } from '../../constants';
import TransactionHistoryModel from '../../models/transactionHistoryModel';
import CardModel from '../../models/paymentsModel';
import { LOCAL_STORAGE } from '../../services';
import ApiManager from '../../services/apiManager';
import { TOAST_MESSAGE } from '../../utils';
import TransactionStatusModel from '../../models/transactionStatus';
import AddFundModel from '../../models/addFundModel';
import WithdrawModel from '../../models/withdrawModel';
let apiManager = ApiManager.getInstance();

const controllerName = 'Card'

//---------------Function to get the list of added cards for a user----------------------//

export const getAddedCardList = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        userName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }

    apiManager.onPostApi(`${controllerName}/GetCards`, requestParameter).then((responseData) => {

        let json = responseData.json.d
        let jsonParse = JSON.parse(json)
        let cards = []
        if (json != undefined) {
            let table = jsonParse["Table"]
            table.map((val) => {
                cards.push(new CardModel().initWithLineupRes(val))
            });
        }
        resolve(cards)

    }).catch((error) => {
        console.log("getAddedCardList Error:", error);
        reject(error)
    })
});
//------------------------------- Function to save a card using API---------------------------------------------//

export const saveCardAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi(`${controllerName}/AddCard`, requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            if (responseData.json.d == 'OK') {
                resolve(true)
            } else {
                TOAST_MESSAGE('Getting error in adding card', '1')
                resolve(false)
            }
        }
        resolve(responseData.json)
    }).catch((error) => {
        console.log("getAddedCardList Error:", error);
        reject(error)
    })
});

//----------------------- Function to remove a card using API-------------------------------------//

export const removeCardAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi(`${controllerName}/RemoveCard`, requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            if (responseData.json.d == 'OK') {
                resolve(true)
            } else {
                TOAST_MESSAGE('Getting error in adding card', '1')
                resolve(false)
            }
        }
        resolve(responseData.json)
    }).catch((error) => {
        console.log("RemoveCard Error:", error);
        reject(error)
    })
});
//----------------------- Function to  get transaction history for a user-------------------------------------//

export const getTransactionHistory = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        ID: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onPostApi(`Player/GetTransactionLogForUser`, requestParameter).then((responseData) => {

        let json = responseData.json
        let jsonParse = JSON.parse(json)
        let transactionList = []
        if (json != undefined) {
            let table = jsonParse["Table"]
            table.map((val) => {
                transactionList.push(new TransactionHistoryModel().initWithTransactionHistory(val))
            });
        }
        resolve(transactionList)

    }).catch((error) => {
        console.log("GetTransactionLogForUser Error:", error);
        reject(error)
    })
});


//-------------------------------Function to add funds--------------------------------//

export const addFundAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Payment/AddFundByPayPal', requestParameter,).then((responseData) => {
        if (responseData.json.Data) {
            let data = JSON.parse(responseData.json.Data)
            let modelClass = new AddFundModel().initWithJSON(data)
            resolve(modelClass)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {

        reject(error)
    })
});

export const withdrawFundAPI = async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi('PaymentWithdraw/WithdrawRequest', requestParameter).then((responseData) => {
            console.log('Paylment ',responseData);
        if (responseData.json) {
            let responseModel = new WithdrawModel().initWithJSON(responseData.json)
            console.log(responseModel,'Model');

            resolve(responseModel)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
});

export const withdrawFundByCheck = async (requestParameter) => new Promise((resolve, reject) => {
    apiManager.onPostApi('PaymentWithdraw/WithdrawRequestbyCheque', requestParameter).then((responseData) => {
        if (responseData.json) {
            let responseModel = new WithdrawModel().initWithJSON(responseData.json)
            resolve(responseModel)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
});
export const transactionStatus = async () => new Promise((resolve, reject) => {
    let requestParameter = {
       userId:LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail) 
    }
    apiManager.onPostApi('Payment/GetTransactionStatus', requestParameter,).then((responseData) => {
            console.log('Paylment ',responseData)
        if (responseData.json) {
            let transactionStatusModel = new TransactionStatusModel().initWithJSON(responseData.json)
            resolve(transactionStatusModel)
        } else {
            TOAST_MESSAGE(responseData.json.message, '1')
            reject(responseData.json)
        }
    }).catch((error) => {
        reject(error)
    })
})