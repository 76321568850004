import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { COLORS, ROUTES } from '../../../../constants'
import { Image } from 'react-native'
import { AUTHHEADER } from '../../../components'
import { LATO } from '../../../../constants/fonts'
import AppButton from '../../../components/appButton'
import { getRewards } from '../../../../controllers/rewardController'

export default function RewardDetail({route,navigation}) {
    const reward = route.params.selectedReward
    const [selectedReward,setSelectedReward]=useState('')
    useEffect(()=>{
        getRewards().then((res)=>{
           let temp = [...res]
            let item = temp.filter((item)=>item.rewardID==reward.rewardID)
            setSelectedReward(item[0])
        }).catch((err)=>{
            console.log(err,'Error in getting Rewards....')
        })
    },[])

    const onPress = () => {
        navigation.navigate(ROUTES.REDEEM_REWARD)
    }

  return (
    <SafeAreaView edges={['top','left','right']} style={styles.mainView}>
      <AUTHHEADER mainView={{backgroundColor:COLORS.appColor,alignItems:'center'}} isBack={true} navigation={navigation} title={'Redeemed Reward'} />
      <View style={{flex:1,backgroundColor:COLORS.white}}>
        <View style={styles.imageView}>
            <Image source={{uri:reward.bannerFile}}style={styles.image}/>
        </View>
        <View style={styles.innerView}>
            <Text style={styles.title}>{reward.description}</Text>
            <Text style={styles.reward}>Reward ID : {reward.rewardID} </Text>
            <Text style={[styles.reward,{color:COLORS.black}]}>{reward.reward} </Text>
        </View>
        <View style={styles.btnView}>
            <Text style={{textAlign:'center',fontFamily:LATO.Bold,fontSize:(15).scaleWidth()}}>Redeemed : {selectedReward.redeemedDateTime}</Text>
            <AppButton title={'GO TO MY REWARDS'}onPress={onPress} />
        </View>
        </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: COLORS.appColor,
  },
  innerView:{
    margin:(20).scaleWidth(),
    marginTop:(0).scaleHeight()
  },
  imageView: {
    alignItems: "center",
    justifyContent: "center",
    margin: (15).scaleWidth(),
  },
  image: { 
    width: "100%", 
    aspectRatio: 100 / 28, 
    marginHorizontal: 20 
 },
 btnView:{
    justifyContent:'flex-end',
    flex:1,
    marginBottom:(10).scaleHeight()
 },
  title: {
    fontFamily: LATO.Semibold,
    fontSize: (15).scaleWidth(),
    paddingTop: 10,
  },
  reward: {
    fontFamily: LATO.Semibold,
    fontSize: (12).scaleWidth(),
    color: COLORS._60PerGrey,
    paddingTop: 10,
  },
});