import { CheckBox } from '@rneui/themed';
import { COLORS } from '../../../constants';
import { LATO } from '../../../constants/fonts';

//-------------------- App Check Box Component---------------------//


function AppCheckBox(props) {
    return (
        <CheckBox
            title={props.title}
            checkedIcon={props.checkedIcon}
            uncheckedIcon={props.uncheckedIcon}
            checked={props.checked}
            checkedColor={props.checkedColor}
            onPress={props.onPress}
            fontFamily={LATO.Regular}
            textStyle={{
                fontFamily: LATO.Regular,
                fontWeight: '400',
                marginLeft: 2,
                color: COLORS.lightGrayColor,
                fontSize: (12).scaleWidth()
            }}
            containerStyle={{
                padding: 0
            }}
        />
    )
}
export default AppCheckBox;