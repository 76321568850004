import { useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { APP_STYLES } from "../../../../utils";
import { CHALLENGEREGISTERCOMPONENT } from "../../../components";

//---------------------------Register for Challenge----------------------//

function ChallengeRegister() {
    const navigation = useNavigation()
    return (
        <SafeAreaView edges={['left', 'right']} style={APP_STYLES.bodyContainer}>
            <CHALLENGEREGISTERCOMPONENT />
        </SafeAreaView>
    )
}
export default ChallengeRegister;