
class AppBottomSheetModel {

    constructor(title, icon, action) {
        this.description = title
        this.icon = icon
        this.action = action

    }

}

export default AppBottomSheetModel;