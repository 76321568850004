import React from 'react'
import { StyleSheet } from 'react-native'
import { COLORS } from '../../../../constants';
import { _scale } from '../../../../utils/appHelper';
import { View, useWindowDimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import ForgotComponent from '../../../components/forgotComponent/index.web'


export default function Forgot() {
    const navigation= useNavigation()
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '40%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1}}>
                        <ForgotComponent navigation={navigation} />
                    </View>
                </View>
            </View>

        </View>
    )
}



const commonStyles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: COLORS.appColor
    },
    webSubContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
     
    },
    webBorderViewStyle: {
        width: '50%',
        height: '80%',
      
        flex:1
    },
})



