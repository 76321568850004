import { IMAGES, COLORS, ERROR_STRING, STORAGE_KEYS, APP_STRINGS, ROUTES } from "../../../constants";
import { withdrawFundAPI, withdrawFundByCheck } from "../../../controllers/paymentController";
import { View, Text, ScrollView, Alert, KeyboardAvoidingView, } from "react-native";
import { Foundation ,MaterialCommunityIcons,Octicons} from "@expo/vector-icons";
import { updateUserBalance } from "../../../redux/reducers/lineupReducer";
import { getUsersBalance } from "../../../controllers/gamesController";
import { checkPassword } from "../../../controllers/signupController";
import { useDispatch, useSelector } from "react-redux";
import { useIsFocused } from "@react-navigation/native";
import { LATO } from "../../../constants/fonts";
import React, { useState, useEffect } from "react";
import { LOCAL_STORAGE } from "../../../services";
import { TOAST_MESSAGE } from "../../../utils";
import { styles } from "./index.style";
import RadioButton from "../radioBtn";
import InputText from "../inputText";
import AppButton from "../appButton";
import { Avatar } from "@rneui/base";
import Loader from "../loader";


function WithdrawFundComponent(props) {

    // ------------- Destructure navigation from props --------------------- //

    const { navigation } = props;

    // -------------- State variables for keyboard height, loader visibility, and form inputs ----------------- //

    // const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [isShowLoader, setShowLoader] = useState(false);
    const [amount, setAmount] = useState('');
    const [payPalId ,setPayPalId ] = useState('');
    const [password,setPassword] = useState('');
    const [isPasswordSecure,setPasswordSecure] = useState(true)
    const [selectedOption, setSelectedOption] = useState('paypal'); 
    const userBalance = useSelector((state) => state.lineupReducers.userBalance)
    const dispatch = useDispatch()
    const isFocused = useIsFocused()

    // Function to handle selection of an option
  
    useEffect(() => {
        getUsersBalance().then((amount) => {
            dispatch(updateUserBalance(amount))
        }).catch((err)=>{
            console.log(err,'Error in fetching user balance');
        })
    }, [])


 
    const handleOptionSelect = (option) => {
        if (selectedOption === option) {
          setSelectedOption('');
        } else {
          setSelectedOption(option);
        }
      };
      

    // ------------------------ API Calling Add Funds ------------------------ //


    const isValidPayPalId = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email.match(APP_STRINGS.emailRegex);
      };
    // ------------------------ Check Validations ------------------------ //

    const checkValidation = () => {
        const trimmedAmount = amount.trim();

       if (+trimmedAmount.trim() == 0) {
            TOAST_MESSAGE(ERROR_STRING.blankAmt, '1');
            return false;
        }

    if (!trimmedAmount || isNaN(trimmedAmount)) {
        TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
        return false;
    }
    const parsedAmount = parseFloat(trimmedAmount);

    if (parsedAmount <= 0) {
        setAmount('')
        TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
        return false;
    }
    if (parsedAmount > userBalance) {
        TOAST_MESSAGE(ERROR_STRING.insufficient, '1');
        return false;
    }

    if(payPalId.trim().length==0){
        TOAST_MESSAGE(ERROR_STRING.blankPayPalId, '1');
        return false;
    }
    if(!isValidPayPalId(payPalId)){
        TOAST_MESSAGE(ERROR_STRING.invalidPayPalId, '1');
        return false;
    }
    if(password.trim().length==0){
        TOAST_MESSAGE(ERROR_STRING.passwordBlank, '1');
        return false;
    }
    if (amount.trim() !== "" && amount.trim() !== "0") {
        // Amount is entered, you can parse it later
        let amt = parseFloat(amount).toFixed(2)
        setAmount(String(amt))
       
    } 
    return true;
    };


    const checkValidationPassword = () => {
        const trimmedAmount = amount.trim();

        if (+trimmedAmount.trim() == 0) {
             TOAST_MESSAGE(ERROR_STRING.blankAmt, '1');
             return false;
         }
 
     if (!trimmedAmount || isNaN(trimmedAmount)) {
         TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
         return false;
     }
     const parsedAmount = parseFloat(trimmedAmount);
 
     if (parsedAmount <= 0) {
         setAmount('')
         TOAST_MESSAGE(ERROR_STRING.invalidAmt, '1');
         return false;
     }
     if (parsedAmount > userBalance) {
         TOAST_MESSAGE(ERROR_STRING.insufficient, '1');
         return false;
     }
     if (amount.trim() !== "" && amount.trim() !== "0") {
         let amt = parseFloat(amount).toFixed(2)
         setAmount(String(amt)) 
     } 
     return true;
    }
    const onClickSubmitBtn = () => {
        if(selectedOption =='cheque'){
            console.log('Cheque is selected');
            let request = {
                UserId:LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                GrossAmount:String(parseFloat(amount).toFixed(2)),
                PayeeEmailAddress:''
            }
            if (checkValidationPassword()) {
                Alert.alert(APP_STRINGS.appName,`Do you want to withdraw $${parseFloat(amount).toFixed(2)}?`,[ {
                    text:"Cancel",
                    style:'cancel'
                },{
                    text:'Yes',
                    onPress:()=>{
                        setShowLoader(true)
                        withdrawFundByCheck(request).then((res)=>{
                            TOAST_MESSAGE(res.message)
                            setAmount('')
                            navigation.navigate(ROUTES.WALLET)
                            getUsersBalance().then((amount) => {
                                console.log(amount)
                                dispatch(updateUserBalance(amount))
                            }).catch((err)=>{
                                console.log(err,'Error in fetching user balance');
                            })
                            console.log(res,'Responsee')
                        }).catch((err)=>{
                            console.log('error',err);
                        }).finally(()=>{
                            setShowLoader(false)
                        })
                    }
                }])
            }
           
          
        }
        else if (checkValidation()) {
               let request={
                userName: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                password: password
               }

               checkPassword(request).then((res)=>{
                     if(res.status == 0){
                        TOAST_MESSAGE('Invalid Password','1')
                     }
                     else{
                        let requestParameter = {
                            UserId: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
                            GrossAmount: String(parseFloat(amount).toFixed(2)),
                            PayeeEmailAddress: payPalId
                        };
                        Alert.alert(APP_STRINGS.appName,`Do you want to withdraw $${parseFloat(amount).toFixed(2)}?`,[ {
                            text:"Cancel",
                            style:'cancel'
                        },{
                            text:"Yes",
                            onPress:()=>{
                                setShowLoader(true)
                                withdrawFundAPI(requestParameter).then((res)=>{
                                    setShowLoader(false)
                                    TOAST_MESSAGE(res.message)
                                    setAmount('')
                                    getUsersBalance().then((amount) => {
                                        console.log(amount)
                                        dispatch(updateUserBalance(amount))
                                    }).catch((err)=>{
                                        console.log(err,'Error in fetching user balance');
                                    })
                                   navigation.navigate(ROUTES.WALLET)
                                    console.log(res,'Responsee')
                                 }).catch((err)=>{
                                    setShowLoader(false)
                                    console.log(err,'Error in WithdrAW');
                                 })
                            }
                           
                        },
                       
                    ])
                     }
                  
                  
               }).catch((err)=> {
                  console.log(err,'Error in check password');
               })
              
            }
              
         
           
       };
    return (
        <View style={styles.mainView}>
            <KeyboardAvoidingView style={{ flex: 1, }} keyboardVerticalOffset={90} behavior="padding" >
                <ScrollView contentContainerStyle={styles.contentContainerStyle} style={styles.scrollView} keyboardShouldPersistTaps='handled' showsVerticalScrollIndicator={false}>
                    <View style={styles.innerView}>
                        <View style={styles.balanceView}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar source={IMAGES.circleWallet} />
                                <Text style={styles.dateTextStyle}>Your Balance</Text>
                            </View>
                            <Text style={styles.dateTextStyle}>${userBalance}</Text>
                        </View>
                        <Text style={styles.enterAmt}>Enter Amount</Text>
                        <View style={styles.inputView}>
                            <InputText
                                textContainer={{ height: (45).scaleHeight(), backgroundColor: COLORS.white, borderWidth: 2, borderColor: COLORS.white, borderRadius: 10,marginLeft:(10).scaleWidth() }}
                                inputStyle={{ height: (45).scaleHeight(), marginLeft: (10).scaleWidth(),fontSize:(16).scaleWidth(),color:COLORS.gray,fontFamily:LATO.Semibold }}
                                iconView={{ height: (45).scaleHeight(), width: (45).scaleWidth(), backgroundColor: 'rgba(234,234,237,1)' ,}}
                                blurOnSubmit={false}
                                value={amount}
                                returnKeyLabel='next'
                                returnKeyType='next'
                                defaultValue={amount}
                                changeValue={text =>{console.log(text,'TEXTT'); setAmount(text)}}
                                keyboardType='decimal-pad'
                                autoCapitalize={true}
                                icons={<Foundation name="dollar" size={34} color={'rgba(110,116,122,1)'} />}
                            />
                        </View>
                        <Text style={styles.enterAmt}>Choose your withdrawal preference</Text>

                        <View style={{ flexDirection: 'row', alignItems: 'center' ,margin:20}}>
                            <RadioButton
                            isSelected={selectedOption === 'paypal'}
                            onPress={() => handleOptionSelect('paypal')}

                            />
                            <Text onPress={() => handleOptionSelect('paypal')}style={styles.text}>Via PayPal</Text>

                            <RadioButton
                            isSelected={selectedOption === 'cheque'}
                            onPress={() => handleOptionSelect('cheque')}
                            />
                            <Text onPress={() => handleOptionSelect('cheque')} style={styles.text}>Via Check</Text>
                        </View>

                       
                      {selectedOption!=='cheque'&& <> 
                      <Text style={styles.enterAmt}>{`Enter PayPal ID`}</Text>
                        <View style={styles.inputView}>
                            <InputText
                                textContainer={{ height: (45).scaleHeight(), backgroundColor: COLORS.white, borderWidth: 2, borderColor: COLORS.white, borderRadius: 10,marginLeft:(10).scaleWidth() }}
                                inputStyle={{ height: (45).scaleHeight(), marginLeft: (10).scaleWidth(),fontSize:(14).scaleWidth(),color:COLORS.gray,fontFamily:LATO.Semibold }}
                                blurOnSubmit={false}
                                returnKeyLabel='next'
                                returnKeyType='next'
                                defaultValue={payPalId}
                                changeValue={text => setPayPalId(text)}
                                keyboardType='email-address'
                                autoCapitalize={true}
                            />
                        </View>
                        <Text style={[styles.enterAmt,{ marginTop:0}]}>{`Enter Your Day Trader Login Password`}</Text>
                        <View style={styles.inputView}>
                            <InputText
                                textContainer={{ height: (45).scaleHeight(), backgroundColor: COLORS.white, borderWidth: 2, borderColor: COLORS.white, borderRadius: 10,marginLeft:(10).scaleWidth() }}
                                inputStyle={{ height: (45).scaleHeight(), marginLeft: (10).scaleWidth(),fontSize:(14).scaleWidth(),color:COLORS.gray,fontFamily:LATO.Semibold }}
                                blurOnSubmit={false}
                                returnKeyLabel='next'
                                returnKeyType='next'
                                defaultValue={password}
                                changeValue={text => setPassword(text)}
                                keyboardType='email-address'
                                autoCapitalize={true}
                                secureText={isPasswordSecure}
                                autocapitalize={'none'}
                                icons={<MaterialCommunityIcons name="lock-outline" size={22} color={COLORS.lightGrayColor} />}
                                eyeIcon={
                                    <Octicons 
                                        name={isPasswordSecure ? "eye-closed" : "eye"}
                                        onPress={() => { isPasswordSecure ? setPasswordSecure(false) : setPasswordSecure(true) }}
                                        size={20} color={COLORS.lightGrayColor}
                                    />}
                                onPressEyeIcon={ ()=>isPasswordSecure ? setPasswordSecure(false) : setPasswordSecure(true) }
                
                            />
                        </View>
                        </>
                        }
                     
                    </View>

                </ScrollView>
                <AppButton disabled={!amount} buttonStyle={{backgroundColor:COLORS.red}}  onPress={onClickSubmitBtn} title={APP_STRINGS.withdrawFund} />

            </KeyboardAvoidingView>
                {isShowLoader && <Loader />}

              
                
        </View>
    )
}

export default WithdrawFundComponent;

