import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import { StyleSheet } from 'react-native'
import { COLORS } from '../../../../constants';
import { _scale } from '../../../../utils/appHelper';
import { useNavigation } from '@react-navigation/native';
import SetNewPasswordComponent from '../../../components/setPasswordComponent/index.web';
import {  LOGO } from '../../../components';

export default function SetNewPasswordWeb({route}) {
    const navigation= useNavigation()
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= 768;

    return (
        <View style={commonStyles.mainContainer} key={'MainContainer'}>
            <View style={commonStyles.webSubContainerStyle} key={'SubContainer'}>
                <LOGO container={{marginTop:20}}/>
                <View style={[commonStyles.webBorderViewStyle, { width: isLargeScreen ? '50%' : '90%' }]} key={'BorderView'}>
                    <View style={{ flex: 1}}>
                        <SetNewPasswordComponent navigation={navigation}route={route} />
                    </View>
                </View>
            </View>

        </View>
    )
}



const commonStyles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: COLORS.appColor
    },
    webSubContainerStyle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
     
    },
    webBorderViewStyle: {
        width: '50%',
        height: '80%',
        flex:1
    },
})




