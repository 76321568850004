import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Modal,
  ImageBackground,
  ActivityIndicator, // Import ActivityIndicator for the loader
} from "react-native";
import { LATO } from "../../../constants/fonts";
import { COLORS } from "../../../constants";
import { scale } from "react-native-size-matters";
import { Ionicons } from "@expo/vector-icons";
import WebView from "react-native-webview";
import { Vimeo } from "react-native-vimeo-iframe";

const VideoComponent = (props) => {

  const VIMEO_ID = props.videoURL;
  const [mp4Url, setMp4Url] = useState('');
  const [loading, setLoading] = useState(true); // State to manage loading state
 

  useEffect(() => {
    let isVimeoUrl = String(props.videoURL).includes('https://vimeo.com/')
    setLoading(false)
      if(isVimeoUrl){
        let video = props.videoURL.split('https://vimeo.com/')
        console.log( video,'c');
        setMp4Url(video[1])
        setLoading(false)
      }
  }, [])
  const videoCallbacks = {
    play: (data) => console.log('play: ', data),
    pause: (data) => console.log('pause: ', data),
    fullscreenchange: (data) => console.log('fullscreenchange: ', data),
    ended: props.onVideoFinish,
    controlschange: (data) => console.log('controlschange: ', data),
  };
  return (
    <View>
      <Modal
        animationType="fade"
        visible={true}
        transparent={true}
        onRequestClose={props.onRequestClose} 
      >
        <ImageBackground blurRadius={90} style={styles.modalView}>
          <View style={styles.gifContainer}>
            {loading ? ( // Show loader if loading is true
              <ActivityIndicator size="large" color={COLORS.gray} />
            ) : (
              <View style={styles.videoView}>
              <Vimeo
                videoId={mp4Url}
                controls={true}
                style={{flex:1}}
                handlers={videoCallbacks}
                params={'api=1&autoplay=1'}
                
                />
                  {/* <Video
                    source={{ uri: mp4Url }}
                    style={{ height: '100%', width: '100%', borderRadius: 10 }}
                    resizeMode="cover"
                    controls={true}
                    onExternalPlaybackChange={()=>console.log('hiii')}
                    onVideoEnd={props.onVideoFinish}
                    onVideoLoad={()=>{console.log('ggfgf')}}
                    onVideoLoadStart={()=>{console.log('Video log');}}
                    onEnd={props.onVideoFinish}
                  />
                 */}
                <TouchableOpacity
                  onPress={props.onVideoFinish}
                  style={{
                    position: "absolute",
                    alignSelf: "flex-end",
                    borderRadius: 5,
                  }}
                >
                  <Ionicons
                    name="close-circle"
                    size={44}
                    color={COLORS.gray}
                    style={styles.icon}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </ImageBackground>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },

  gifContainer: {
    width: "90%",
    borderRadius: scale(8),
    backgroundColor: "#E6E6E6",
    marginVertical: (20).scaleHeight(),
    alignItems: "center",
    justifyContent: "center",
    height:(200).scaleHeight()
  },
  videoView:{
    width:'100%',
    height:'100%',
    borderRadius: 10,
    flex:1
  },
  icon: {},
});

export default VideoComponent;
