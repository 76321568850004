export const moneyModel = [
  '$150000',
  '$145000',
  '$140000',
  '$135000',
  '$130000',
  '$125000',
  '$120000',
  '$115000',
  '$110000',
  '$105000',
  '$100000',
  '$95000',
  '$90000',
  '$85000',
  '$80000',
  '$75000',
];
