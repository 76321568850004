import { SafeAreaView } from "react-native-safe-area-context";
import OtpComponent from "../../../components/otpComponent";
import { AUTHHEADER, LOGO } from "../../../components";
import { APP_STYLES } from "../../../../utils";

function Otp({ navigation, route }) {
    return (

        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER isBack={true} navigation={navigation} route={route} />
            <LOGO />
            <OtpComponent navigation={navigation} route={route} />
        </SafeAreaView>
    );
}

export default Otp;