import { amountColor, amountSign, backgroundColor, icon } from "../../../../utils/appHelper"
import { getTransactionHistory } from "../../../../controllers/paymentController"
import { FacebookLoader } from "react-native-easy-content-loader"
import TableFooterView from "../../../components/tableFooterView"
import {  View, SectionList, Text, Image } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { APP_STRINGS } from "../../../../constants"
import { AUTHHEADER } from "../../../components"
import { APP_STYLES } from "../../../../utils"
import { useEffect, useState } from "react"
import { styles } from './index.style'
import moment from "moment"

function TransactionHistory({ navigation }) {

    const [transactionHistoryList, setTransactionHistoryList] = useState([])
    const [isShimmer, setShimmer] = useState(false)

//-----------------------API Calling----------------------//
    useEffect(() => {
        callTransactionHistory()
    }, [])

    const callTransactionHistory = () => {
        setShimmer(true)
        getTransactionHistory().then((res) => {
            filterData(res)
            setShimmer(false)
        }).catch((error) => {
            setShimmer(false)
        })
    }
//--------------------------Filter Data-----------------------//
    const filterData = (response) => {

        const combinedData = response.reduce((acc, cur) => {
    
            let localMoment = moment.utc(cur.transactionDateTime).local()
            const formattedDateStr = localMoment.format('MM-DD-YYYY')
            const existingData = acc.find((elem) => {
                return elem.title == formattedDateStr
            })
            if (existingData) {
                existingData.data.push(cur)
            } else {
                acc.push({ title: formattedDateStr, data: [cur] })

            }
            return acc
        }, [])
        setTransactionHistoryList(combinedData)
    }

//------------------------Flatlist render Item--------------------------//
    const renderItem = ({ item }) => {
        let utcMoment = moment.utc(item.transactionDateTime).local()
        let createdDate = utcMoment.format('hh:mm A')
        let type = (item.transactionType).replace(/ /g, "").toLowerCase()
        return (
            <View style={styles.card}>
                <View style={styles.innerView}>
                    <View style={styles.subContainer}>
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <View style={styles.imageView}>
                                <Image source={icon(item.transactionType)}/>
                            </View>
                            <View style={{flex:1}}>
                                <Text style={styles.itemType}>{type=='addfunds'?'Deposit':item.transactionType}</Text>
                                <Text style={styles.time}>{createdDate}{(type=='gameentry'||type=='unregistered'||type=='win'||type=='gamecancelrefund')&&<Text style={styles.time}> | Game ID:{item.gameID}</Text>}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View>
                            <Text style={[styles.amt,{ color: 'grey'}]}>{amountSign(item.transactionType) + '$'+parseFloat(item.netAmount).toFixed(2)}</Text>
                        </View>
                        <View style={{backgroundColor:backgroundColor(item.transactionStatus),padding:5,borderRadius:5,alignSelf:'flex-end',marginTop:4}}>
                            <Text style={[{color:amountColor(item.transactionStatus)},styles.success]}>{item.transactionStatus}</Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
//-------------------------Render Shimmer-----------------------------//
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {
                    Array(8).fill(0).map((i) => {
                        return <FacebookLoader active={true} avatarStyles={styles.avatar} pRows={0} />
                    })
                }
            </View>
        )
    }

    return (
        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <AUTHHEADER mainView={{alignItems:'center'}}isBack={true} navigation={navigation} title={APP_STRINGS.history} />
            <View style={styles.container}>
                {isShimmer ? renderShimmer() :
                    <SectionList
                        sections={transactionHistoryList}
                        keyExtractor={(item, index) => item + index}
                        renderItem={renderItem}
                        renderSectionHeader={({ section: { title } }) => (
                            <View style={styles.sectionView}>
                                <Text style={styles.title}>{title}</Text>
                            </View>
                        )}
                        ListEmptyComponent={!isShimmer && <TableFooterView message={'Transactions not available'} />}
                    />
                }
            </View>
        </SafeAreaView >
    )
}

export default TransactionHistory
