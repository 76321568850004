export default {
    kAuthToken: "kAuthToken",
    kDeviceToken: "kDeviceToken",
    isUserLoggedIn: "isUserLoggedIn",
    kFirstName: "kFirstName",
    kLastName: "kLastName",
    kEmail: "kEmail",
    kRemember: "kRemember",
    kUserNameRemember: "kUserNameRemember",
    kPasswordRemember: "kPasswordRemember",
    isVideoPlayed:'isVideoPlayed'



};
