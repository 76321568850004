import { getCancelledGamesAPI } from "../../../../../controllers/gamesController";
import TableFooterView from "../../../../components/tableFooterView";
import { FlatList, View ,RefreshControl} from "react-native";
import { GAMECOMPONENT } from "../../../../components";
import { useEffect, useState } from "react";
import GameDetailPopUp from "../../../../components/gameDetailPopUp";


function CancelledGame({ navigation }) {

    
     //----------State variables using the useState hook--------------//
     const [cancelGames, setCancelGames] = useState([]);
     const [isLoader, setLoader] = useState(false);
     const [refreshing, setRefreshing] = useState(false);
     const [showPopup, setShowPopup] = useState(false);
     const [gameTitle,setGameTitle] = useState('')
     const [gameDescription,setGameDescription] = useState()
    //-------------useEffect hook to handle component initialization and fetching data-----------//
     useEffect(() => {
         // Set loader to true and add a listener to fetch cancelled games on focus
         setLoader(true);
         const unsubscribe = navigation.addListener('focus', () => {
             getCanceledGames();
             return unsubscribe;
         }, []);
 
     }, []);
 
     //---------------------- Function to Fetch Cancelled Games ----------------------//
     const getCanceledGames = () => {
         // Call API to get cancelled games
         getCancelledGamesAPI().then((response) => {
             setCancelGames(response);
         }).catch((error) => {
             console.log('error', error);
             setLoader(false);
             setRefreshing(false);
 
         }).finally(() => {
             setLoader(false);
             setRefreshing(false);
         });
     }
 
     //---------------------- Function to Handle Refreshing ----------------------//
     const onRefreshPending = () => {
         setRefreshing(true);
         getCanceledGames();
     }
  
    //---------------------- Function to Handle Question Mark Button Press ----------------------//
    const onPressQuestionMark = (title,description,gameDetail) => {
        if(gameDetail.length!=0){
            setShowPopup(true)
            setGameTitle(title)
            setGameDescription(description)
        }
      
    }

     //---------------------- Function to Render Each Item in the FlatList ----------------------//
     const RenderItem = (itemData) => {
        let parts =  itemData.item.gameDetail.length==0?'':itemData.item.gameDetail.split(':')
        let title = itemData.item.gameDetail.length==0?'':parts[0].trim()
        let description =  itemData.item.gameDetail.length==0?'':parts.slice(1).join(':').trim()
         return (
            <GAMECOMPONENT 
                data={itemData.item} 
                cellType={'Cancelled'} 
                onPressQuestionMark={()=>onPressQuestionMark(title,description,itemData.item.gameDetail)}
                onDismissPopup={() => setShowPopup(false)}
             />
         );
     }
 
     //---------------------- Component Rendering ----------------------//
     return (
         <View style={{ backgroundColor: 'white', paddingBottom: (80).scaleHeight(), flex: 1 }}>
 
             <FlatList
                 showsVerticalScrollIndicator={false}
                 data={cancelGames}
                 keyExtractor={item => item.gameID}
                 renderItem={RenderItem}
                 ListEmptyComponent={!isLoader && <TableFooterView />}
                 refreshControl={
                     <RefreshControl refreshing={refreshing} onRefresh={onRefreshPending} />
                 }
             />
              {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
                
              />
            )}
         </View>
     );
 }
 
 // Export the CancelledGame component as the default export
 export default CancelledGame;