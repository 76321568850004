import React, { useState, useRef } from 'react';
import { View, TouchableOpacity, StyleSheet, Animated } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { COLORS } from '../../../constants';

const RadioButton = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const scaleValue = useRef(new Animated.Value(0)).current;

  const handlePress = () => {
    setIsSelected(!isSelected);
    Animated.spring(scaleValue, {
      toValue: isSelected ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const scale = scaleValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 1.2],
  });

  return (
    <TouchableOpacity onPress={props.onPress} style={styles.radioButton}>
      <View style={[styles.outerCircle, props.isSelected && styles.outerCircleSelected]}>
        {props.isSelected && 
          <Animated.View style={[styles.innerCircle, { transform: [{ scale }] }]}>
            <LinearGradient
              colors={[COLORS.orange,COLORS.lightYellowColor]} // Add your desired gradient colors here
              style={styles.gradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 1 }}
            />
          </Animated.View>
        }
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  radioButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerCircle: {
    borderWidth: 1.5,
    borderColor: 'black',
    borderRadius: 12,
    height: 24,
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  outerCircleSelected: {
    borderColor: COLORS.orange,
  },
  innerCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    overflow: 'hidden',
  },
  gradient: {
    flex: 1,
  },
});

export default RadioButton;
