import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO, OVERPASS } from "../../../constants/fonts";
import { scale } from 'react-native-size-matters';

export const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        // padding: (35),
    },

    gifContainer: {
        height: scale(190),
        width: '90%',
        borderRadius: scale(8),
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent:'center'

    },

    buttonStyle: {
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonText: {
        color: COLORS.white,
        fontFamily: LATO.Bold,
        fontSize: (14).scaleWidth(),
        paddingVertical: 15
    },
    btn:{ 
        width: '90%',
        marginTop:15  
    },
    innerView:{ 
        width: '90%', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end',  
    },
    inputStyle:{
        textAlign:'center',
        paddingVertical:5 
    },
    textContainer:{
        alignItems:'center',
        paddingHorizontal:20
    }
});