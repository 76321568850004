class CardModel {

    constructor(
        cardID = '',
        userID = '',
        cardNumber = '',
        cardholderName = '',
    ) {
        this.cardID = cardID
        this.userID = userID
        this.cardNumber = cardNumber
        this.cardholderName = cardholderName
    }

    initWithLineupRes(lineUpRes) {

        this.cardID = lineUpRes['card_id'] || ''
        this.userID = lineUpRes['user_id'] || ''
        this.cardNumber = lineUpRes['card_number'] || ""
        this.cardholderName = lineUpRes['cardholder_name'] || ""
        return this
    }
}
export default CardModel; 