import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: "#F9F9F9",
    paddingBottom: (10).scaleHeight(),
  },
  view: {
    flex: 1,
  },
  contentContainerStyle: {
    flex: 1,
  },
  innerView: {
    flex: 1,
    backgroundColor: "#F9F9F9",
  },
  balanceView: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: scale(8),
    justifyContent: "space-between",
    backgroundColor: COLORS.blueColor,
    padding: (18).scaleWidth(),
  },
  innerSubViews: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: scale(10),
    paddingHorizontal: scale(8),
  },
  enterAmt: {
    fontFamily: LATO.Bold,
    fontSize: (16).scaleWidth(),
    marginTop: (20).scaleWidth(),
    marginHorizontal: (12).scaleWidth(),
  },
  textContainer: {
    height: (48).scaleHeight(),
    backgroundColor: COLORS.white,
    borderWidth: 2,
    borderColor: COLORS.white,
    borderRadius: 10,
    marginLeft: (10).scaleWidth(),
  },
  textView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  feeTitle: {
    fontFamily: LATO.Bold,
    fontSize: 17,
    textAlign: "center",
    marginHorizontal: 10,
  },
  feeSchedule: {
    fontFamily: LATO.Bold,
    marginTop: 15,
    fontSize: 17,
  },
  paypal: { 
    fontFamily: LATO.Bold, 
    marginTop: 10 
  },
  inputStyle: {
    height: (48).scaleHeight(),
    marginLeft: (10).scaleWidth(),
    fontSize: (16).scaleWidth(),
    color: COLORS.gray,
    fontFamily: LATO.Bold,
  },
  iconView: {
    height: (47).scaleHeight(),
    width: (47).scaleWidth(),
    backgroundColor: COLORS.iconBackgroundColor,
    borderWidth: 1,
    borderColor: COLORS.white,
    borderRadius: 5,
  },
  dateTextStyle: {
    marginLeft: 10,
    fontSize: (14).scaleWidth(),
    fontFamily: LATO.Medium,
    color: COLORS.black,
  },
  userBalance: {
    // marginLeft: 10,
    fontSize: (14).scaleWidth(),
    fontFamily: LATO.Bold,
    color: COLORS.gray,
    flex: 1,
    textAlign: "right",
  },
  moneyBtn: {
    padding: (5).scaleWidth(),
    paddingHorizontal: (10).scaleWidth(),
    borderWidth: 2,
    borderColor: "rgba(40, 126, 173, 0.3)",
    borderRadius: (15).scaleWidth(),
    marginLeft: (10).scaleWidth(),
    backgroundColor: COLORS.white,
  },
  moneyText: {
    fontFamily: LATO.Bold,
    color: "rgba(6, 0, 44, 0.6)",
    fontSize: (12).scaleWidth(),
  },
  btnView: {
    marginTop: 20,
    backgroundColor: COLORS.white,
  },
});
