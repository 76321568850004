import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
export const styles = StyleSheet.create({
    mainView:{ 
        flex: 1, 
        backgroundColor:COLORS.lighterBlue 
    },
    innerView:{
        justifyContent:'space-between',
        flexDirection:'row'
    },
    gameID:{
        marginTop:20,
        marginHorizontal:20,
        fontSize:(16).scaleWidth(),
        fontFamily:LATO.Bold,
        marginBottom:10
    },
    avatarStyles:{ 
        width: 0, 
        height: 0, 
        borderRadius: 60 
    },
})