import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { validatedPasswordReset } from "../../../controllers/signupController";
import {  MaterialCommunityIcons, Octicons } from '@expo/vector-icons';
import {  TOAST_MESSAGE } from "../../../utils";
import { COLORS, ERROR_STRING } from "../../../constants";
import OTPTextInput from 'react-native-otp-textinput';
import { LATO ,OVERPASS} from "../../../constants/fonts";
import InputText from "../inputText";
import AppButton from "../appButton";
import { useState } from "react";
import Loader from "../loader";

function SetNewPasswordComponent(props) {

    const { navigation, route } = props
    const { email } = route.params
    const [isPasswordSecure, setPasswordSecure] = useState(true);
    const [isConfirmPasswordSecure, setConfirmPasswordSecure] = useState(true);
    const [isShowLoader, setShowLoader] = useState(false)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');

    const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\w\W]{8,}$/;

    //-------------Validations for New Password-------------//

    const onSetNewPassword = () => {

        if (password.trim().length == 0) {
            TOAST_MESSAGE(ERROR_STRING.passwordBlank, '1')
            return
        } else if (otp.trim().length != 6) {
            TOAST_MESSAGE(ERROR_STRING.otpBlank, '1')
            return
        } else if (!passwordPattern.test(password)) {
            TOAST_MESSAGE(ERROR_STRING.passwordPattern, "1")
            return false
        } else if (confirmPassword.trim().length == 0 || password != confirmPassword) {
            TOAST_MESSAGE(ERROR_STRING.confirmPassword, "1")
            return false
        } else {
            validatedNewPassword()
        }

    }
    //-------------API Calling-------------//

    const validatedNewPassword = () => {

        let requestParameter = {
            Code: otp,
            Email: email,
            Password: password
        }
        setShowLoader(true)
        validatedPasswordReset(requestParameter).then(() => {
            navigation.popToTop()
        }).catch((error) => {
            console.log(error);
            setShowLoader(false)

        }).finally(() => {
            setShowLoader(false)
        })

    }

    return (

        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" :'padding'} style={styles.mainView}>
            <ScrollView  showsVerticalScrollIndicator={false} keyboardShouldPersistTaps={'handled'} contentContainerStyle={styles.contentContainerStyle} style={styles.mainView}>
                <View style={{flex:1,}}>
                    <View style={styles.innerView}>
                        <Text style={styles.authTitleStyle}>Change Password</Text>
                        <View style={{alignSelf:'center'}}>
                            <Text style={styles.subTitleText}>Please enter OTP that sent to be on given email and set new password.</Text>
                        </View>
                        <View style={styles.innerSubView}>
                            <OTPTextInput
                                textInputStyle={styles.textInputStyle}
                                offTintColor={'red'}
                                inputCount={6}
                                secureTextEntry={true}
                                handleTextChange={(text) => {
                                    setOtp(text)
                                }}
                            />
                        </View>
                        <InputText
                            textContainer={[styles.textContainer,{marginTop:40}]}
                            inputStyle={{ fontSize: 16,color:COLORS._60PerWhite,height:55,outline: "none"}}
                            value={password}
                            secureText={isPasswordSecure}
                            placeHolder={'Set New Password'}
                            placeholderTextColor={COLORS._60PerWhite}
                            keyboardType={'email-address'}
                            autocapitalize={'none'}
                            icons={<MaterialCommunityIcons  name="lock-outline" size={20} color={COLORS._60PerWhite} />}
                            eyeIcon={
                                <Octicons name={isPasswordSecure ? "eye-closed" : "eye"}
                                    onPress={() => { isPasswordSecure ? setPasswordSecure(false) : setPasswordSecure(true) }}
                                    size={20} color={COLORS._60PerWhite}
                                />}
                            changeValue={value => setPassword(value)}
                            maxLength={20}                 
                        />
                            

                            <InputText
                                secureText={isConfirmPasswordSecure}
                                defaultValue={confirmPassword}
                                placeHolder={'Confirm Password'}
                                placeholderTextColor={COLORS._60PerWhite}
                                textContainer={[styles.textContainer]}
                                inputStyle={{ fontSize: 16,color:COLORS._60PerWhite,height:55,outline: "none"}}

                                autocapitalize={'none'}
                                icons={<MaterialCommunityIcons  name="lock-outline" size={20} color={COLORS._60PerWhite} />}
                                eyeIcon={
                                    <Octicons name={isConfirmPasswordSecure ? "eye-closed" : "eye"}
                                        onPress={() => { isConfirmPasswordSecure ? setConfirmPasswordSecure(false) : setConfirmPasswordSecure(true) }}
                                        size={20} color={COLORS._60PerWhite}
                                    />}
                                changeValue={value => setConfirmPassword(value)}
                                maxLength={20}
                            />
                        <AppButton buttonStyleWeb={{marginHorizontal:30,marginTop:20}} title={'Change Password'} onPress={onSetNewPassword} />

                    </View>
                </View>
            </ScrollView>
            {isShowLoader && <Loader />}
        </KeyboardAvoidingView >
    );
}

export default SetNewPasswordComponent;

const styles = StyleSheet.create({
    mainView:{
        flex:1
    },
    contentContainerStyle:{ 
        flexGrow: 1
    },
    textInputStyle: {
        width: (40),
        height: (40),
        borderRadius: (20),
       
        backgroundColor: COLORS._60PerWhite,
        borderBottomWidth: 0,
        fontFamily: LATO.Bold,
        fontSize: (20),
       
    },
    innerView:{ 
        flex: 1, 
        marginBottom: 20 
    },
    innerSubView:{
        padding: 0, 
        marginTop: 20 ,
        marginLeft:0,
        maxWidth:350,
        alignSelf:'center'
    },

    textContainer: {
        borderWidth: 1,
        borderColor: "rgba(223, 225, 229, 1)",
        height: 50,
        marginVertical: 15,
        color: COLORS.white,
       
    },
        authTitleStyle: {
            textAlign: "center",
            fontSize: 17,
            
            color: COLORS.white,
            fontFamily: OVERPASS.Bold,
            marginBottom: (10),
            marginHorizontal: 20,
            
        },
        subTitleText: {
            fontFamily: LATO.Medium,
            marginHorizontal: (40).scaleWidth(),
            color: COLORS.white,
            fontSize: 18,
            textAlign: 'center',
            maxWidth:450
        },
    


})