import {  STORAGE_KEYS } from '../../constants'
import GroupsModel from '../../models/groupsModel'
import { LOCAL_STORAGE } from '../../services'
import ApiManager from '../../services/apiManager'

let apiManager = ApiManager.getInstance();

//----------------Get AvailableGroups for all users---------------------//

export const getAvailableGroups = async () => new Promise((resolve, reject) => {

    
    apiManager.onGetApi('Group/GetGroups').then((responseData) => {

        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let temp = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                temp.push(new GroupsModel().initWithGroupResponse(val))
            });
        }
        
        resolve(temp)

    }).catch((error) => {
        console.log("getAvailableGroups Api Error:", error);
        reject(error)
    })
})
//----------------Get AvailableGroups for all users---------------------//


export const getUserGroups = async () => new Promise((resolve, reject) => {

    let req = {
        UserId:LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }
    apiManager.onGetApi('Group/GetUserGroups',req).then((responseData) => {

        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let temp = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                temp.push(new GroupsModel().initWithGroupResponse(val))
            });
        }
        
        resolve(temp)

    }).catch((error) => {
        console.log("getAvailableGroups Api Error:", error);
        reject(error)
    })
})
//----------------Add the current user to the Group---------------------//


export const addUserToGroup = async (requestParameter) => new Promise((resolve, reject) => {

    
    apiManager.onPostApi('Group/AddUserToGroup', requestParameter).then((responseData) => {
        let json = JSON.parse(responseData.json.d)
        let tableRecord = json["Table"]
        let completedGamesRecord = []
        if (tableRecord != undefined) {
            console.log(tableRecord,'tableRecord');
            tableRecord.map((val) => {
                // completedGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(json["Table"])

    }).catch((error) => {
        console.log("AddUserToGroup Error:", error);
        reject(error)
    })
});
//----------------Remove the current user from a group---------------------//

export const removeUserFromGroup = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('Group/RemoveUserToGroup', requestParameter).then((responseData) => {

        let json = JSON.parse(responseData.json.d)
        console.log(responseData.json.d,'responseData.json.d');
        let tableRecord = json["Table"]
        let completedGamesRecord = []
        if (tableRecord != undefined) {
            tableRecord.map((val) => {
                // completedGamesRecord.push(new GameModel().initWithGameResponse(val))
            });
        }
        resolve(tableRecord)

    }).catch((error) => {
        console.log("RemoveUserToGroup API Error:", error);
        reject(error)
    })
});
