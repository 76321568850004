class GroupsModel {
    constructor(
        groupID = "",
        groupName = "",
        groupType = ""
    ) {
        this.groupID = groupID;
        this.groupName = groupName;
        this.groupType = groupType;
    }

    initWithGroupResponse(groupObj) {
        this.groupID = groupObj["GroupID"] || "";
        this.groupName = groupObj["GroupName"] || "";
        this.groupType = groupObj["GroupType"] || "";

        return this;
    }
}

export default GroupsModel;
