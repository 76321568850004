

export default function updateStates(selectedStockData, action) {

    switch (action.type) {

        case "lineup/addStockList": return addStocks(selectedStockData, action.payload)

        case "lineup/onUpdateSpendMoney": return spendMoneyChange(selectedStockData, action.payload)

        case "lineup/updateStockPositionUpDown": return updatePositionUpOrDown(selectedStockData, action.payload)

        case "lineup/deleteStocks": return deleteSelectedStocks(selectedStockData, action.payload)

    }
}


const addStocks = (selectedStockData, payload) => {

    let tempSelectedStock = [...selectedStockData]

    if (payload.index == -1) {

        tempSelectedStock.push(payload.itemData)
        selectedStockData = [...tempSelectedStock]

        const index = tempSelectedStock.findIndex((currValue) => {
            return currValue.id == payload.itemData.id
        })

        let obj = tempSelectedStock[index]
        if (obj.money !== undefined || obj.position !== undefined) {
            delete obj.money
            delete obj.position
        }
    } else {

        tempSelectedStock.splice(payload.index, 1)
        selectedStockData = [...tempSelectedStock]
    }
    return selectedStockData

}

const spendMoneyChange = (selectedStockData, payload) => {

    let temp = [...selectedStockData]
    let obj = temp[payload.idx]
    if (payload.text.length == 0) {
        obj.money = ""
    } else {
        obj.money = payload.text.indexOf(".") > 0 ? payload.text.split(".").length >= 2 ? payload.text.split(".")[0] + "." + payload.text.split(".")[1].substring(-1, 2) : payload.text.trim() : payload.text.trim()
    }

    selectedStockData = [...temp]
    return selectedStockData
}
const updatePositionUpOrDown = (selectedStockData, payload) => {
    let temp = [...selectedStockData]
    let obj = temp[payload.idx]
    if (obj.position != undefined) {
        if (payload.pos == obj.position) {
            delete obj.position
        }
        else {
            obj.position = payload.pos
        }
    }
    else {
        obj.position = payload.pos
    }

    selectedStockData = [...temp]
    return selectedStockData

}

const deleteSelectedStocks = (selectedStockData, payload) => {
    let tempSelectedStock = [...selectedStockData]
    tempSelectedStock.splice(payload.index, 1)
    if (tempSelectedStock.length == 0) {
        payload.navigation.pop()
    }
    selectedStockData = [...tempSelectedStock]
    return selectedStockData
}



