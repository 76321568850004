
import { STORAGE_KEYS } from '../../constants';
import LineUpData from '../../models/lineUpData';
import StocksListData from '../../models/stocksModelClass';
import { LOCAL_STORAGE } from '../../services';
import ApiManager from '../../services/apiManager';
import { TOAST_MESSAGE } from '../../utils';
let apiManager = ApiManager.getInstance();

//--------------------Function to fetch the stock list from the API----------------------//

export const fetchStockList = async () => new Promise((resolve, reject) => {

    apiManager.onGetApi('Game/GetStockList').then((responseData) => {

        let json = JSON.parse(responseData.json)
        let stockList = []
        if (json != undefined) {
            let table = json["Table"]
            table.map((val) => {

                let price = val.Price == "" ? "0.00" : parseFloat(val.Price).toFixed(2)
                stockList.push(new StocksListData(val.Symbol, val.Symbol, val.Security, price))
            });
        }
        resolve(stockList)

    }).catch((error) => {
        console.log("GetStockList Error:", error);
        reject(error)
    })
});
//---------------- Function to fetch created lineups from the API----------------------//

export const fetchCreatedLineup = async () => new Promise((resolve, reject) => {

    let requestParameter = {
        locUserID: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    }

    apiManager.onPostApi('Player/GetSP500LineupsbyPlayerId', requestParameter).then((responseData) => {

        let json = responseData.json.d
        let jsonParse = JSON.parse(json)
        let lineUpList = []
        if (jsonParse != undefined) {
            let table = jsonParse["Table"]
            table.map((val) => {
                lineUpList.push(new LineUpData().initWithLineupRes(val))
            });
        }
        resolve(lineUpList)

    }).catch((error) => {
        console.log("FetchCreatedLineup Error:", error);
        reject(error)
    })
});
//---------------Function to check if a lineup name already exists for the user-----------------//

export const checkLineupNameExist = async (name) => new Promise((resolve, reject) => {

    let requestParameter = {
        strUserID: LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail),
        strLineupName: name
    }

    apiManager.onPostApi('LineUp/SP500DoesThisUserHaveALineupWithThisName', requestParameter).then((responseData) => {
        if (responseData.json != undefined) {
            if (responseData.json.d == 'N') {
                resolve(true)
            } else {
                resolve(false)
            }
        }
    }).catch((error) => {
        console.log("checkLineupNameExist DoesThisUserHaveALineupWithThisName:", error);
        reject(error)
    })
});
//------------------Function to generate the lineup number----------------------------//

export const generateLineupNumber = async () => new Promise((resolve, reject) => {

    apiManager.onGetApi('LineUp/GenNextSequenceValue').then((responseData) => {
        if (responseData.json != undefined) {
            if (!isNaN(responseData.json.d)) {
                resolve(responseData.json.d)
            } else {
                reject('')
            }
        }
    }).catch((error) => {
        console.log('GenNextSequenceValue:' ,error);
        reject(error)
    })
});

//------------------Function to create line up--------------------//

export const createLineupApi = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('LineUp/SP500InsertIntoStoredProcedureCreateLineupMaster', requestParameter).then((responseData) => {
        resolve(responseData.json)
    }).catch((error) => {
        console.log("Create Line up Error:", error);
        reject(error)
    })
});
//------------------Function to insert lineup details-------------------//

export const insertLineupDetailsApi = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('LineUp/SP500InsertIntoStoredProcedureInsertLineupDetails', requestParameter).then((responseData) => {
        resolve(responseData.json)
    }).catch((error) => {
        console.log("insertLineupDetailsApi Error:", error);
        reject(error)
    })
});
//------------------Function to check if the lineup is active or not-------------------//

export const inactiveLineupAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('LineUp/MakeSP500LineupInactive', requestParameter).then((responseData) => {

        if (responseData.json != undefined) {
            if (responseData.json.d == 'OK') {
                resolve(true)
            } else {
                TOAST_MESSAGE('Action not completed at this moment', '1')
                resolve(false)
            }
        }
        resolve(responseData.json)
    }).catch((error) => {
        console.log("inactiveLineupAPI Error:", error);
        reject(error)
    })
});
//------------------Function to get lineup details-------------------//

export const lineupDetailsByIdAPI = async (requestParameter) => new Promise((resolve, reject) => {

    apiManager.onPostApi('LineUp/SP500GetLineupDetailbyPlayerIdLineupName', requestParameter).then((responseData) => {

        let json = responseData.json.d
        let jsonParse = JSON.parse(json)
        let selectedStocks = []
        if (jsonParse != undefined) {
            let table = jsonParse["Table"]
            table.map((val) => {

                let price = val.Price == "" ? "0.00" : parseFloat(val.Price).toFixed(2)
                let model = new StocksListData(val.Symbol, val.Symbol, val.Security, price)
                let amount = val['Amount'] || 0;
                amount = '$' + parseInt(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                model.money = amount
                model.position = val['Position'] || ''
                model.isSelected = true
                selectedStocks.push(model)
            });
        }
        resolve(selectedStocks)
    }).catch((error) => {
        console.log("LineupDetailbyPlayerIdLineupNam Error:", error);
        reject(error)
    })
});



