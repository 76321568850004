import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import { COLORS } from '../../../constants';
import { LATO } from '../../../constants/fonts';
import moment from 'moment';

const DividerText = ({ text }) => {

  const date = moment().format('dddd, MM/DD/YYYY')
  if(date == text){
    text='Today'
  }
  

  return (
    <View style={styles.container}>
      <View style={styles.divider} />
      <Text style={styles.text}>{text}</Text>
      <View style={styles.divider}/>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Platform.OS=='web'?10:(10).scaleHeight(), // Use scaleHeight method to scale margin vertically
  },
  divider: {
    flex: 1,
    height: Platform.OS=='web'?1: (1).scaleHeight(), // Use scaleHeight method to scale height
    backgroundColor:COLORS.lightGrayColor,
    marginHorizontal:  Platform.OS=='web'?10:(10).scaleWidth(), // Use scaleWidth method to scale margin horizontally
  },
  text: {
    fontSize:  Platform.OS=='web'?13:(13).scaleWidth(),
    fontFamily:LATO.Medium,
    color:COLORS.lightGrayColor
  },
});

export default DividerText;
