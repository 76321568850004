import { SafeAreaView } from "react-native-safe-area-context";
import { AUTHHEADER, LEADERBOARDCOMPONENT } from "../../../components";
import { IMAGES, ROUTES } from "../../../../constants";
import { APP_STYLES } from "../../../../utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLeaderboardBannerAPI } from "../../../../controllers/leaderboardBanner";
import { getLeaderBoard } from "../../../../controllers/gamesController";
import { View, Text, TouchableOpacity, FlatList, Image, Dimensions, AppState } from "react-native"
import {styles} from '../leaderboard/index.style'
import { moderateScale } from "react-native-size-matters";
import TableFooterView from "../../../components/tableFooterView";
import GameDetailPopUp from "../../../components/gameDetailPopUp";
import { FacebookLoader } from "react-native-easy-content-loader";

//-------------------Completed Games Leaderboard component--------------------//

function LeaderBoardListRoot({ navigation, route }) {
    const [leaderBoardList, setLeaderBoardList] = useState([])
    const [isShimmer, setShimmer] = useState(false)
    const [showPopup, setShowPopup]=useState(false)
    const [gameTitle,setGameTitle] = useState('')
    const [gameDescription,setGameDescription] = useState()
    const [bannerImage,setBannerImage] = useState('')
    const { width } = Dimensions.get('screen')
    const completedGameID = useSelector((state) => state.lineupReducers.completedGameID)
    const getLeaderBoardListForCompleted = (gameID) => {
        setShimmer(true)
        let requestParameter = {
            GameID: gameID
        }
        getLeaderBoard(requestParameter)
          .then((leaderBoard) => {
            setLeaderBoardList(leaderBoard);
          })
          .catch((error) => {
            console.log(error,'Error leader');
          })
          .finally(() => {
            setShimmer(false);
          });
    }
    useEffect(()=>{
        getLeaderBoardListForCompleted(completedGameID)
        getLeaderboardBanner(completedGameID)
        
    },[])
    const getLeaderboardBanner = (id) => {
        getLeaderboardBannerAPI(id).then((res)=>{
            if(res.length > 0){
                let parts =  res[0].gameDetail.length==0?'':res[0].gameDetail.split(':')
                let title = res[0].gameDetail.length==0?'':parts[0].trim()
                let description =  res[0].gameDetail.length==0?'':parts.slice(1).join(':').trim()
                setGameTitle(title)
                setGameDescription(description)
                setBannerImage(res[0].leaderboardBanner)   
            }
        }).catch((err)=>{
            console.log(err,'Error');
        }).finally(()=>{

        })
      }
    const didClickUser = (item) => {
        navigation.navigate(ROUTES.COMPLETED_GAME_DETAILS, { gameID: completedGameID, playerId: item.playerID })
    }
    //---------------Render Shimmer----------------------//
    const renderShimmer = () => {
        return (
            <View style={styles.shimmerView}>
                {Array(10).fill(0).map((i) => {
                    return <FacebookLoader active={true} avatarStyles={styles.avatarStyles} pRows={0} />
                })}
            </View>
        )
    }
    const onPressImage = () => {
        
        if(gameTitle.length>0||gameDescription.length>0){ 
            setShowPopup(true)
        }
    }
    //------------------Render LEADERBOARD COMPONENT-------------------//
    const renderItem = ({ item ,index}) => {
        return (
            <LEADERBOARDCOMPONENT onPress={didClickUser} itemData={item} length={leaderBoardList.length}index={index}/>
        )
    }

    return (
        <SafeAreaView style={APP_STYLES.bodyContainer} edges={['left', 'right', 'top']}>
            <AUTHHEADER isBack={true} navigation={navigation} title="" isShowHeader = {true } mainView={{alignItems:'center'}} img={IMAGES.header}backIcon={{top:10}}/>
            <View style={styles.mainView}>
                <View style={styles.upperView}>

                        <Text style={[styles.titleText,{flexShrink:1}]}>Leaderboard</Text>
                        <Text style={[styles.titleText]}>GameID:<Text>{completedGameID?completedGameID:'NA'} </Text></Text>
                </View>
                {bannerImage && 
                    <TouchableOpacity onPress={onPressImage}>
                        <Image source={{uri:bannerImage}} style={{ width: width,height:(45).scaleHeight() }} resizeMode='contain' />
                    </TouchableOpacity>
                }
            {leaderBoardList.length > 0 &&
                <View style={styles.subTitleContainer}>
                    <Text style={[styles.subTitleText]}>RANK</Text>
                    <Text style={[styles.subTitleText1]}>USER NAME</Text>
                    <Text style={styles.subTitleText}>SCORE</Text>
                </View>
            }
            {
                isShimmer ? renderShimmer() :
                    <FlatList
                        data={leaderBoardList}
                        keyExtractor={(item) => item.id}
                        renderItem={renderItem}
                        style={{flex:1,marginBottom:20}}
                        ListEmptyComponent={<TableFooterView message={'Leaderboard not available'} />}
                    />
                    
            }

            {showPopup && (
              <GameDetailPopUp
                onDismissPopup={() => setShowPopup(false)}
                title={gameTitle}  
                gameDescription={gameDescription} 
              />
            )}

        </View>
        </SafeAreaView>
    )
}
export default LeaderBoardListRoot;