import { StyleSheet } from "react-native";
import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";
export const styles = StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:COLORS.lighterBlue
    },
    titleStyle:{
        marginHorizontal:16,
        marginVertical:20,
        color:COLORS.black,
        fontSize:(16).scaleWidth(),
        fontFamily:LATO.Semibold
    },
    innerContainer:{
        flexDirection:'row',
        justifyContent:'space-between'
    },
    dateLabel:{
        marginVertical: (8).scaleHeight(),
        marginHorizontal: (16).scaleWidth(),
        paddingHorizontal:10,
        paddingVertical:15,
        backgroundColor: COLORS.appTextInputBackgroundColor,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center'
    },
    dateTextStyle:{
        marginLeft:10,
        fontSize: (12).scaleWidth(),
        fontFamily: LATO.Medium,
        color:COLORS.lightGrayColor
    }
})