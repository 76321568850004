import moment from "moment";

class GameModel {

    constructor(
        bannerFile,
        bannerOn,
        description,
        duration,
        endDateTime,
        entryFee,
        gameID,
        gameStyle,
        gameType,
        gameDetail,
        inGameTrading,
        marketID,
        maxPlayers,
        minPlayers,
        numRegistered,
        payoutType,
        playerID,
        registrationFee,
        startDateTime,
        startTrigger,
        startingBankroll,
        status,
        totalPrizeMoney,
        tradingEndTime,
        tradingStartTime,
        stockGroup,
        eventDateRange,
        lineupName,
        videoURL,
        isInfoPost,
        infoMsg,
        featuredPriority,
        groupID,
        latitude,
        longitude
    ) {
        this.bannerFile = bannerFile;
        this.bannerOn = bannerOn;
        this.description = description;
        this.duration = duration;
        this.endDateTime = endDateTime;
        this.entryFee = entryFee;
        this.gameID = gameID;
        this.gameStyle = gameStyle;
        this.gameType = gameType;
        this.gameDetail = gameDetail;
        this.inGameTrading = inGameTrading;
        this.marketID = marketID;
        this.maxPlayers = maxPlayers;
        this.minPlayers = minPlayers;
        this.numRegistered = numRegistered;
        this.payoutType = payoutType;
        this.playerID = playerID;
        this.registrationFee = registrationFee;
        this.startDateTime = startDateTime;
        this.startTrigger = startTrigger;
        this.startingBankroll = startingBankroll;
        this.status = status;
        this.totalPrizeMoney = totalPrizeMoney;
        this.tradingEndTime = tradingEndTime;
        this.tradingStartTime = tradingStartTime;
        this.stockGroup = stockGroup;
        this.eventDateRange = eventDateRange
        this.lineupName = lineupName;
        this.videoURL = videoURL;
        this.isInfoPost = isInfoPost
        this.infoMsg = infoMsg;
        this. groupID=groupID;
        this.featuredPriority=featuredPriority;
        this.latitude=latitude;
        this.longitude=longitude;
    }


    initWithGameResponse(gameObj) {

        this.bannerFile = gameObj["BannerFile"] || "";
        this.lineupName = gameObj["Lineup_Name"] || "";
        this.bannerOn = gameObj["BannerOn"] || "";
        this.description = gameObj["Description"] || "";
        this.duration = gameObj["Duration"] || "";
        this.endDateTime = gameObj["EndDateTime"] || "";
        this.entryFee = gameObj["EntryFee"] || 0.00;
        this.gameID = gameObj["GameID"] || "";
        this.gameStyle = gameObj["GameStyle"] || "";
        this.gameType = gameObj["GameType"] || "";
        this.gameDetail = gameObj["GameDetail"] ||"";
        this.inGameTrading = gameObj["InGameTrading"] || "";
        this.marketID = gameObj["MarketID"] || "";
        this.maxPlayers = gameObj["MaxPlayers"] || "0";
        this.minPlayers = gameObj["MinPlayers"] || "0";
        this.numRegistered = gameObj["NumRegistered"] || "0";
        this.payoutType = gameObj["PayoutType"] || "";
        this.playerID = gameObj["Player_Id"] || "";
        this.registrationFee = gameObj["RegistrationFee"] || 0.00;
        this.startDateTime = gameObj["StartDateTime"] || "";
        this.startTrigger = gameObj["StartTrigger"] || "";
        this.startingBankroll = gameObj["StartingBankroll"] || "";
        this.status = gameObj["Status"] || "";
        this.totalPrizeMoney = gameObj["TotalPrizeMoney"] || "0.00";
        this.tradingEndTime = gameObj["TradingEndTime"] || "";
        this.tradingStartTime = gameObj["TradingStartTime"] || "";
        this.stockGroup = gameObj["stockGroup"] || "";
        this.videoURL = gameObj['VideoURL']||'';
        this.isInfoPost = gameObj ['isMessageEnabled']|| ''
        this.infoMsg = gameObj['Message']|| '';
        this.groupID = gameObj['GroupID']|| '';
        this.latitude = gameObj['Latitude']|| '';
        this.longitude = gameObj['Longitude']|| '';
        this.featuredPriority=gameObj['FeaturedPriority']||'';
        let startDate = moment(this.startDateTime, 'MM/DD/YYYY hh:mm:ss a');
        let endDate = moment(this.endDateTime, 'MM/DD/YYYY hh:mm:ss a');
        if (this.duration == '') {
            let diff = endDate.diff(startDate, 'hour');
            this.duration = diff
        }
        let startDateFormatted = moment(startDate).format('MMDDYYYY')
        let endDateFormatted = moment(endDate).format('MMDDYYYY')
        if (startDateFormatted == endDateFormatted) {
            this.eventDateRange = moment(startDate).format('MM-DD-YYYY LT') + ' - ' + moment(endDate).format('LT')
        }

        return this
    }


}
export default GameModel; 