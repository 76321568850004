import { useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";
import {  KeyboardAvoidingView ,View} from "react-native";
import { APP_STYLES } from "../../../../utils";
import { AUTHHEADER, CONFIGLINEUP, LINEUPHEADER } from "../../../components";

//------------------------Config Line Up Component------------------//

function ConfigLineUp({ route }) {
    const navigation = useNavigation()
    return (

        <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
            <View style={{flex:1}}>
            <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding" >
                <AUTHHEADER mainView={{alignItems:'center'}} isBack={true} navigation={navigation} title="Choose Stocks"  />
                <LINEUPHEADER />
                <CONFIGLINEUP route={route} navigation={navigation} />
            </KeyboardAvoidingView>
            </View>
        </SafeAreaView>

    )
}
export default ConfigLineUp;