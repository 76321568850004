export default class SubscriptionModel {
    constructor(status = "", message = "") {
        this.status = status;
        this.message = message;
    }

    initWithObj(json) {
        this.status = json.status || ''; // Ensure correct access to status property
        this.message = json.message || ''; // Ensure correct access to message property
        return this;
    }
}
