import { MaterialCommunityIcons, Ionicons, AntDesign } from '@expo/vector-icons'
import {Text, TouchableOpacity, View ,Image, Dimensions} from "react-native"
import { APP_STRINGS, COLORS, IMAGES } from "../../../constants"
// import styles from "./index.style"
import { StyleSheet } from "react-native";
// import { COLORS } from "../../../constants";
import { LATO } from "../../../constants/fonts";


function RewardComponent (props) {
  const width = Dimensions.get('screen').width
  const onPressCell = () => {
    if (props.onPress != undefined) {
      props.onPress(props.data)
    }
  }
 

  return (
    // <View style={{justifyContent:'center',flex:1}}>
    <TouchableOpacity  onPress={onPressCell} activeOpacity={props.redeemed ? 1 :0.5}  key={props.data.gameID} style={[styles.innerView]}>
      <View style={styles.titleView}>
        <Text style={styles.title}>{props.data.description}</Text>
        <View>
          {(props.cellType == APP_STRINGS.pending || props.cellType == APP_STRINGS.running)
           && 
          <AntDesign name='questioncircleo' size={24} style={styles.markIcon} onPress={props.onPressQuestionMark}/>}
        
          

        </View>
      </View>
      <View style={styles.lineView} />
      {/* second Views */}
      <View style={{backgroundColor:props.redeemed?'rgba(0,0,0,0.1)':'',opacity:props.redeemed ?0.5:1}}>
        <Image source={props.data.bannerFile.length == 0 ? {uri:IMAGES.defaultBanner}:{uri:props.data.bannerFile}} style={[styles.image,{opacity:props.redeemed?0.5:1,borderBottomLeftRadius:props.isInfoPost ? 8: 0,borderBottomRightRadius:props.isInfoPost ? 8: 0,}]} resizeMode='contain'/>
       
      </View>
      {/* Third Views */}

        <View>
            <View style={styles.gameIDView}>
              <Text style={styles.gameDetail}>{props.gameDetail?props.gameDetail:''}</Text>
            </View>
            <View style={[styles.thirdView,{paddingVertical:10}]}>
              <View style={styles.imageView}>
                <Text style={[styles.rewardGameId,{color:props.redeemed?COLORS.gray:COLORS.black}]}>Reward ID: {props.data.rewardID}</Text>          
              </View>
              <View>
                <Text style={styles.dateText}>{props.data.status.trim()=='EXPIRED' ?`EXPIRED` : props.data.status.trim()=='REDEEMED' ? `${props.data.redeemedDateTime}` :`Expires: ${props.data.rewardExperationDate}` }</Text>
              </View>
            </View>
        </View>
        
      

    </TouchableOpacity>

    // </View>
  )
}
export default RewardComponent;


const styles = StyleSheet.create({
    innerView: {
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 6,
        shadowOpacity: 0.1,
        shadowColor: COLORS.shadowColor,
        elevation: 4,
        borderRadius: 10,
        marginHorizontal: 12,
        marginBottom: 5,
        backgroundColor: COLORS.white,
        marginTop: 5,
       
    },
    cellTypeView: { 
        flexDirection: 'row', 
        alignItems: 'center' 
    },
    titleView: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-between",
        paddingHorizontal: 10,
        paddingVertical: 8,
    },
    secondView: {
        paddingHorizontal: 10,
        paddingTop: 6,
        paddingVertical: 10,
    },
    secondValueView: { 
        flexDirection: 'row', 
        paddingTop: 10 
    },
    gameIDView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 10
    },
    image: {
        aspectRatio: 103 / 30
    },
    secInnerView: { 
        flex: 1, 
        justifyContent: 'flex-start' 
    },
    totalPriceView: { 
        flex: 1, 
        justifyContent: 'flex-start' 
    },
    thirdView: {
        flex: 1,
        backgroundColor: COLORS.lightBlueColor,
        flexDirection: 'row',
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        justifyContent: "space-between",
        paddingHorizontal: 10,
        borderColor: COLORS.skyBlue,
        paddingVertical: 5,
        borderBottomColor: COLORS.lightBlueColor,
        borderBottomWidth: 4,
    },
    title: {
        fontFamily: LATO.Bold,
        fontSize: 18,
        color: COLORS.appColor,
        flex: 1
    },
    prizeText: {
        fontFamily: LATO.Bold,
        fontSize: 19,
        color: COLORS.appColor,
        marginTop: 5,
    },
    innerHeadingsView: { 
        flex: 1, 
        alignItems: 'flex-end' 
    },
    innerHeadings: {
        color: COLORS.lightGrayColor,
        fontSize: 13,
        fontFamily: LATO.Regular,
    },
    markIcon: {
        paddingLeft: 10
    },
    entryText: {
        marginTop: 5,
        fontFamily: LATO.Bold,
        fontSize: 14,
        color: COLORS.appColor,
    },
    entriesView: {
        flex: 1,
        alignItems: 'center',
    },
    entryView: {
        paddingHorizontal: 16,
        paddingVertical: 6,
        borderRadius: 5,
    },
    entry: {
        textAlign: 'center',
        fontFamily: LATO.Bold,
        fontSize: 14
    },
    gameID: {
        fontFamily: LATO.Bold,
        color: COLORS.black,
        fontSize: 13
    },
    lineView: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.appDividerColor,
    },
    dateText: {
        color: COLORS.dateColor,
        fontSize: 12,
        fontFamily: LATO.Semibold,
        flex: 1
    },
    hoursView: {
        justifyContent: "flex-end",
        flexDirection: 'row',
        borderRadius: 4,
        borderColor: "rgba(40, 126, 173, 0.3)",
        borderWidth: 1,
        backgroundColor: COLORS.white,
        alignItems: 'center',
        maxHeight: 30
    },
    iconTextView: {
        backgroundColor: COLORS.topBarLabel,
        borderRadius: 3,
        paddingHorizontal: 5,
        paddingVertical: 2,
    },
    hour: {
        backgroundColor: COLORS.white,
        color: COLORS.dateText,
        fontSize: 10,
        fontFamily: LATO.Semibold,
        paddingHorizontal: 5
    },
    imageView: {
        flexDirection: 'row',
        paddingHorizontal: 4,
        alignItems: 'center',
        flex: 1
    },
    circleView: {
        width: 10,
        height: 10,
        borderRadius: 5,
        marginRight: 5,
    },
    textCellType: {
        fontSize: 11,
        fontFamily: LATO.Semibold,
    },
    rewardGameId: {
        fontFamily: LATO.Bold,
        fontSize: 12,
        color: COLORS.black
    },
    gameDetail: {
        fontFamily: LATO.Medium,
        color: `rgba(6, 0, 44, 0.5)`,
        fontSize: 13,
    }
});
