import { StyleSheet } from "react-native";
import { COLORS } from "../../../../constants";
import { LATO } from "../../../../constants/fonts";
import { scale } from "react-native-size-matters";

export const styles = StyleSheet.create({
    container:{
        flex:1
    },
    innerContainer:{
        marginVertical:(12).scaleHeight(),
        marginHorizontal:(15).scaleWidth(),
        backgroundColor:COLORS.white,
        borderRadius:10,
        shadowOpacity:0.1,
        shadowOffset:{width:0,height:1},
        padding:(15).scaleWidth()
    },
    balanceContainer:{
        justifyContent:'center',
        alignItems:'center'
    },
    titleText:{
        marginTop:(10).scaleWidth(),
        color:COLORS.lightGrayColor,
        fontFamily:LATO.Semibold,
        fontSize:(14).scaleWidth()
    },
    priceText:{
        marginTop:(8).scaleHeight(),
        color:COLORS.appColor,
        fontFamily:LATO.Bold,
        fontSize:(20).scaleWidth()
    },
    addButton:{
        marginVertical:(15).scaleWidth(),
        backgroundColor:COLORS.green,
        justifyContent:'center',
        borderWidth:1,
        borderColor:COLORS.green,
        alignItems:'center',
        paddingVertical:12,
        paddingHorizontal:(25).scaleWidth(),
        borderRadius:5,
        marginHorizontal:10,
        // width:(130).scaleWidth()
    },
    addButtonText:{
        color:COLORS.white,
        fontFamily:LATO.Bold,
        fontSize:(12).scaleWidth()
    },
    amountContainer:{
        marginHorizontal:20,
        paddingVertical:10,
        borderBottomWidth:1,
        borderBottomColor:'#E6E6E6',
        borderTopWidth:1,
        borderTopColor:'#E6E6E6'
    },
    amountText:{
        color:COLORS.lightGrayColor,
        fontFamily:LATO.Medium,
        fontSize:(13).scaleWidth()
    },
    amountPriceText:{
        marginTop:4,
        color:COLORS.darkGrayColor,
        fontSize:(14).scaleWidth(),
        fontFamily:LATO.Medium
    },
    winningContainer:{
        flexDirection:'row',
        marginHorizontal:20,
        paddingVertical:10,
        alignItems:'center',
        justifyContent:'space-between'
    },
    withdrawButton:{
        borderColor:COLORS.darkGrayColor,
        borderWidth:1,
        paddingHorizontal:15,
        paddingVertical:10,
        borderRadius:5
    },
    withdrawButtonText:{
        color:COLORS.darkGrayColor,
        fontFamily:LATO.Semibold,
        fontSize:(12).scaleWidth()
    },

    
    subContainer:{
        flex:1,
    },
    innerView:{
        flex: 1, 
        flexDirection: 'row' 
    },
    card: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        paddingVertical: (10).scaleHeight(),
        borderRadius: 10,
        borderBottomWidth:1,
        borderColor:'rgba(229,229,229,1)',

    },
    shimmerView:{ 
        flex: 1, 
        paddingHorizontal: scale(16), 
        backgroundColor: '#fff', 
        paddingTop: 30 
    },
    avatarStyles:{ 
        width: '100%', 
        height: scale(50), 
        borderRadius: 10 
    },
    itemType:{ 
        fontFamily: LATO.Medium, 
        color: COLORS.appColor, 
        fontSize: scale(14) 
    },
    success:{
        fontFamily: LATO.Medium, 
        fontSize: scale(11) 
    },
    time:{ 
        fontFamily: LATO.Regular, 
        color: COLORS.gray, 
        fontSize: scale(11), 
        marginTop: 3 
    },
    sectionView:{ 
        marginLeft: 20, 
        backgroundColor: '#fff', 
        paddingVertical: 10 
    },
    title:{ 
        fontFamily: LATO.Bold, 
        color: '#a1a1a1', 
        fontSize: scale(14) 
    },
    avatar:{ 
        width: '100%', 
        height: scale(50), 
        borderRadius: 10 
    },
    amt:{ 
        fontFamily: LATO.Bold, 
        fontSize: scale(14) ,
        textAlign:'right'
    },
    transactionView:{
        flexDirection:'row',
        marginHorizontal:(5).scaleWidth(),
        paddingHorizontal:(15).scaleWidth(),
        paddingVertical:(20).scaleHeight(),
        alignItems:'center',
        justifyContent:'space-between'
    },
    
    textStyle:{
        fontSize:(15).scaleWidth(),
        fontFamily:LATO.Semibold,
        color:COLORS.appColor
    },
    subTextStyle:{
        fontSize:(12).scaleWidth(),
        fontFamily:LATO.Medium,
        color:COLORS.gray
    },
    iconView:{ 
        flexDirection: "row", 
        alignItems: "center" 
    },
})