import {KeyboardAvoidingView, Platform, ScrollView, StyleSheet,Text, View,useWindowDimensions} from "react-native"
import {getRewards} from "../../../../../controllers/rewardController"
import RewardComponent from "../../../../components/gameComponent/rewardComponent"
import { useIsFocused, useNavigation } from "@react-navigation/native"
import Loader from "../../../../components/loader"
import { useEffect, useState } from "react"
import TableFooterView from "../../../../components/tableFooterView"


export default function RedeemedRewards() {

//------------States used in the Component-------------------------//
    const [isLoading, setIsLoading] = useState(false)
    const [redeemedRewards ,setRedeemedRewards] = useState([])
    const { width } = useWindowDimensions()
    const navigation = useNavigation()
    const isFocused = useIsFocused()

//---------------------Use effect to whenever screen comes in focus-----------------//
    useEffect(() => {
      if(isFocused){
        setIsLoading(true)
        getRewardsfunc()
      }
    }, [isFocused])
//-----------------------Width calculations according to screen size-----------------//

    let isLandscape = 0
    if (width <= 500) {
        isLandscape = '100%'
    } else if (501 < width && width <= 1100) {
        isLandscape = '48%'
    } else if (1100 < width && width <= 1400) {
        isLandscape = '33%'
    } else {
        isLandscape = '24%'
    }
//--------------Custom function to get Rewards-----------------//

    const getRewardsfunc = () => {
      setIsLoading(true)
      getRewards().then((res) => {
        let rewardsDataRedeemed = res.filter((item) => item.status.trim() === 'REDEEMED'|| item.status.trim() === 'EXPIRED')
        rewardsDataRedeemed.sort((a, b) => {
          const dateA = new Date(a.rewardExperationDate)
          const dateB = new Date(b.rewardExperationDate)
          return dateA - dateB
      })
      setRedeemedRewards(rewardsDataRedeemed)
      })
      .catch((err) => {
        console.log(err, "error")
       
      }).finally(()=>{
        setIsLoading(false)
      })
      
    }
    return (
        <View style={styles.container}>
          <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'ios' ? 'padding' : null}>
            <View style={styles.container}>    
              <View style={styles.container}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View style={styles.cardView}>
                  {isLoading && <Loader /> }                  
                    {redeemedRewards.length == 0 && !isLoading && 
                      <TableFooterView message={'No Rewards Available at the moment'}/>}
                      {redeemedRewards.length > 0 && redeemedRewards.map((res) => {
                          return (
                            <View style={{ width: isLandscape, margin: 1 }}>
                              <RewardComponent
                                cellType='reward'
                                gameDetail={res.reward}
                                reward
                                redeemed={true}
                                data={res}
                              />
                            </View>
                          )
                        })}
                  </View>
                </ScrollView>
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
)}

 const styles = StyleSheet.create({
   container: {
    flex:1
   },
   
   button: {
     alignItems: "center",
   },
   cardView:{
    flexDirection: 'row', 
    flex: 1, 
    flexWrap: 'wrap',
    marginTop:20
  }
 })


