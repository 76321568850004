import { Text, TouchableOpacity, View } from "react-native";
import { styles } from "./index.style";
import { COLORS, STORAGE_KEYS } from "../../../constants";
import LinearGradient from "react-native-linear-gradient";
import { LOCAL_STORAGE } from "../../../services";

function LeaderBoardComponent(props) {
    let itsME = LOCAL_STORAGE.getItem(STORAGE_KEYS.kEmail)
    
    const onClickCell = () => {
        if (props.onPress) {
            props.onPress(props.itemData)
        }
    }
    return (
        <TouchableOpacity onPress={onClickCell} style={styles.container}>
            <LinearGradient start={{ x: 1, y: 0.5 }} end={{ x: 0.13, y: 0.5 }}
                colors={props.itemData.email == itsME ? ['#FF4E00', '#EC9F05'] : ['transparent', 'transparent']}
                style={props.itemData.id % 2 == 0 ? styles.innerContainer : [styles.innerContainer, { backgroundColor: COLORS.white }]}>
                <View style={styles.userNameTextStyle}>
                    <View style={styles.rankView}>
                        <Text style={props.itemData.email == itsME ? [styles.rankTextStyle, { color: COLORS.white }] : [styles.rankTextStyle, { color: COLORS.appColor }]}>{props.itemData.id}</Text>
                    </View>
                </View>
                <Text style={props.itemData.email == itsME ? [styles.earningTextStyle, { color: COLORS.white }] : [styles.earningTextStyle, { color: COLORS.appColor }]}>{props.itemData.userID}</Text>
                <Text style={props.itemData.email == itsME ? [styles.userNameTextStyle, { color: COLORS.white,marginTop:(4).scaleHeight() }] : [styles.userNameTextStyle, { color: COLORS.appColor,marginTop:(4).scaleHeight() }]}>{parseFloat(props.itemData.score).toFixed(2)}</Text>
            </LinearGradient>
        </TouchableOpacity>
    )
}
export default LeaderBoardComponent;