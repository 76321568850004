
import { KeyboardAvoidingView, Platform, ScrollView, StatusBar, View } from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { AUTHHEADER, LOGO, SIGNUPCOMPONENT } from '../../../components';
import SplashScreen from 'react-native-splash-screen';
import { APP_STYLES } from '../../../../utils';
import { COLORS, IMAGES } from '../../../../constants';
import { useState,useEffect } from 'react';
import * as React from 'react';


function SignUp({ navigation }) {
  //-------------------States used in the component--------------------//
  const [headerLogo, setHeaderLogo] = useState()

//----------------------Use effect to hide the splash screen------------//
  useEffect(() => {
    if(Platform.OS!=='web'){
      SplashScreen.hide()
    }
  }, [])

//----------------------Component Rendering-----------------------------//
  return (
    <SafeAreaView edges={['top', 'left', 'right']} style={APP_STYLES.bodyContainer}>
      <StatusBar barStyle={"light-content"} />
      <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : 'padding'} style={{ flex: 1 }}>
      <AUTHHEADER mainView={{ height: Platform.OS == 'ios' ? (35).scaleHeight() : (55).scaleHeight(), }} isShow={headerLogo} isBack={true} navigation={navigation} img={IMAGES.header} />
      <ScrollView keyboardShouldPersistTaps={'handled'} contentContainerStyle={APP_STYLES.contentContainerStyle} style={APP_STYLES.mainContainer}
          onScroll={(event) => {
            const scrolling = event.nativeEvent.contentOffset.y;
            if (scrolling > 100) {
              setHeaderLogo(true);
            } else {
              setHeaderLogo(false);
            }
          }}
          scrollEventThrottle={16}
        >
          <LOGO />
          <SIGNUPCOMPONENT navigation={navigation} />
        </ScrollView>
      </KeyboardAvoidingView>
      <View style={{ height: useSafeAreaInsets().bottom, backgroundColor: COLORS.white }} />
    </SafeAreaView>
  );
}

export default SignUp;